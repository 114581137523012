import React from "react";
import PropTypes from "prop-types";

class AtomLabel extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  render() {
    return <div className="label">{this.props.text}</div>;
  }
}

export default AtomLabel;
