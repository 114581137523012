import React from "react";
import PropTypes from "prop-types";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  dekText: PropTypes.string,
  hedText: PropTypes.string,
  iconUrl: PropTypes.string,
  promoCodePrepend: PropTypes.string,
  promoCode: PropTypes.string,
  subText: PropTypes.string,
  subTextItalic: PropTypes.bool,
};

const MoleculePlansPagePromo = props => {
  const backgroundColor = {
    "background-color": props.backgroundColor,
  };
  const borderStyle = {
    "border-color": props.borderColor,
  };

  const subText = props.subTextItalic ? <i>{props.subText}</i> : props.subText;

  return (
    <div className="molecule-plans-page-promo" data-test-id="molecule-plans-page-promo" style={backgroundColor}>
      <div className="molecule-plans-page-promo__promotion-card" style={borderStyle}>
        <RtrImage src={props.iconUrl} alt="an icon of a gift box" />
        <div className="molecule-plans-page-promo__promotion-card__copy">
          <h4>{props.hedText}</h4>
          <p>
            {props.dekText}
            <br />
            {props.promoCodePrepend}&nbsp;<b>{props.promoCode}</b>
          </p>
        </div>
      </div>
      <p className="molecule-plans-page-promo__sub-text">{subText}</p>
    </div>
  );
};

MoleculePlansPagePromo.propTypes = propTypes;
export default MoleculePlansPagePromo;
