import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";

import AnimatedEmailInput from "components/source/shared/animated_inputs/animated-email-input";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import { clientSideErrorMessages, formLabels, shareCreateStatuses } from "rtr-constants";

const defaultValidationErrorMsg = clientSideErrorMessages.formValidation.email;

const propTypes = {
  additionalClassName: PropTypes.string,
  buttonDisabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  shareCreateStatus: PropTypes.string,
  submissionError: PropTypes.string,
};

const defaultProps = {
  additionalClassName: "",
  buttonText: "Send",
  label: formLabels.email,
};

export default class EmailShareAnimated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      validationError: "",
      isValid: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.shareCreateStatus !== nextProps.shareCreateStatus &&
      nextProps.shareCreateStatus === shareCreateStatuses.SUCCESS
    ) {
      this.resetStates();
    }

    // reset value when a user submits an email share successfully, then enters another email to submit again
    if (this.props.shareCreateStatus !== nextProps.shareCreateStatus && _.isNull(nextProps.shareCreateStatus)) {
      this.resetStates();
    }
  }

  resetStates() {
    // set value to null to empty the input after a successful submission submission
    this.setState({
      value: null,
      validationError: null,
      isValid: null,
    });
  }

  // this difference between handleBlur and handleInputChange is that
  // when the user clicks out of the input and the email isn't valid, show an error.
  // however, while the user is typing, we don't show an error until she's finished.
  // as soon as the user's typing makes a previously invalid entry valid, remove any errors

  handleBlur(email, isValid) {
    this.handleInputChange(email, isValid);

    if (!isValid) {
      this.setState({
        validationError: defaultValidationErrorMsg,
        isValid: false,
      });
    }
  }

  handleInputChange(email, isValid) {
    this.setState({
      value: email,
      isValid: isValid,
    });

    if (isValid) {
      this.setState({
        validationError: null,
      });
    }
  }

  handleSubmit() {
    const email = this.state.value;
    const { isValid } = this.state;

    // we shouldn't submit the form
    // if the email is invalid or if no email is provided
    if (isValid && !_.isEmpty(email)) {
      this.setState({
        validationError: null,
      });
      this.props.handleSubmit(email);
    } else {
      this.setState({
        validationError: defaultValidationErrorMsg,
      });
    }
  }

  render() {
    return (
      <div className="email-share-animated">
        <AnimatedEmailInput
          additionalClassName={this.props.additionalClassName}
          label={this.props.label}
          value={this.state.value}
          error={this.state.validationError || this.props.submissionError}
          onBlurCallback={(value, isValid) => this.handleBlur(value, isValid)}
          onChangeCallback={(value, isValid) => this.handleInputChange(value, isValid)}
          required={false}
        />
        <AtomPrimaryButton
          buttonText={this.props.buttonText}
          onClick={() => this.handleSubmit()}
          disabled={this.props.buttonDisabled}
        />
      </div>
    );
  }
}

EmailShareAnimated.propTypes = propTypes;
EmailShareAnimated.defaultProps = defaultProps;
