import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { listPanelPropType, optionsPropType } from "../../../propTypes/rent-my-style-quiz-content-prop-type";

const ListPanel = ({ panel, options, onClick, skipQuiz, skipToGridUrl }) => {
  const renderJustBrowsing = () => {
    const { skipToGridText } = panel;

    if (!skipToGridText) {
      return;
    }

    return (
      <a className={styles["just-browsing-link"]} onClick={skipQuiz} href={skipToGridUrl} data-test-id="skip-to-grid">
        {skipToGridText}
      </a>
    );
  };

  return (
    <div>
      <div className={styles["header-copy"]}>
        <h3 dangerouslySetInnerHTML={{ __html: panel.header }} />
        <p dangerouslySetInnerHTML={{ __html: panel.dek }} />
      </div>
      <div className={styles["list-options"]}>
        {panel.panelOptions.map(optionKey => {
          const optionContent = options[optionKey];
          return (
            <button
              key={optionKey}
              className={"btn-secondary"}
              onClick={() => onClick(optionContent, panel.loggingPanelName, optionKey)}>
              <p dangerouslySetInnerHTML={{ __html: optionContent.label }} />
            </button>
          );
        })}
      </div>
      {renderJustBrowsing()}
    </div>
  );
};

ListPanel.propTypes = {
  panel: listPanelPropType.isRequired,
  options: PropTypes.objectOf(optionsPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  skipQuiz: PropTypes.func,
  skipToGridUrl: PropTypes.string,
};

export default ListPanel;
