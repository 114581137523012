import PropTypes from "prop-types";

const panelTypes = ["list", "zipAndSize", "dateSelect", "eventIcons", "marketingContent"];

const panelBaseTypeWith = additionalKeys => {
  return PropTypes.shape({
    type: PropTypes.oneOf(panelTypes),
    header: PropTypes.string.isRequired,
    dek: PropTypes.string,
    leadsTo: PropTypes.oneOf([...panelTypes, "grid"]),
    ...additionalKeys,
  });
};

export const listPanelPropType = panelBaseTypeWith({
  panelOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  skipToGridText: PropTypes.string,
});
export const eventIconsPanelPropType = listPanelPropType;

export const zipAndSizePanelPropType = panelBaseTypeWith({
  maternityDek: PropTypes.string,
});

export const dateSelectPanelPropType = panelBaseTypeWith({
  ctaText: PropTypes.string,
  messageOne: PropTypes.string,
  messageTwo: PropTypes.string,
});

export const marketingContentPanelPropType = panelBaseTypeWith({
  imageSrc: PropTypes.string.isRequired,
  imageSrcAspectRatio: PropTypes.string.isRequired,
  mobileImageSrc: PropTypes.string.isRequired,
  mobileImageSrcAspectRatio: PropTypes.string.isRequired,
  primaryCtaText: PropTypes.string.isRequired,
  primaryCtaHref: PropTypes.string.isRequired,
  secondaryCtaText: PropTypes.string.isRequired,
  message: PropTypes.string,
  messageTwo: PropTypes.string,
});

export const optionsPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  numPanels: PropTypes.number,
  gridUrl: PropTypes.string,
  leadsTo: PropTypes.oneOf([...panelTypes, "grid"]).isRequired,
});

export const rentMyStyleQuizContentPropType = PropTypes.shape({
  defaultGridUrl: PropTypes.string.isRequired,
  extraLoggingData: PropTypes.object,
  loggingObjectType: PropTypes.string.isRequired,
  maxPanels: PropTypes.number.isRequired,
  options: PropTypes.objectOf(optionsPropType).isRequired,
  panels: PropTypes.objectOf(
    PropTypes.oneOf([
      listPanelPropType,
      eventIconsPanelPropType,
      zipAndSizePanelPropType,
      dateSelectPanelPropType,
      marketingContentPanelPropType,
    ])
  ).isRequired,
  quizLaunch: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  skipToGridUrl: PropTypes.string.isRequired,
  startPanel: PropTypes.string.isRequired,
});
