import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import ActionLogger from "action-logger";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import { moleculePlansPropType } from "components/propTypes";
import AtomUnorderedList from "components/source/atoms/atom-unordered-list";
import { analytics, DelayedAccountCreation, TOOLTIP } from "rtr-constants";
import MoleculeTooltip from "components/source/molecules/tooltip/molecule-tooltip";
import CarouselProgressionDots from "components/source/shared/carousels/carousel-progression-dots";
import { isAuthorized, isAnonymous, withUserData, userDataPropType } from "components/source/hoc/with-user-data";
import AuthActions from "actions/auth-actions";
import { authFormConstants } from "constants/auth";
import { HEAP_AUTH_TRIGGER_TYPES, HEAP_ADD_TO_BAG_IDS } from "helpers/heap-helpers";
import { navigateTo } from "../../../helpers/location-helpers";
import tryCatchLog from "../../../helpers/try-catch";
import RtrImage from "../shared/rtr-image";
import Converge from "../../../helpers/converge";
import { bindTouchGesturesWithAsyncHammer } from "../../../helpers/touch-interaction-helper";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import {
  flagsAndExperimentsPropType,
  withFlagsAndExperiments,
  flagsAndExperimentNames,
} from "../hoc/with-flags-and-experiments";
import { withSubscriptionAtbAnalytics } from "../hoc/with-subscription-atb-analytics";

export class MoleculePlans extends React.Component {
  static propTypes = {
    fetchFeatureFlagConfiguration: PropTypes.func,
    flagsAndExperiments: flagsAndExperimentsPropType,
    ...moleculePlansPropType,
    onPlanCtaClick: PropTypes.func, // NW [EXPLANATION] 12/2/20: a click event handler for the plan card CTA with signature (listItem) => {...}
    showAuthModal: PropTypes.func.isRequired,
    userData: userDataPropType.isRequired,
    sendSubscriptionAtbAnalytics: PropTypes.func.isRequired,
  };

  state = {
    index: 0,
    mobileSelectedPlanIndex: null,
    displayStickyCta: false,
    translation: 0,
    pageSize: 3,
    cardsShiftToZero: 33,
    cardsShiftToOne: 0,
    cardsShiftToTwo: -33,
    cardsShiftToThree: -33,
  };

  static defaultProps = {
    onPlanCtaClick: () => {}, // NOSONAR
  };

  elementRef = React.createRef(null);
  plansCtaRef = React.createRef();
  swipeableCardsRef = React.createRef();

  componentDidMount() {
    const { listItems, fetchFeatureFlagConfiguration, mobileDefaultSelectedPlanIndex, closetStyleCard } = this.props;
    const { isMobileViewport } = this.props.browser;

    this.setState({
      mobileSelectedPlanIndex: mobileDefaultSelectedPlanIndex,
      pageSize: listItems.length,
      translation: isMobileViewport && listItems.length > 3 ? 13 : 0,
      cardsShiftToZero: listItems.length > 3 ? 38 : 33,
      cardsShiftToOne: listItems.length > 3 ? 13 : 0,
      cardsShiftToTwo: listItems.length > 3 ? -12 : -33,
      cardsShiftToThree: listItems.length > 3 ? -37 : 0,
    });

    if (!closetStyleCard) {
      this.initializeHammer();
    }

    if (listItems?.length) {
      listItems.forEach((_listItem, index) => {
        this[`setTooltipRef${index}-desktop`] = React.createRef();
        this[`setTooltipRef${index}-mobile`] = React.createRef();
      });
    }

    window.addEventListener("scroll", this.handleScroll);
    this.scrollPixelLogger = this.createScrollPixelLogger();

    Converge.trackViewedSubscriptionPlans();

    fetchFeatureFlagConfiguration();
  }

  componentWillUnmount() {
    this.hammer?.off("swipeleft swiperight")?.destroy();
    window.removeEventListener("scroll", this.handleScroll);

    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  handleScroll = () => {
    // bottomNavDisplayed is  used to hide the sticky cta for the bottom nav
    const bottomNavDisplayed = window.scrollY < document.body.scrollHeight - 1600;
    const displayStickyCta = this.getTopPosition() <= 0 && bottomNavDisplayed;
    this.setState({ displayStickyCta });
  };

  getTopPosition() {
    if (!this.plansCtaRef.current) return;

    return this.plansCtaRef.current.getBoundingClientRect().y;
  }

  initializeHammer() {
    const { isMobileViewport } = this.props.browser;

    bindTouchGesturesWithAsyncHammer(Hammer => {
      if (isMobileViewport) {
        this.hammer = new Hammer(this.swipeableCardsRef.current);
        this.hammer.on("swipeleft", () => {
          this.handlePlanCardsScroll(1);
        });
        this.hammer.on("swiperight", () => {
          this.handlePlanCardsScroll(-1);
        });
      }
    });
  }

  createScrollPixelLogger = () => {
    const { scrollPixelObject, shouldLogScroll, scrollAction } = this.props;
    if (!scrollPixelObject && !shouldLogScroll) {
      return null;
    }

    const pixelData = scrollPixelObject ?? {
      objectType: "scroll_read",
      action: scrollAction,
    };

    return createScrollIntoViewPixelLogger(this.elementRef, "scroll_read", pixelData);
  };

  baseClassWith(element) {
    return ["molecule-plans", element].join("");
  }

  logCtaAction(item, stickyCtaClick) {
    const { cta, membershipTierRevisionId, planType, stickyCta } = item;
    const text = cta.text.toLowerCase().replace(" ", "_");
    const { inferAction, logAction } = ActionLogger;
    //If the user will see the auth modal on this page, log the action immediately
    //Otherwise, defer to the following pageload (which will happen immediately)
    const loggerAction = this.shouldSeeAuthModal() ? logAction : inferAction;
    let objectType, actionType;

    if (stickyCtaClick) {
      objectType = stickyCta?.objectType;
      actionType = stickyCta?.actionType;
    } else if (cta?.objectType) {
      objectType = cta?.objectType;
      actionType = cta?.actionType;
    } else {
      objectType = "cta_click";
      actionType = "click_through_plans";
    }

    loggerAction({
      // These `get` functions allow us to pass through in the CTA object from the CMS
      object_type: objectType,
      action: cta?.action ?? "molecule_plans",
      action_type: actionType,
      context: "click",
      url: window.location.pathname,
      component_type: this.props.cmsUrl,
      copy: text,
    });

    // Log generic click through that is not experiment driven for general update CVR
    loggerAction({
      object_type: "plans_module",
      context: "click",
      url: window.location.pathname,
      component_type: this.props.cmsUrl,
      action: `learn_more_${planType}`,
    });

    loggerAction({
      object_type: analytics.OBJECT_TYPE.PLANS_MODAL,
      action: analytics.ACTION_TYPE.CLICK_TRY_NOW,
      tier: membershipTierRevisionId,
    });
  }

  getStyles(backgroundColor) {
    const styles = {};
    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }
    return styles;
  }

  onSelectPlan = (planIndex, membershipTierRevisionId) => {
    const { isMobileViewport } = this.props.browser;

    if (isMobileViewport) {
      this.setState({
        mobileSelectedPlanIndex: planIndex,
      });

      if (planIndex === 0) {
        this.setState({ translation: this.state.cardsShiftToZero });
      } else if (planIndex === 1) {
        this.setState({ translation: this.state.cardsShiftToOne });
      } else if (planIndex === 2) {
        this.setState({ translation: this.state.cardsShiftToTwo });
      } else if (planIndex === 3) {
        this.setState({ translation: this.state.cardsShiftToThree });
      }

      ActionLogger.logAction({
        action: analytics.ACTION_TYPE.CLICK_TIER,
        object_type: analytics.OBJECT_TYPE.PLANS_MODAL,
        tier: membershipTierRevisionId,
      });
    }
  };

  // Normally this would be controlled by pure CSS but the markup separation of the header and footer prevents it
  applyHoverStyles = (planIndex, hoverToggle) => {
    if (hoverToggle) {
      this.setState({
        hoveredPlanIndex: planIndex,
      });
    } else {
      this.setState({
        hoveredPlanIndex: null,
      });
    }
  };

  renderMobileSelectedPlanDetail() {
    const { mobileDefaultSelectedPlanIndex, mobileListAreaFooterBackgroundColor, listItems } = this.props;
    const { isMobileViewport } = this.props.browser;
    let { mobileSelectedPlanIndex } = this.state;

    if (mobileSelectedPlanIndex === null) mobileSelectedPlanIndex = mobileDefaultSelectedPlanIndex;

    if (!isMobileViewport || mobileSelectedPlanIndex < 0) {
      return null;
    }

    const selectedPlan = listItems[mobileSelectedPlanIndex];
    if (!selectedPlan) {
      return null;
    }

    const customClass = classNames(this.baseClassWith("__mobile-selected-plan-detail"), "new-plans-cards");

    return (
      <div className={customClass} style={this.getStyles(mobileListAreaFooterBackgroundColor)}>
        {this.renderSubHedBanner(selectedPlan)}
        {this.renderSubHed(selectedPlan)}
        {this.renderSubHedImages(selectedPlan)}
        {this.renderCta(selectedPlan)}
        {this.renderStickyCta(selectedPlan)}
      </div>
    );
  }

  renderItems() {
    const {
      listAreaFooterBackgroundColor,
      mobileListAreaFooterBackgroundColor,
      listAreaHeaderBackgroundColor,
      listItems,
      closetStyleCard,
      closetStyleCardLimitedColor,
      closetStyleCardFullColor,
      closetStyleCardFullTitle,
      closetStyleCardFullHed,
      closetStyleCardFullSubHed,
      closetStyleCardLimitedTitle,
      closetStyleCardLimitedHed,
      closetStyleCardLimitedSubHed,
    } = this.props;
    const { isMobileViewport } = this.props.browser;

    const planCardHeaders = listItems.map((item, index) => {
      return this.renderPlanCardHeader(item, index);
    });

    let planCardHeadersFull, planCardHeadersLimited;
    if (closetStyleCard) {
      planCardHeadersLimited = [this.renderClosetStylePlanCard(listItems[0], 0)];
      planCardHeadersFull = [];
      for (let i = 1; i < listItems.length; i++) {
        planCardHeadersFull.push(this.renderClosetStylePlanCard(listItems[i], i));
      }
    }

    const planCardFooters = listItems.map((item, index) => {
      return this.renderPlanCardFooter(item, index);
    });

    if (listItems.length > 4) {
      return this.renderSwipeablePlanCards(planCardHeaders, planCardFooters);
    }

    const backgroundColor =
      isMobileViewport && !closetStyleCard ? mobileListAreaFooterBackgroundColor : listAreaHeaderBackgroundColor;

    const planFooters =
      isMobileViewport && !closetStyleCard ? (
        ""
      ) : (
        <div className={this.baseClassWith("__ul--footers")} style={this.getStyles(listAreaFooterBackgroundColor)}>
          {planCardFooters}
        </div>
      );

    const customClass = classNames(this.baseClassWith("__ul--headers"), {
      "new-plans-cards": isMobileViewport && !closetStyleCard,
      "closet-style-cards": closetStyleCard,
    });
    const closetStyleFullStyles = this.props.closetStyleCard
      ? {
          flexDirection: isMobileViewport ? "column-reverse" : "row",
          alignItems: "center",
        }
      : {};

    const transformation = this.props.closetStyleCard ? "" : "translate3d(" + this.state.translation + "%, 0, 0)";
    const style = {
      MozTransform: transformation,
      msTransform: transformation,
      transform: transformation,
      WebkitTransform: transformation,
      backgroundColor: backgroundColor,
      ...this.getCardWidth(this.state.pageSize),
      ...closetStyleFullStyles,
    };

    return (
      <>
        <div className={customClass} data-test-id="ul--headers" style={style}>
          {!closetStyleCard && planCardHeaders}
          {closetStyleCard && (
            <div
              data-test-id="ul--headers--colors"
              style={{
                background: closetStyleCardLimitedColor,
                display: "flex",
                paddingTop: 18,
                paddingLeft: 18,
                paddingRight: 14,
                paddingBottom: 48,
                marginBottom: 24,
                borderRadius: 18,
                flexDirection: "column",
                justifyContent: "center",
                marginRight: isMobileViewport ? 24 : 18,
                marginLeft: isMobileViewport ? 24 : "auto",
                maxWidth: "100%",
              }}>
              <div style={{ paddingTop: 18, paddingBottom: 48 }}>
                <div
                  className="customizable-atom--proxima-nova-semibold customizable-atom--18"
                  style={{ paddingBottom: 18 }}>
                  {closetStyleCardLimitedTitle}
                </div>
                <div className="customizable-atom--proxima-nova-regular customizable-atom--14">
                  {closetStyleCardLimitedHed}
                </div>
                <div className="customizable-atom--proxima-nova-regular customizable-atom--14">
                  {closetStyleCardLimitedSubHed}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: isMobileViewport ? "column" : "row" }}>
                {planCardHeadersLimited}
              </div>
            </div>
          )}
          {closetStyleCard && (
            <div
              data-test-id="ul--headers--colors"
              style={{
                background: closetStyleCardFullColor,
                display: "flex",
                paddingTop: 18,
                paddingLeft: 18,
                paddingRight: 14,
                paddingBottom: 48,
                marginBottom: 24,
                borderRadius: 18,
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: isMobileViewport ? 24 : 18,
                marginRight: isMobileViewport ? 24 : "auto",
                maxWidth: "100%",
              }}>
              <div style={{ paddingTop: 18, paddingBottom: 48 }}>
                <div
                  className="customizable-atom--proxima-nova-semibold customizable-atom--18"
                  style={{ paddingBottom: 18 }}>
                  {closetStyleCardFullTitle}
                </div>
                <div className="customizable-atom--proxima-nova-regular customizable-atom--14">
                  {closetStyleCardFullHed}
                </div>
                <div className="customizable-atom--proxima-nova-regular customizable-atom--14">
                  {closetStyleCardFullSubHed}
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", flexDirection: isMobileViewport ? "column" : "row" }}>
                {planCardHeadersFull}
              </div>
            </div>
          )}
        </div>

        {!closetStyleCard && planFooters}

        {!closetStyleCard && this.renderMobileSelectedPlanDetail()}
      </>
    );
  }

  renderSwipeablePlanCards(planCardHeaders, planCardFooters) {
    const { listAreaFooterBackgroundColor, listAreaHeaderBackgroundColor } = this.props;
    const startIndex = this.state.index;
    const endIndex = startIndex + this.state.pageSize;
    const currentPagePlanCardHeaders = planCardHeaders.slice(this.state.index, endIndex);
    const currentPagePlanCardFooters = planCardFooters.slice(this.state.index, endIndex);

    return (
      <>
        <div className={this.baseClassWith("__ul--headers")} style={this.getStyles(listAreaHeaderBackgroundColor)}>
          {currentPagePlanCardHeaders}
        </div>
        <div
          className={this.baseClassWith("__ul--swipeable-container")}
          style={this.getStyles(listAreaFooterBackgroundColor)}>
          <div className={this.baseClassWith("__ul--footers")}>{currentPagePlanCardFooters}</div>
          {this.renderCarouselProgressionDots()}
        </div>
        {this.renderMobileSelectedPlanDetail()}
      </>
    );
  }

  renderCarouselProgressionDots() {
    const { listItems } = this.props;

    return (
      <CarouselProgressionDots
        totalChildren={listItems?.length}
        pageSize={this.state.pageSize - 1}
        currentIndex={this.state.index}
        updatePage={this.handlePlanCardsScroll}
      />
    );
  }

  handlePlanCardsScroll = displacement => {
    const { index, mobileSelectedPlanIndex } = this.state;
    const { listItems } = this.props;
    const maxIndex = listItems.length - this.state.pageSize;
    const canSwipeRight = mobileSelectedPlanIndex < listItems.length - 1 && displacement > 0; // displacement is +1 if swiping left
    const canSwipeLeft = mobileSelectedPlanIndex > 0 && displacement < 0; // displacement is -1 if swiping right

    if (canSwipeRight || canSwipeLeft) {
      this.setState({
        mobileSelectedPlanIndex: mobileSelectedPlanIndex + displacement,
        index: mobileSelectedPlanIndex + displacement,
      });
      if (listItems.length < 3) {
        if (displacement > 0) {
          this.setState({ translation: this.state.translation + this.state.cardsShiftToTwo });
        } else {
          this.setState({ translation: this.state.translation + this.state.cardsShiftToZero });
        }
      } else {
        if (mobileSelectedPlanIndex + displacement === 0) {
          this.setState({ translation: this.state.cardsShiftToZero });
        } else if (mobileSelectedPlanIndex + displacement === 1) {
          this.setState({ translation: this.state.cardsShiftToOne });
        } else if (mobileSelectedPlanIndex + displacement === 2) {
          this.setState({ translation: this.state.cardsShiftToTwo });
        } else {
          this.setState({ translation: this.state.cardsShiftToThree });
        }
      }
      return;
    }

    // NW [EXPLANATION] 3/31/21: you cannot scroll back from index 0, or forward from the max index
    if ((index < 1 && displacement < 0) || (index >= maxIndex && displacement > 0)) {
      return;
    }
    this.setState({
      index: index + displacement,
    });
  };

  getPlanCardCustomBorderStyles(hasHighlightedBorder, hasHoverBorder, item) {
    if (!hasHighlightedBorder && !hasHoverBorder) {
      return {};
    }

    const { mobileSelectedPlanBorderColor, bannerTextBackgroundColor } = this.props;
    const { isMobileViewport } = this.props.browser;
    let boxShadow;

    if (hasHighlightedBorder) {
      const borderColor = isMobileViewport ? mobileSelectedPlanBorderColor : item.borderColor;
      boxShadow = `0 0 0 2px ${borderColor}`;
    }

    if (hasHoverBorder && !isMobileViewport) {
      const hoverBorder = `-12px 15px 0 0 ${bannerTextBackgroundColor}, 12px 15px 0 0 ${bannerTextBackgroundColor}`;
      boxShadow = boxShadow ? `${boxShadow}, ${hoverBorder}` : hoverBorder;
    }

    return {
      boxShadow,
    };
  }

  getCardWidth(pageSize) {
    const { isMobileViewport } = this.props.browser;
    if (!isMobileViewport) {
      return {};
    }
    if (pageSize > 3) {
      return { width: "200%", marginLeft: "-195px", marginRight: "-195px" };
    }
  }

  wrapPlanCardContent(item, index, content) {
    const { listItemBackgroundColor } = this.props;
    const { isMobileViewport } = this.props.browser;
    const { borderColor, membershipTierRevisionId } = item;
    const planIsSelectedOnMobile = this.state.mobileSelectedPlanIndex === index;
    const hasHighlightedBorder = isMobileViewport ? planIsSelectedOnMobile : borderColor;

    const className = classNames(this.baseClassWith("__item-content"), "new-plans-cards", {
      ["solid-border"]: hasHighlightedBorder,
    });

    const closetStyleCardStyles = this.props.closetStyleCard
      ? { width: "auto", maxWidth: 300, marginBottom: isMobileViewport ? 24 : 0, height: "auto" }
      : {};

    const customStyles = {
      ...closetStyleCardStyles,
      ...this.getStyles(listItemBackgroundColor),
      ...this.getPlanCardCustomBorderStyles(hasHighlightedBorder, this.state.hoveredPlanIndex === index, item),
    };

    return (
      <div
        key={index}
        role="presentation"
        className={className}
        style={customStyles}
        data-test-id="plan-card-content"
        onClick={() => {
          this.onSelectPlan(index, membershipTierRevisionId);
        }}
        onMouseEnter={() => this.applyHoverStyles(index, true)}
        onMouseLeave={() => this.applyHoverStyles(index, false)}>
        {content}
      </div>
    );
  }

  renderClosetStylePlanCard(item, index) {
    const {
      bannerTextBackgroundColor,
      bannerTextForegroundColor,
      closetStyleCard,
      closetStyleBannerBackgroundColor,
      closetStyleBannerForegroundColor,
    } = this.props;
    const { isMobileViewport } = this.props.browser;
    const { bannerText } = item;
    const planBanner =
      isMobileViewport && !closetStyleCard
        ? this.renderNewPlansBanner(bannerText, bannerTextBackgroundColor, bannerTextForegroundColor, index)
        : this.renderBanner(
            bannerText,
            closetStyleCard ? closetStyleBannerBackgroundColor : bannerTextBackgroundColor,
            closetStyleCard ? closetStyleBannerForegroundColor : bannerTextForegroundColor
          );

    const content = (
      <>
        {planBanner}
        {this.renderHed(item, index)}
        {this.renderBody(item)}
        {<hr className="plan-card-divider" style={{ marginBottom: 24 }} />}
        {this.renderUnorderedList(item, index, "desktop")}
        <div style={{ maxWidth: "90%", display: "flex", margin: "0 auto" }}>{this.renderCta(item)}</div>
        {this.renderSubHedBanner(item)}
        {!isMobileViewport && this.renderSubHed(item)}
      </>
    );
    return this.wrapPlanCardContent(item, index, content);
  }

  renderPlanCardHeader(item, index) {
    const {
      bannerTextBackgroundColor,
      bannerTextForegroundColor,
      closetStyleCard,
      closetStyleBannerBackgroundColor,
      closetStyleBannerForegroundColor,
    } = this.props;
    const { isMobileViewport } = this.props.browser;
    const { bannerText } = item;
    const planBanner = isMobileViewport
      ? this.renderNewPlansBanner(bannerText, bannerTextBackgroundColor, bannerTextForegroundColor, index)
      : this.renderBanner(
          bannerText,
          closetStyleCard ? closetStyleBannerBackgroundColor : bannerTextBackgroundColor,
          closetStyleCard ? closetStyleBannerForegroundColor : bannerTextForegroundColor
        );

    const content = (
      <>
        {planBanner}
        {this.renderHed(item, index)}
        {!isMobileViewport && this.renderBody(item)}
      </>
    );
    return this.wrapPlanCardContent(item, index, content);
  }

  renderPlanCardFooter(item, index) {
    const { isMobileViewport } = this.props.browser;

    const content = (
      <>
        <hr className="plan-card-divider" />
        {!isMobileViewport && this.renderUnorderedList(item, index, "desktop")}
        {!isMobileViewport && this.renderCta(item)}
        {!isMobileViewport && this.renderSubHedBanner(item)}
        {!isMobileViewport && this.renderSubHed(item)}
        {isMobileViewport && this.renderMobilePlanCardFooter(item)}
      </>
    );
    return this.wrapPlanCardContent(item, index, content);
  }

  getBackgroundStyle(bannerTextBackgroundColor, bannerTextForegroundColor) {
    const backgroundStyle = bannerTextBackgroundColor ? this.getStyles(bannerTextBackgroundColor) : {};
    const style = {
      ...backgroundStyle,
      color: bannerTextForegroundColor,
    };

    return style;
  }

  renderBanner(bannerText, bannerTextBackgroundColor = "", bannerTextForegroundColor = "") {
    if (!bannerText) {
      return;
    }

    const style = this.getBackgroundStyle(bannerTextBackgroundColor, bannerTextForegroundColor);

    return (
      <div className={this.baseClassWith("__banner")} style={style}>
        {bannerText}
      </div>
    );
  }

  renderNewPlansBanner(bannerText, bannerTextBackgroundColor = "", bannerTextForegroundColor = "", planIndex) {
    const className = classNames("plan-checked", {
      "plan-checked-icon": planIndex === this.state.mobileSelectedPlanIndex,
      "not-selected": !this.props.closetStyleCard && planIndex !== this.state.mobileSelectedPlanIndex,
    });
    const bannerClassName = classNames("new-plans-banner", {
      "not-selected": !this.props.closetStyleCard && planIndex !== this.state.mobileSelectedPlanIndex,
    });

    const style = this.getBackgroundStyle(bannerTextBackgroundColor, bannerTextForegroundColor);

    return (
      <div className={this.baseClassWith("__banner-container")}>
        {bannerText && (
          <div className={bannerClassName} style={style}>
            {bannerText}
          </div>
        )}
        <div className={className} />
      </div>
    );
  }

  renderSubHedBanner(item) {
    const { subHedBannerTextBackgroundColor } = this.props;
    if (!item.subHedBannerText) {
      return null;
    }
    const backgroundStyle = subHedBannerTextBackgroundColor ? this.getStyles(subHedBannerTextBackgroundColor) : {};

    return (
      <p className={`${this.baseClassWith("__sub-hed-banner")} universal-xsmall`} style={backgroundStyle}>
        {item.subHedBannerText}
      </p>
    );
  }

  renderHed(item, index) {
    if (!item.hed) {
      return;
    }
    const { listHedAttributes } = this.props;
    const className = classNames(this.baseClassWith("__hed"), {
      "not-selected": !this.props.closetStyleCard && this.state.mobileSelectedPlanIndex !== index,
    });

    return (
      <AtomDek
        text={item.hed}
        dekAttributes={listHedAttributes}
        mobileText={item.newMobileHed}
        mobileDekAttributes={listHedAttributes}
        customClass={className}
      />
    );
  }

  renderSubHed(item) {
    if (!item.subHed) {
      return;
    }
    const { listSubHedAttributes, mobileListSubHedAttributes } = this.props;
    const { isMobileViewport } = this.props.browser;

    return (
      <div ref={element => (this.$target = element)} className={this.baseClassWith("__sub-hed-container")}>
        <AtomDek
          text={item.subHed}
          dekAttributes={listSubHedAttributes}
          mobileText={item?.newMobileSubHed?.text}
          mobileDekAttributes={mobileListSubHedAttributes}
          customClass={this.baseClassWith("__sub-hed")}
        />
        {isMobileViewport && this.addTooltip(item.newMobileSubHed, this.$target)}
      </div>
    );
  }

  renderSubHedImages(item) {
    if (!item.mobilePlanFirstImage) {
      return;
    }

    return (
      <div className={this.baseClassWith("__sub-hed-image-container")}>
        <div className="sub-hed-image" style={this.getStyles(item.mobilePlanImageBackgroundColor)}>
          <p>{item.mobilePlanFirstImageTitle}</p>
          <RtrImage
            src={item.mobilePlanFirstImage}
            alt={item.imageAlt}
            style={{ "--aspect-ratio": item.mobilePlanFirstImageAspectRatio }}
          />
        </div>
        <div className="sub-hed-image" style={this.getStyles(item.mobilePlanImageBackgroundColor)}>
          <p>{item.mobilePlanSecondImageTitle}</p>
          <RtrImage
            src={item.mobilePlanSecondImage}
            alt={item.imageAlt}
            style={{ "--aspect-ratio": item.mobilePlanSecondImageAspectRatio }}
          />
        </div>
      </div>
    );
  }

  renderMobilePlanCardFooter(item) {
    if (!item || !item.mobilePlanCardFooterText) {
      return null;
    }

    return <p className={this.baseClassWith("__mobile-plan-card-footer")}>{item.mobilePlanCardFooterText}</p>;
  }

  renderBody(item) {
    if (!item.body) {
      return;
    }

    return (
      <AtomDek
        text={item.body}
        dekAttributes={this.props.listBodyAttributes}
        mobileText={item.mobileBody}
        mobileDekAttributes={this.props.mobileListBodyAttributes}
        customClass={this.baseClassWith("__body")}
      />
    );
  }

  addTooltip(tooltipParentOrParents, refBoundary) {
    //Attempting to render MoleculeTooltip when the refBoundary exists but the refTarget does not causes the page to error
    //This would happen when there is no tooltip specified for any of the bullets
    const { isMobileViewport } = this.props.browser;

    const hasTooltip = isMobileViewport
      ? !!tooltipParentOrParents?.tooltip
      : tooltipParentOrParents.some(item => item.tooltip);

    if (!hasTooltip) {
      return;
    }

    let tooltipContent;
    if (!refBoundary) return null;

    // both the desktop and mobile buttons are rendered, so be sure we pick the
    // correct one, given the view
    const refTarget = refBoundary.querySelector(".customizable-atom button");

    if (isMobileViewport) {
      tooltipContent = tooltipParentOrParents.tooltip;
    } else {
      tooltipParentOrParents.forEach(item => {
        if (item.tooltip) {
          tooltipContent = item.tooltip;
        }
      });
    }

    return (
      <MoleculeTooltip
        style={{ marginLeft: "0" }}
        target={refTarget}
        isOpen={false}
        placement={TOOLTIP.PLACEMENTS.TOP}
        closeOnOutsideClick={true}
        // mobile is covered even when you choose hover
        mode={TOOLTIP.MODE.HOVER}
        timeout={isMobileViewport ? 5000 : null}
        boundaryElement={refBoundary}
        boundaryPadding={"0"}
        className="molecule-plans--tooltip"
        modifiers={{
          offset: {
            offset: isMobileViewport ? "0, 5px" : "0, 0",
          },
        }}>
        {tooltipContent}
      </MoleculeTooltip>
    );
  }

  renderUnorderedList(item, index, refSuffix) {
    if (!item.bulletListItems) {
      return;
    }

    const {
      bulletIcon,
      listBulletTextAttributes,
      mobileBulletIcon,
      mobileListBulletTextAttributes,
      retinaBulletIcon,
    } = this.props;
    const { isMobileViewport } = this.props.browser;

    const retinaIcon = isMobileViewport && mobileBulletIcon ? mobileBulletIcon : retinaBulletIcon;
    const refBoundary = this[`setTooltipRef${index}-${refSuffix}`];

    return (
      <div ref={refBoundary} className={this.baseClassWith("__list-container")}>
        <AtomUnorderedList
          bulletIcon={bulletIcon}
          retinaBulletIcon={retinaIcon}
          listItems={item.bulletListItems}
          listItemAttributes={listBulletTextAttributes}
          mobileListItemAttributes={mobileListBulletTextAttributes}
        />
        {this.addTooltip(item.bulletListItems, refBoundary?.current)}
      </div>
    );
  }

  shouldSeeAuthModal() {
    const { userData } = this.props;

    return !isAuthorized(userData);
  }

  handleAddSubscription = (addToCartHref, tierId) => {
    const { showAuthModal, flagsAndExperiments, userData } = this.props;
    const { DisplayStyle } = authFormConstants;
    /**
     * if the user isn't logged in and the flag is on, send them to delayed account creation checkout
     */
    if (
      flagsAndExperiments?.[flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION] &&
      isAnonymous(userData) &&
      tierId
    ) {
      tryCatchLog(() => {
        navigateTo(`${DelayedAccountCreation.CHECKOUT_URL}/${tierId}`);
      });
    } else if (this.shouldSeeAuthModal()) {
      //We want to show users a specific version of the auth form in some cases, so it needs to be triggered manually
      //Otherwise the middleware will trigger the default auth form when we attempt to ATB by navigating to the href
      showAuthModal({
        destination: addToCartHref,
        triggeredBy: HEAP_AUTH_TRIGGER_TYPES.CLICK_ADD_PLAN_TO_BAG,
        displayStyle: DisplayStyle.PLANS_PAGE,
        isFullScreen: true,
      });
    } else {
      tryCatchLog(() => navigateTo(addToCartHref));
    }
  };

  onPlanCtaClick = (item, stickyCta) => {
    this.sendAnalyticsData(item, stickyCta);
    this.props.onPlanCtaClick(item);
  };

  sendAnalyticsData(item, stickyCta) {
    const { membershipTiers, sendSubscriptionAtbAnalytics } = this.props;
    const selectedTier = membershipTiers?.find(membership => membership.id === item.cta.tierId);
    this.logCtaAction(item, stickyCta, selectedTier);
    sendSubscriptionAtbAnalytics(selectedTier.id);
    this.handleAddSubscription(item.cta.href, item.cta.tierId);
  }

  renderCta(item) {
    const { cta = {} } = item;
    if (!cta.text) {
      return;
    }

    const ctaClassName = classNames(this.baseClassWith(`__cta--${cta.type}`), "new-plans-cards", {
      primary: !cta.isSecondaryCta,
      secondary: cta.isSecondaryCta,
      disabled: cta.disabled,
    });

    return (
      <button
        className={ctaClassName}
        disabled={cta.disabled}
        onClick={() => this.onPlanCtaClick(item)}
        ref={this.plansCtaRef}
        data-heap-id={HEAP_ADD_TO_BAG_IDS.SUBSCRIPTION_PURCHASE}>
        {cta.text}
      </button>
    );
  }

  renderStickyCta(item) {
    const { stickyCta = {} } = item;

    if (!stickyCta.text) {
      return;
    }

    const ctaContainerClassName = classNames(this.baseClassWith("__sticky-cta"), {
      visible: this.state.displayStickyCta,
    });
    const ctaClassName = this.baseClassWith(`__cta--${stickyCta.type}`);

    return (
      <div className={ctaContainerClassName}>
        <button
          className={ctaClassName}
          disabled={stickyCta.disabled}
          onClick={() => this.onPlanCtaClick(item, true)}
          data-heap-id={HEAP_ADD_TO_BAG_IDS.SUBSCRIPTION_PURCHASE}>
          {stickyCta.text}
        </button>
      </div>
    );
  }

  render() {
    const wrapperClassName = classNames(this.baseClassWith("__items-wrapper"), {
      [this.baseClassWith("__items-wrapper--no-title")]: !this.props.hedText && !this.props.dekText,
      [this.baseClassWith("--mobile-reverse")]: this.props.mobileOrder === "reverse",
    });

    const itemsClassName = classNames(this.baseClassWith("__ul"), {
      "new-plans-cards": this.props.browser.isMobileViewport,
    });

    return (
      <div
        data-test-id="molecule-plans"
        className={this.baseClassWith("__container")}
        style={this.getStyles(this.props.backgroundColor)}
        ref={this.elementRef}>
        <AtomHed
          text={this.props.hedText || ""}
          type="h2"
          hedAttributes={this.props.hedAttributes}
          mobileText={this.props.mobileHedText}
          mobileHedAttributes={this.props.mobileHedAttributes}
        />
        <AtomDek
          text={this.props.dekText || ""}
          hedAttributes={this.props.dekAttributes}
          mobileText={this.props.mobileDekText}
          mobileHedAttributes={this.props.mobileDekAttributes}
        />
        <div
          className={wrapperClassName}
          style={this.getStyles(this.props.listAreaBackgroundColor)}
          ref={this.swipeableCardsRef}>
          <ul className={itemsClassName} style={{ ...this.getStyles(this.props.mobileListAreaFooterBackgroundColor) }}>
            {this.renderItems()}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  browser: state.browser,
  membershipTiers: state.membershipTiers,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchFeatureFlagConfiguration: () =>
      dispatch(
        FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION)
      ),
    showAuthModal: options => {
      dispatch(AuthActions.showAuthModal(options));
    },
  };
};

export default compose(
  withUserData,
  withSubscriptionAtbAnalytics,
  withFlagsAndExperiments(flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION),
  connect(mapStateToProps, mapDispatchToProps)
)(MoleculePlans);
