import React from "react";
import PropTypes from "prop-types";

const datepickerHeadlineClass = "datepicker-title";

const DatepickerHeadline = ({ headline, breakDefaultHeadline }) => {
  const getHeadlineText = () => {
    if (headline) {
      return headline;
    }

    const textLines = {
      firstLine: "Pick a delivery date 1",
      secondLine: "2 days ",
      thirdLine: "before your event",
    };

    if (breakDefaultHeadline) {
      return [
        textLines.firstLine,
        <span key="en-dash">&ndash;</span>,
        textLines.secondLine,
        <br key="br" />,
        textLines.thirdLine,
      ];
    }

    return [textLines.firstLine, <span key="en-dash">&ndash;</span>, textLines.secondLine + textLines.thirdLine];
  };

  return <div className={datepickerHeadlineClass}>{getHeadlineText()}</div>;
};

DatepickerHeadline.propTypes = {
  headline: PropTypes.string,
  breakDefaultHeadline: PropTypes.bool,
};

export default DatepickerHeadline;
