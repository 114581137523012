import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { isAuthorized, withUserData, userDataPropType } from "components/source/hoc/with-user-data";
import { authFormConstants } from "rtr-constants/auth";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import AuthActions from "actions/auth-actions";
import ActionLogger from "action-logger";
import { navigateTo } from "../../../helpers/location-helpers";
import tryCatchLog from "../../../helpers/try-catch";

/**
 * A CTA which optionally checks auth state in the browser before navigating to an href.
 * This exists so marketing can configure auth triggers on CMS-driven page CTAs
 * If user is authorized, does so immediately.
 * If not, shows auth and fires the onClick handler after successful login/account creation.
 * This is not ideal, but due to how we handle auth in Ruby it's required to enable dynamic auth form styling.
 */
export class MoleculeCtaWithOptionalAuth extends React.Component {
  static propTypes = {
    authFormDisplayStyle: PropTypes.oneOf(Object.values(authFormConstants.DisplayStyle)),
    className: PropTypes.string,
    ctaText: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    /**
     * Any third party logging actions (Tatari, etc) to fire on click
     */
    handleAdditionalLogging: PropTypes.func,
    heapTriggeredBy: PropTypes.oneOf(Object.values(HEAP_AUTH_TRIGGER_TYPES)).isRequired,
    href: PropTypes.string.isRequired,
    isFullScreenAuthModal: PropTypes.bool,
    /**
     * Pixel hash to fire on click action.
     */
    pixelData: PropTypes.object,
    requiresAuthentication: PropTypes.bool.isRequired,
    showAuthModal: PropTypes.func.isRequired,
    userData: userDataPropType,
  };

  static defaultProps = {
    disabled: false,
    handleAdditionalLogging: () => {},
    requiresAuthentication: false,
  };

  handleLogging = () => {
    const { pixelData, handleAdditionalLogging, requiresAuthentication, userData } = this.props;

    handleAdditionalLogging();

    if (pixelData && Object.keys(pixelData).length) {
      //If the user will see auth modal on the same page, log immediately
      //Else, defer logging to the following pageload
      if (requiresAuthentication && !isAuthorized(userData)) {
        ActionLogger.logAction(pixelData);
      } else {
        ActionLogger.inferAction(pixelData);
      }
    }
  };

  navigateWithAuthCheck = () => {
    const { authFormDisplayStyle, heapTriggeredBy, href, isFullScreenAuthModal, showAuthModal, userData } = this.props;

    this.handleLogging();

    if (!isAuthorized(userData)) {
      showAuthModal({
        destination: href,
        displayStyle: authFormDisplayStyle,
        isFullScreen: isFullScreenAuthModal,
        triggeredBy: heapTriggeredBy,
      });
    } else {
      tryCatchLog(() => navigateTo(href));
    }
  };

  render() {
    const { className, ctaText, disabled, href, requiresAuthentication } = this.props;

    return requiresAuthentication ? (
      <button disabled={disabled} className={className} onClick={this.navigateWithAuthCheck}>
        {ctaText}
      </button>
    ) : (
      <a href={href} className={className} onClick={this.handleLogging}>
        {ctaText}
      </a>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showAuthModal: options => {
      dispatch(AuthActions.showAuthModal(options));
    },
  };
};

export default compose(withUserData, connect(null, mapDispatchToProps))(MoleculeCtaWithOptionalAuth);
