import PropTypes from "prop-types";
/**
 * Reservation calendar used for seeing availability and setting
 * rental pickup and return dates.
 * Has 4 elements:
 * - Headline (with option to break it)
 * - (variably displayed) Subhead
 * - Errors / validation (if any)
 * - Datepicker
 * - Legend
 */
import Datepicker from "components/source/new_taxonomy/datepicker";
import DatepickerHeadline from "components/source/shared/calendars/datepicker-headline";

export default class ReservationCalendar extends React.Component {
  static propTypes = {
    blackoutDays: PropTypes.arrayOf(PropTypes.string),
    breakDefaultHeadline: PropTypes.bool,
    date: PropTypes.string,
    dateEnd: PropTypes.string,
    dateStart: PropTypes.string,
    deliveryCutoffs: PropTypes.array,
    duration: PropTypes.number,
    error: PropTypes.string,
    headline: PropTypes.string,
    idSuffix: PropTypes.string,
    logging: PropTypes.object,
    loggingObjectType: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    return (
      <div>
        <DatepickerHeadline headline={this.props.headline} breakDefaultHeadline={this.props.breakDefaultHeadline} />
        <Datepicker
          blackoutDays={this.props.blackoutDays}
          date={this.props.date}
          dateEnd={this.props.dateEnd}
          dateStart={this.props.dateStart}
          deliveryCutoffs={this.props.deliveryCutoffs}
          duration={this.props.duration}
          error={this.props.error}
          idSuffix={this.props.idSuffix}
          logging={this.props.logging}
          loggingObjectType={this.props.loggingObjectType}
          name={this.props.name}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}
