import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";

import AtomUnorderedList from "components/source/atoms/atom-unordered-list";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";
import RtrImage from "../shared/rtr-image";

class MoleculeBulletList extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string, // Hex value
    bulletIcon: PropTypes.string,
    desktopColumns: PropTypes.string,
    hedText: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    dekText: PropTypes.string,
    dekAttributes: atomTextAttributesPropType,
    mobileDekText: PropTypes.string,
    mobileDekAttributes: atomTextAttributesPropType,
    href: PropTypes.string,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    imageAlign: PropTypes.string, // Aligns the item to the left or right of the block; default is left
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      })
    ).isRequired,
    listItemAttributes: PropTypes.object,
    retinaBulletIcon: PropTypes.string,
  };

  static className = "molecule-bullet-list";
  static headerType = "h2";

  getStyles = () => {
    var styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  };

  baseClassWith = element => {
    return [this.constructor.className, element].join("");
  };

  constructWrapperClass = () => {
    // If we specify the number of desktop columns, we should add a class
    // to the wrapping element
    var classArray = [this.baseClassWith("__container")];
    if (!_.isEmpty(this.props.desktopColumns)) {
      classArray.push(this.baseClassWith("--" + this.props.desktopColumns + "-column"));
    }

    if (!_.isEmpty(this.props.imageAlign)) {
      classArray.push(`image-align--${this.props.imageAlign}`);
    }

    return classArray.join(" ");
  };

  renderHed = () => {
    if (_.isEmpty(this.props.hedText)) {
      return;
    }

    return (
      <AtomHed text={this.props.hedText} type={this.constructor.headerType} hedAttributes={this.props.hedAttributes} />
    );
  };

  renderImage = () => {
    if (_.isEmpty(this.props.imageUrl)) {
      return;
    }

    const inner = <RtrImage src={this.props.imageUrl} alt={this.props.imageAlt} />;

    if (!_.isEmpty(this.props.href)) {
      return (
        <a href={this.props.href} className={this.baseClassWith("__image")}>
          {inner}
        </a>
      );
    }

    return <div className={this.baseClassWith("__image")}>{inner}</div>;
  };

  renderDekText = () => {
    if (_.isEmpty(this.props.dekText)) {
      return;
    }

    return (
      <AtomDek
        text={this.props.dekText}
        dekAttributes={this.props.dekAttributes}
        mobileText={this.props.mobileDekText}
        mobileDekAttributes={this.props.mobileDekAttributes}
      />
    );
  };

  render() {
    return (
      <div className={this.constructWrapperClass()} style={this.getStyles()} data-test-id="molecule-bullet-list">
        {this.renderHed()}
        <div className={this.baseClassWith("__inner")}>
          {this.renderImage()}
          <div className={this.baseClassWith("__content")}>
            {this.renderDekText()}
            <AtomUnorderedList
              bulletIcon={this.props.bulletIcon}
              retinaBulletIcon={this.props.retinaBulletIcon}
              listItems={this.props.listItems}
              listItemAttributes={this.props.listItemAttributes}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MoleculeBulletList;
