import React, { Component } from "react";
import PropTypes from "prop-types";
import { withShareChannelBehavior } from "./with-share-channel-behavior";

export class EmailLinkShare extends Component {
  static propTypes = {
    buttonClassNames: PropTypes.string,
    buttonCopy: PropTypes.string,
    hasSharedStyles: PropTypes.bool,
    onShareSuccess: PropTypes.func,
    shortenedUrl: PropTypes.string,
  };

  static defaultProps = {
    hasSharedStyles: false,
  };

  // do not pass `onShareSuccess` as the event handler directly or
  // it will receive a JavaScript Event as a parameter which breaks
  // downstream code
  handleClick = () => {
    this.props.onShareSuccess();
  };

  composeUrl() {
    let url = "mailto:";
    let emailCopy;

    const emailSubject = this.props?.landingCopy?.subject;

    if (this.props.hasSharedStyles) {
      emailCopy = this.props?.landingCopy?.eventShare?.emailLink;
    } else {
      emailCopy = this.props?.landingCopy?.emailLink;
    }

    url += "?subject=" + encodeURIComponent(emailSubject);
    url += "&body=" + encodeURIComponent(emailCopy);
    url += encodeURIComponent("\n\n") + this.props.shortenedUrl;

    return url;
  }

  render() {
    return (
      <a
        className={this.props.buttonClassNames}
        onClick={this.handleClick}
        target="_blank"
        rel="noopener noreferrer"
        href={this.composeUrl()}>
        {this.props.buttonCopy}
      </a>
    );
  }
}

export default withShareChannelBehavior({ type: "emailLink" })(EmailLinkShare);
