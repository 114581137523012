import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import AtomStatsCircleWithAnimation from "components/source/atoms/atom-stats-circle-with-animation";
import { emitDeprecationEvent } from "helpers/deprecation-alert";

const baseClass = "molecule-stats-circles";

class MoleculeStatsCircles extends React.Component {
  static propTypes = {
    hedText: PropTypes.string,
    dekText: PropTypes.string,
    statsCircles: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string,
        dek: PropTypes.string,
      })
    ),
  };

  componentDidMount() {
    emitDeprecationEvent("MoleculeStatsCircles");
  }

  renderStats() {
    const { statsCircles } = this.props;

    return (
      <div className={`${baseClass}__stats`}>
        {_.map(statsCircles, stat => {
          return <AtomStatsCircleWithAnimation {...stat} />;
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={`${baseClass} ${baseClass}--${this.props.statsCircles.length}`}>
        <AtomHed
          text={this.props.hedText}
          mobileText={this.props.mobileHedText}
          hedAttributes={this.props.hedAttributes}
          mobileHedAttributes={this.props.mobileHedAttributes}
        />
        <AtomDek
          text={this.props.dekText}
          mobileText={this.props.mobileDekText}
          dekAttributes={this.props.dekAttributes}
          mobileDekAttributes={this.props.mobileDekAttributes}
        />
        {this.renderStats()}
      </div>
    );
  }
}

export default MoleculeStatsCircles;
