import React, { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";

import EmailShareAnimated from "components/source/social/email-share-animated";
import { referralProgramTypes } from "rtr-constants";
import { withShareChannelBehavior } from "./with-share-channel-behavior";
import TextInputPair from "../shared/text-input-pair";

export class EmailShare extends Component {
  static EMAIL_PATTERN = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

  static propTypes = {
    animatedTextInput: PropTypes.bool,
    animatedTextInputAdditionalClassName: PropTypes.string,
    buttonCopy: PropTypes.string,
    buttonDisabled: PropTypes.bool.isRequired,
    buttonText: PropTypes.string,
    identifiedUser: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }),
    label: PropTypes.string,
    onShareSuccess: PropTypes.func,
    placement: PropTypes.string,
    shareCreateStatus: PropTypes.string,
    submissionError: PropTypes.string,
  };

  static defaultProps = {
    animatedTextInput: false,
    buttonCopy: "",
    identifiedUser: null,
    label: "",
    style: "button", // doesn't matter.
    hasSharedStyles: false,
    programType: referralProgramTypes.classic,
    placement: "",
  };

  state = {
    firstName: "",
    userEmail: "",
    friendEmails: [],
    submittedEmailString: "",
    validationError: null,
  };

  componentDidMount() {
    if (!this.props.identifiedUser) {
      return;
    }

    this.setState({
      firstName: this.props.identifiedUser.firstName,
      userEmail: this.props.identifiedUser.email,
    });
  }

  handleEmailUpdate = event => {
    this.setState({
      submittedEmailString: event.target.value,
    });
  };

  updateEmail = e => {
    this.setState({
      email: e.target.value,
    });
  };

  allEmailsValid(emails) {
    return _.all(
      emails,
      function (email) {
        return email.match(this.constructor.EMAIL_PATTERN);
      },
      this
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    const { placement } = this.props;

    const emails = _.chain(this.state.submittedEmailString.split(/,[\ ]*/))
      .compact()
      .uniq()
      .value();

    if (!emails.length || !this.allEmailsValid(emails)) {
      this.setState({
        validationError: "One or more email addresses is invalid.",
      });

      return;
    }

    this.setState({
      friendEmails: emails,
      submittedEmailString: "",
      validationError: null,
    });

    // @todo: send all emails in one request
    _.each(
      emails,
      function (email) {
        this.props.onShareSuccess(email, placement);
      },
      this
    );
  };

  handleAnimatedInputSubmit = email => {
    const { placement } = this.props;
    // this method comes from the ShareChannelMixin
    this.props.onShareSuccess(email, placement);
  };

  renderAnimatedForm() {
    return (
      <EmailShareAnimated
        additionalClassName={this.props.animatedTextInputAdditionalClassName}
        buttonText={this.props.buttonText}
        handleSubmit={this.handleAnimatedInputSubmit}
        label={this.props.label}
        shareCreateStatus={this.props.shareCreateStatus}
        buttonDisabled={this.props.buttonDisabled}
        submissionError={this.props.submissionError}
        validationError={this.state.validationError}
      />
    );
  }

  render() {
    if (this.props.animatedTextInput) {
      return this.renderAnimatedForm();
    }

    const user = this.props.identifiedUser;
    const firstName = user?.firstName;
    const email = user?.email;

    return (
      <div className="email-share">
        <div className="error">{this.state.validationError}</div>
        <form method="post" onSubmit={this.handleSubmit}>
          <div className="email-share__user-info-wrapper">
            <TextInputPair
              label="Name"
              id="firstName"
              name="firstName"
              inputProps={{
                disabled: firstName,
                placeholder: "Your first name",
              }}
              value={this.state.firstName}
              onChange={this.updateName}
              wrapperClass="email-share__name"
            />

            <TextInputPair
              label="Your email address"
              id="email"
              name="email"
              inputProps={{
                disabled: email,
                placeholder: "Your email address",
              }}
              value={this.state.userEmail}
              onChange={this.updateEmail}
              wrapperClass="email-share__email"
            />
          </div>

          <input type="hidden" value={this.state.emails} />

          <TextInputPair
            label="Email Addresses"
            id="email_addresses"
            name="emails[]"
            inputProps={{
              placeholder: "Enter your friends' emails separated by a comma",
            }}
            value={this.state.submittedEmailString}
            onChange={this.handleEmailUpdate}
          />
          <button type="submit" className="btn email-share__button">
            {this.props.buttonCopy}
          </button>
        </form>
      </div>
    );
  }
}

export default withShareChannelBehavior({ type: "email" })(EmailShare);
