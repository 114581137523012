import React from "react";
import ActionLogger from "action-logger";
import AtomDek from "components/source/atoms/atom-dek";

import classNames from "classnames";
import PropTypes from "prop-types";

import {
  browserPropType,
  childrenPropType,
  atomHedAttributesPropType,
  atomTextAttributesPropType,
  shoppablePropType,
} from "components/propTypes";
import GenericHeroUnitPicture from "components/source/shared/generic-hero-unit-picture";
import { authFormConstants } from "rtr-constants/auth";
import MoleculeCtaWithOptionalAuth from "../molecules/molecule-cta-with-optional-auth";
import { HEAP_AUTH_TRIGGER_TYPES } from "../../../helpers/heap-helpers";
import { isSSR } from "../../../helpers/client-server-helper";
import MoleculeRotatingHedText from "components/source/molecules/molecule-rotating-hed-text";
import ShoppableOverlay from "./shoppable-overlay/shoppable-overlay";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

const propTypes = {
  backgroundColor: PropTypes.string,
  browser: browserPropType,
  children: childrenPropType,
  cmsUrl: PropTypes.string,
  colorScheme: PropTypes.string, // Can be "black" or "white"
  cta: PropTypes.shape({
    action: PropTypes.string,
    /**
     * Version of the auth form to display if requiresAuthentication is true.
     */
    authFormStyle: PropTypes.shape({
      displayStyle: PropTypes.oneOf(Object.values(authFormConstants.DisplayStyle)),
      isFullScreen: PropTypes.bool,
    }),
    extraPixelData: PropTypes.object,
    hideOnMobile: PropTypes.bool,
    objectType: PropTypes.string,
    /**
     * Trigger the login/auth form prior to navigating to the href if the user isn't authorized.
     */
    requiresAuthentication: PropTypes.bool,
  }),
  ctaButtonHref: PropTypes.string,
  ctaButtonText: PropTypes.string,
  ctaCallback: PropTypes.func,
  customClass: PropTypes.string, // Custom class to add
  dekAttributes: atomTextAttributesPropType,
  dekText: PropTypes.string,
  hedAttributes: atomHedAttributesPropType,
  hedText: PropTypes.string,
  heightType: PropTypes.string, // Can be "short" or "tall"
  heroCtaText: PropTypes.string, // Old-style, should be deprecated if possible
  imageAlt: PropTypes.string,
  imageURL: PropTypes.string,
  imageURLAspectRatio: PropTypes.string,
  index: PropTypes.number, // index of this module in a CMS LP
  legalCtaLink: PropTypes.shape({
    href: PropTypes.string,
    link: PropTypes.string,
  }),
  legalText: PropTypes.string,
  maxWidth: PropTypes.string,
  mobileCtaType: PropTypes.string, // Can be "link", defaults to "button"
  mobileColorScheme: PropTypes.string,
  mobileDekAttributes: atomTextAttributesPropType,
  mobileDekText: PropTypes.string,
  mobileHedAttributes: atomHedAttributesPropType,
  mobileHedText: PropTypes.string,
  mobileImageURL: PropTypes.string,
  mobileImageURLAspectRatio: PropTypes.string,
  mobilePlaceholderImageUrl: PropTypes.string,
  mobilePosition: PropTypes.string,
  mobileTextAlign: PropTypes.string, // Aligns text on mobile
  mobileVerticalAlign: PropTypes.string, // Vertical alignment of the text on mobile screens
  newPlansBackgroundColor: PropTypes.string,
  position: PropTypes.string, // Positions the text block to the left, right, or center
  mobileRelativePosition: PropTypes.bool, // Position the CTA wrapper relatively instead of absolutely
  rotatingWordOptions: PropTypes.arrayOf(PropTypes.string),
  scrollAction: PropTypes.string,
  scrollPixelObject: PropTypes.object,
  shouldLogScroll: PropTypes.bool,
  shoppable: shoppablePropType,
  subCtaLink: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  surHeroText: PropTypes.string,
  textAlign: PropTypes.string, // Aligns text - left, right, or center
  verticalAlign: PropTypes.string, // Vertical alignment of the text - top, middle, or bottom
};

export class GenericHeroUnit extends React.Component {
  elementRef = React.createRef(null);

  componentDidMount() {
    this.scrollPixelLogger = this.createPixelLogger();
  }

  componentWillUnmount() {
    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  createPixelLogger = () => {
    const { scrollPixelObject, shouldLogScroll, scrollAction } = this.props;

    if (!scrollPixelObject && !shouldLogScroll) {
      return null;
    }

    const pixelData = scrollPixelObject ?? {
      objectType: "scroll_read",
      action: scrollAction,
    };

    return createScrollIntoViewPixelLogger(this.elementRef, "scroll_read", pixelData);
  };

  getPixelData(cta) {
    //Don't access window properties as they break SSR
    //This is used in onClick actions that aren't fired there anyway
    if (isSSR()) {
      return {};
    }

    const text = this.props.ctaButtonText ?? "";
    const logInfo = {
      // These `get` functions allow us to pass through in the CTA object from the CMS
      object_type: cta?.objectType ?? "cta_click",
      action: cta?.action ?? "generic_hero_unit",
      context: "click",
      url: window.location.pathname,
      component_type: this.props.cmsUrl,
      copy: text,
      ...cta?.extraPixelData,
    };

    if (cta?.styleName) {
      logInfo.style_name = cta.styleName;
    }

    return logInfo;
  }

  logSubCtaAction(cta) {
    const pixelData = this.getPixelData(cta);
    ActionLogger.inferAction(pixelData);
  }

  logLegalCta() {
    const logInfo = {
      object_type: this.props.cta?.objectType ?? "generic_hero_unit",
      action: this.props.cta?.action ?? "legal_cta_click",
      url: window.location.pathname,
    };

    ActionLogger.inferAction(logInfo);
  }

  renderShoppable = () => {
    const { shoppable } = this.props;

    if (!shoppable || !shoppable?.products?.length) return;

    return <ShoppableOverlay shoppable={shoppable} />;
  };

  renderHeroBackgroundImage() {
    let extraClass, heroMaxWidth, backgroundImageConstruct;

    if (this.props.maxWidth) {
      extraClass = "hero-wrapper--has-max-width";
      heroMaxWidth = this.props.maxWidth;
    }

    if (this.props.mobilePlaceholderImageUrl) {
      backgroundImageConstruct = `url('${this.props.mobilePlaceholderImageUrl}')`;
    }

    const heroWrapperStyling = {
      backgroundImage: backgroundImageConstruct,
      backgroundPosition: "top center",
      maxWidth: heroMaxWidth,
    };

    // NW [EXPLANATION] 2/27/24: if this hero unit is one of the first 3 modules on a page, its image should be given high priority for loading order
    const hasHighPriority = typeof this.props.index === "number" && this.props.index < 3;

    return (
      <div className={`hero-wrapper ${extraClass || ""}`} style={heroWrapperStyling}>
        <GenericHeroUnitPicture
          fetchpriority={hasHighPriority ? "high" : "auto"}
          mobileImageURL={this.props.mobileImageURL}
          imageURL={this.props.imageURL}
          imageAlt={this.props.imageAlt}
          imageMobileAspectRatio={this.props.mobileImageURLAspectRatio}
          imageDesktopAspectRatio={this.props.imageURLAspectRatio}
        />
      </div>
    );
  }

  renderSurHeroText() {
    if (!this.props.surHeroText) {
      return false;
    }

    return <div className="hero__surtext" dangerouslySetInnerHTML={{ __html: this.props.surHeroText }}></div>;
  }

  renderHed() {
    return (
      <MoleculeRotatingHedText
        hedAttributes={this.props.hedAttributes}
        hedText={this.props.hedText || this.props.heroCtaText}
        isMobileViewport={this.props.browser?.isMobileViewport}
        mobileHedAttributes={this.props.mobileHedAttributes}
        mobileHedText={this.props.mobileHedText}
        rotatingWordOptions={this.props.rotatingWordOptions}
      />
    );
  }

  renderDekText() {
    const { browser: { isMobileViewport } = {} } = this.props;
    if (!this.props.dekText || (!this.props.mobileDekText && isMobileViewport)) {
      return null;
    }

    return (
      <AtomDek
        text={this.props.dekText}
        dekAttributes={this.props.dekAttributes}
        mobileText={this.props.mobileDekText}
        mobileDekAttributes={this.props.mobileDekAttributes}
      />
    );
  }

  renderButton() {
    const {
      browser: { isMobileViewport } = {},
      colorScheme,
      cta = {},
      ctaButtonHref,
      ctaButtonText,
      ctaCallback,
      mobileColorScheme,
      mobileCtaType,
    } = this.props;
    if (this.props.ctaButtonText) {
      const desktopButtonClass = colorScheme === "white" ? "cta-btn--light" : "cta-btn--dark";
      const mobileButtonClass = mobileColorScheme === "white" ? "cta-btn--light" : "cta-btn--dark";
      const colorSchemeButton = isMobileViewport ? mobileButtonClass : desktopButtonClass;
      const mobileCtaClass = mobileCtaType === "link" ? "cta-btn--mobile-link" : "cta-btn--mobile-btn";
      const hideOnMobile = cta.hideOnMobile ? "mq__tablet-and-desktop" : "";

      const wrapperClass = `cta-btn ${hideOnMobile}`;
      const ctaClass = `${mobileCtaClass} ${colorSchemeButton}`;

      if (this.props.ctaCallback) {
        return (
          <div className={wrapperClass}>
            <button className={ctaClass} onClick={ctaCallback}>
              {ctaButtonText}
            </button>
          </div>
        );
      }

      return (
        <div className={wrapperClass}>
          <MoleculeCtaWithOptionalAuth
            authFormDisplayStyle={cta.authFormStyle?.displayStyle}
            className={ctaClass}
            ctaText={ctaButtonText}
            heapTriggeredBy={HEAP_AUTH_TRIGGER_TYPES.CLICK_CMS_CTA}
            href={ctaButtonHref}
            isFullScreenAuthModal={cta.authFormStyle?.isFullScreen}
            pixelData={this.getPixelData(cta)}
            requiresAuthentication={cta.requiresAuthentication}
          />
        </div>
      );
    }
  }

  renderSubCtaLink() {
    const { subCtaLink } = this.props;

    if (!subCtaLink) {
      return null;
    }

    return (
      <div className="sub-cta-link">
        <a
          href={subCtaLink.href}
          className="cta-btn--link"
          onClick={() => {
            this.logSubCtaAction(this.props.subCtaLink);
          }}>
          {subCtaLink.text}
        </a>
      </div>
    );
  }

  renderLegal() {
    if (!this.props.legalText) {
      return null;
    }

    const { legalCtaLink } = this.props;

    if (!this.props.legalCtaLink) {
      return <div className="hero__legal" dangerouslySetInnerHTML={{ __html: this.props.legalText }}></div>;
    }

    return (
      <div className="hero__legal">
        {this.props.legalText}
        <a
          href={legalCtaLink.href}
          className="hero__legal-link"
          onClick={() => {
            this.logLegalCta();
          }}>
          {legalCtaLink.link}
        </a>
      </div>
    );
  }

  getCtaClassNames() {
    const {
      mobileTextAlign,
      verticalAlign,
      mobileVerticalAlign,
      mobilePosition,
      colorScheme,
      mobileColorScheme,
      textAlign,
      position,
    } = this.props;

    const classes = classNames("cta", colorScheme, `text-align-${textAlign}`, `position-${position}`, {
      [`mobile-text-align-${this.props.mobileTextAlign}`]: mobileTextAlign,
      [`vertical-${this.props.verticalAlign}`]: verticalAlign,
      [`mobile-vertical-${this.props.mobileVerticalAlign}`]: mobileVerticalAlign,
      [`mobile-position-${this.props.mobilePosition}`]: mobilePosition,
      [`mobile-${mobileColorScheme}`]: mobileColorScheme,
    });

    return classes;
  }

  render() {
    const customClass = this.props.customClass ? this.props.customClass : "";
    const { browser: { isMobileViewport } = {} } = this.props;

    const customStyling = {
      backgroundColor: isMobileViewport ? this.props.newPlansBackgroundColor : this.props.backgroundColor,
    };

    const wrapperClassName = classNames("cta__wrapper", {
      "mobile-relative-position": this.props.mobileRelativePosition,
    });

    const className = classNames(`generic-hero-unit generic-hero-unit--${this.props.heightType} ${customClass}`, {
      "no-dekText": !this.props.mobileDekText,
    });
    return (
      <div className={className} ref={this.elementRef} style={customStyling} data-test-id="generic-hero-unit">
        {this.renderHeroBackgroundImage()}
        <div className={wrapperClassName}>
          <div className={this.getCtaClassNames()}>
            {this.renderSurHeroText()}
            {this.renderHed()}
            {this.renderDekText()}
            {this.props.children}
            {this.renderButton()}
            {this.renderSubCtaLink()}
            {this.renderLegal()}
          </div>
        </div>
        {this.renderShoppable()}
      </div>
    );
  }
}

GenericHeroUnit.propTypes = propTypes;

export default GenericHeroUnit;
