import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { withShareChannelBehavior } from "./with-share-channel-behavior";

import { stringify } from "qs";
export class TwitterShare extends Component {
  static handle = "renttherunway";

  static propTypes = {
    buttonClassNames: PropTypes.string,
    copy: PropTypes.string,
    hashtag: PropTypes.string,
    shortenedUrl: PropTypes.string,
    style: PropTypes.oneOf(["icon", "link", "button"]),
    twitterButtonCopy: PropTypes.string,
  };

  static defaultProps = {
    hasSharedStyles: false,
  };

  state = {
    shareUrl: "https://twitter.com/intent/tweet?",
  };

  shareUrl() {
    const params = {
      hashtags: this.props.hashtag,
      text: this.props.copy,
      url: this.props.shortenedUrl,
      via: this.constructor.handle,
    };

    return this.state.shareUrl + stringify(_.pick(params, _.identity));
  }

  openDialog = () => {
    this.logShare("tweet_pending");

    window.open(this.shareUrl(), "shareWindow", "height=600,width=800");
  };

  render() {
    let copy;

    if (this.props.style === "button") {
      copy = this.props.twitterButtonCopy;
    }

    return (
      <button onClick={this.openDialog} className={this.props.buttonClassNames}>
        {copy}
      </button>
    );
  }
}

export default withShareChannelBehavior({ type: "twitter" })(TwitterShare);
