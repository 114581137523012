import PropTypes from "prop-types";
import { browserPropType } from "./browser-prop-type";

export const moleculePlansPropType = {
  backgroundColor: PropTypes.string,
  bannerTextBackgroundColor: PropTypes.string, // The background color of a plan card banner
  bannerTextForegroundColor: PropTypes.string, // The text color of a plan card banner
  browser: browserPropType,
  subHedBannerTextBackgroundColor: PropTypes.string, // The background color of a plan card sub-hed banner
  cmsUrl: PropTypes.string,
  hedText: PropTypes.string, // The main heading of the molecule
  hedAttributes: PropTypes.object, // Attributes for the main heading of the molecule
  mobileHedText: PropTypes.string, // The main heading of the molecule on mobile web
  dekText: PropTypes.string, // The subheading of the molecule
  dekAttributes: PropTypes.object, // Attributes for the subheading of the molecule
  mobileDekText: PropTypes.string, // The subheading of the molecule on mobile web
  bulletIcon: PropTypes.string, // Icon for the unordered list
  retinaBulletIcon: PropTypes.string,
  mobileBulletIcon: PropTypes.string, // Icon for the unordered list for mobile web
  listAreaBackgroundColor: PropTypes.string, // background for the entirety of the plans list area, if not using 2 background colors
  listAreaFooterBackgroundColor: PropTypes.string, // background for upper half of plans list area
  listAreaHeaderBackgroundColor: PropTypes.string, // background for bottom half of plans list area
  listItems: PropTypes.arrayOf(
    // Can be an array of 1, 2, or 3 items
    PropTypes.shape({
      borderColor: PropTypes.string, // color of optional solid border
      image: PropTypes.string, // The main image for the item
      imageAlt: PropTypes.string,
      hed: PropTypes.string.isRequired, // The title of the list item
      body: PropTypes.string, // The left-indented text above the ul
      bulletListItems: PropTypes.arrayOf(
        // The items for the ul
        PropTypes.shape({
          text: PropTypes.string,
        })
      ),
      cta: PropTypes.shape({
        href: PropTypes.string, // The CTA below the bulleted list
        text: PropTypes.string,
        type: PropTypes.oneOf(["button", "link"]),
      }),
      subHed: PropTypes.string, // Sub-hed copy
      mobileSubHed: PropTypes.string, // Mobile web sub-hed copy
      subHedBannerText: PropTypes.string, // Text of an optional banner above the sub-hed
      mobilePlanCardFooterText: PropTypes.string, // Plan card footer text for mobile web
      bannerText: PropTypes.string,
      tierId: PropTypes.number,
    })
  ).isRequired,
  listHedAttributes: PropTypes.object, // Attributes for the various text items below the image
  listItemBackgroundColor: PropTypes.string,
  listBodyAttributes: PropTypes.object,
  listBulletTextAttributes: PropTypes.object,
  mobileDekAttributes: PropTypes.object,
  mobileHedAttributes: PropTypes.object,
  mobileListBulletTextAttributes: PropTypes.object, // For mobile web view, style attributes of bulleted item text
  mobileDefaultSelectedPlanIndex: PropTypes.number, // For mobile web view, index of plan that is selected by default
  mobileSelectedPlanBorderColor: PropTypes.string, // Border color for user-selected plan on mobile web
  mobileOrder: PropTypes.string, // Reverses the cards when on mobile (i.e. if the cards are 1,2,3 - on mobile they'll appear 3,2,1)
  closetStyleCard: PropTypes.bool, // Adds new card style for plans by closet
  closetStyleCardLimitedColor: PropTypes.string, //color for limited closet
  closetStyleCardFullColor: PropTypes.string, //color for limited closet
  closetStyleBannerBackgroundColor: PropTypes.string,
  closetStyleBannerForegroundColor: PropTypes.string,
  closetStyleCardFullTitle: PropTypes.string,
  closetStyleCardFullHed: PropTypes.string,
  closetStyleCardFullSubHed: PropTypes.string,
  closetStyleCardLimitedTitle: PropTypes.string,
  closetStyleCardLimitedHed: PropTypes.string,
  closetStyleCardLimitedSubHed: PropTypes.string,
};
