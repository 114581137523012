import PropTypes from "prop-types";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "./atom-text-attributes-prop-type";

// Documentation: https://renttherunway.jira.com/wiki/spaces/EN/pages/864059579/Home+Hero+Unit
const ctaPropType = PropTypes.shape({
  /**
   * Adds white border. Primarly used for Black CTAs on Black backgrounds
   */
  borderWhite: PropTypes.bool,
  borderWhiteMobile: PropTypes.bool,
  /**
   * Primary - styled as a button element
   * Secondary - styled as clickable text
   */
  type: PropTypes.oneOf(["primary", "secondary", "link"]),
  typeMobile: PropTypes.oneOf(["primary", "secondary", "link"]),
  hed: PropTypes.string.isRequired,
  dek: PropTypes.string,
  /**
   * Text and styling for the panel hed and dek, if using "three_panels" layout
   */
  panelBackgroundColor: PropTypes.string, // hex code
  panelHedText: PropTypes.string,
  panelHedAttributes: atomHedAttributesPropType,
  panelDekText: PropTypes.string,
  panelDekAttributes: atomTextAttributesPropType,
  panelMobileHedText: PropTypes.string,
  panelMobileHedAttributes: atomHedAttributesPropType,
  panelMobileDekText: PropTypes.string,
  panelMobileDekAttributes: atomTextAttributesPropType,
  /**
   * Styling for primary CTAs
   */
  style: PropTypes.oneOf(["black", "transparent", "transparent white", "white", "underline white", "underline black"]),
  styleMobile: PropTypes.oneOf([
    "black",
    "transparent",
    "transparent white",
    "white",
    "underline white",
    "underline black",
  ]),
  /**
   * Clicking will navigate to this URL.
   */
  url: PropTypes.string,
  /**
   * Clicking will fire an on click action (not for use by the CMS)
   */
  onClick: PropTypes.func,
  pixelData: PropTypes.shape({
    object_type: PropTypes.string,
    action_type: PropTypes.string,
  }),
  dataId: PropTypes.string,
});

export const moleculeDualCtaHeroPropType = PropTypes.shape({
  align: PropTypes.oneOf(["left", "right", "center"]),
  backgroundColor: PropTypes.string,
  backgroundColorMobile: PropTypes.string,
  ctasInline: PropTypes.bool,
  ctaOne: ctaPropType,
  ctaTwo: ctaPropType,
  dekAttributes: atomTextAttributesPropType,
  dekText: PropTypes.string,
  hedAttributes: atomHedAttributesPropType,
  hedText: PropTypes.string,
  heroLink: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrcAspectRatio: PropTypes.string, // the calculated aspect ratio of image src, for CLS-prevention
  layout: PropTypes.oneOf(["single_panel", "three_panels"]),
  legalCtaLink: PropTypes.shape({
    href: PropTypes.string,
    link: PropTypes.string,
  }),
  legalText: PropTypes.string,
  mobileAlign: PropTypes.oneOf(["left", "right", "center"]),
  mobileDekAttributes: atomTextAttributesPropType,
  mobileDekText: PropTypes.string,
  mobileHedAttributes: atomHedAttributesPropType,
  mobileHedText: PropTypes.string,
  mobileImageSrc: PropTypes.string,
  mobileImageSrcAspectRatio: PropTypes.string, // the calculated aspect ratio of mobile image src, for CLS-prevention
  mobileVerticalPosition: PropTypes.oneOf(["top", "center", "bottom"]),
  mobileVideoSrcMp4: PropTypes.string,
  mobileVideoSrcWebm: PropTypes.string,
  rotatingWordOptions: PropTypes.arrayOf(PropTypes.string),
  surHeroText: PropTypes.string,
  surHeroTextAttributes: atomHedAttributesPropType,
  videoSrcMp4: PropTypes.string,
  videoSrcWebm: PropTypes.string,
  additionalClassname: PropTypes.string,
});
