import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AtomHed from "components/source/atoms/atom-hed";
import ClosableModal from "components/source/shared/closable-modal";

const modalName = "ai-search-feedback-modal";

export class AiSearchFeedbackModal extends React.Component {
  static propTypes = {
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isMobileViewport: PropTypes.bool,
    onChange: PropTypes.func,
    onRequestClose: PropTypes.func,
  };

  state = {
    selectedFeedback: null,
    charCount: 0,
    text: null,
    submitDisabled: true,
    ids: {
      notatall: "ai-search-feedback-option",
      somewhat: "ai-search-feedback-option",
      matchedexpectations: "ai-search-feedback-option",
      exceededexpectations: "ai-search-feedback-option",
      submit: "submit-feedback-button",
    },
  };

  static modalName = modalName;

  renderFeedbackModal() {
    return (
      <div className="feedback-button-group">
        <button className={this.state.ids.notatall} id="notatall" onClick={e => this.clickedOption(e)}>
          {"Not at all"}
        </button>
        <button className={this.state.ids.somewhat} id="somewhat" onClick={e => this.clickedOption(e)}>
          {"Somewhat"}
        </button>
        <button
          className={this.state.ids.matchedexpectations}
          id="matchedexpectations"
          onClick={e => this.clickedOption(e)}>
          {"Matched expectations"}
        </button>
        <button
          className={this.state.ids.exceededexpectations}
          id="exceededexpectations"
          onClick={e => this.clickedOption(e)}>
          {"Exceeded expectations"}
        </button>
        <textarea
          className="written-feedback"
          placeholder="Share additional feedback to help us improve search (optional)"
          maxLength="200"
          onChange={e => this.textChange(e)}
          aria-label="textarea">
          {this.state.text}
        </textarea>
        <div className="char-count">{this.state.charCount}/200</div>
        <button
          className={this.state.ids.submit}
          id="submit"
          disabled={this.state.submitDisabled}
          onClick={() => this.submitData()}>
          Submit
        </button>
      </div>
    );
  }

  submitData() {
    this.setState({
      selectedFeedback: null,
      charCount: 0,
      text: null,
      submitDisabled: true,
      ids: {
        notatall: "ai-search-feedback-option",
        somewhat: "ai-search-feedback-option",
        matchedexpectations: "ai-search-feedback-option",
        exceededexpectations: "ai-search-feedback-option",
        submit: "submit-feedback-button",
      },
    });
    this.props.onChange(this.state.selectedFeedback, this.state.text);
  }

  textChange(e) {
    const newIds = this.state.ids;
    let submitDisabled = true;
    this.setState({ charCount: e.currentTarget.value.length, text: e.currentTarget.value });
    if (e.target.value.length > 0 || this.state.selectedFeedback) {
      newIds.submit = "active-submit-feedback-button";
      submitDisabled = false;
    } else if (e.target.value.length === 0 && !this.state.selectedFeedback) {
      newIds.submit = "submit-feedback-button";
    }
    this.setState({ submitDisabled: submitDisabled, ids: newIds });
  }

  clickedOption(e) {
    const newIds = this.state.ids;
    if (this.state.selectedFeedback) {
      newIds[this.state.selectedFeedback?.toLowerCase().replaceAll(" ", "")] = "ai-search-feedback-option";
    }
    this.setState({ selectedFeedback: e.target.innerText, submitDisabled: false });
    newIds[e.target.id] = "selected-option";
    newIds.submit = "active-submit-feedback-button";
    this.setState({ ids: newIds });
  }

  render() {
    const { isMobileViewport, displayedModal, onRequestClose } = this.props;
    const mobileStyles = {
      content: {
        bottom: 0,
      },
    };
    const styles = isMobileViewport ? mobileStyles : {};
    const hedText = "Did the search results match your expectations?";

    return (
      <ClosableModal
        additionalStyles={styles}
        isOpen={displayedModal === modalName}
        onRequestClose={onRequestClose}
        optionalClass="grid-header-modal ai-search-feedback-modal modal">
        <div className="ai-search-feedback-modal-contents">
          <AtomHed className="ai-search-feedback-modal-header" text={hedText} />
          {this.renderFeedbackModal()}
        </div>
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const { browser = {} } = state;
  const { isMobileViewport = false } = browser;

  return {
    displayedModal: state.displayedModal,
    isMobileViewport,
  };
};

export default connect(mapStateToProps)(AiSearchFeedbackModal);
