import React from "react";
import $ from "jquery";
import AtomStatsCircle from "components/source/atoms/atom-stats-circle";

class AtomStatsCircleWithAnimation extends React.Component {
  $circle = null;

  componentDidMount() {
    window.addEventListener("scroll", () => {
      this.setCircleAnimation();
    });
  }

  componentDidUpdate() {
    this.setCircleAnimation();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {
      this.setCircleAnimation();
    });
  }

  setCircleAnimation() {
    const circle = this.$circle.$circle;
    if (!circle) {
      return;
    }

    const windowTop = $(window).scrollTop();
    const windowBottom = windowTop + $(window).height();
    const heightOfCircle = $(circle).position().top;

    if (!windowTop || !heightOfCircle) {
      return;
    }

    if (!$(circle).addClass) {
      return;
    }

    // If the height at which the circle finds itself is less than (i.e. higher than)
    // the bottom of the window *and* it's greater than (i.e. lower than) the top of
    // the window, the circle should be in the browser viewport. Therefore, add the animate class!
    if (heightOfCircle < windowBottom && windowTop < heightOfCircle) {
      $(circle).addClass("animate");
    }
  }

  render() {
    const { children } = this.props;

    return (
      <AtomStatsCircle ref={element => (this.$circle = element)} withAnimation {...this.props}>
        {children}
      </AtomStatsCircle>
    );
  }
}

export default AtomStatsCircleWithAnimation;
