import React from "react";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import ClosableModal from "components/source/shared/closable-modal";
import ImgLightbulbBlueBG from "images/img_lightbulb_blueBG.svg";

const modalName = "ai-search-feedback-confirmation-modal";

export class AiSearchFeedbackConfirmationModal extends React.Component {
  static propTypes = {
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isMobileViewport: PropTypes.bool,
    onRequestClose: PropTypes.func,
  };

  static modalName = modalName;

  render() {
    const { isMobileViewport, displayedModal, onRequestClose } = this.props;
    const mobileStyles = {
      content: {
        bottom: 0,
      },
    };
    const styles = isMobileViewport ? mobileStyles : {};
    const hedText = "Thanks for helping improve our search.";

    return (
      <ClosableModal
        additionalStyles={styles}
        isOpen={displayedModal === modalName}
        onRequestClose={onRequestClose}
        optionalClass="grid-header-modal ai-search-feedback-conf-modal modal">
        <div className="confirmation-modal-contents">
          <ImgLightbulbBlueBG className="lightbulb" />
          <AtomHed className="ai-search-feedback-confirmation-modal-header" text={hedText} />
        </div>
      </ClosableModal>
    );
  }
}

export default AiSearchFeedbackConfirmationModal;
