import React from "react";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import PropTypesHelper from "helpers/PropTypesHelper";
import AtomVisualMedia from "components/source/atoms/atom-visual-media";

const baseClass = "molecule-checkerboard";

class MoleculeCheckerboard extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(["right", "left"]).isRequired,
    altText: PropTypes.string,
    backgroundColor: PropTypesHelper.hexColor,
    children: childrenPropType,
    image: PropTypes.string.isRequired,
    videoSettings: PropTypes.shape({
      autoPlay: PropTypes.bool,
      controls: PropTypes.bool,
    }),
    videoSrcMp4: PropTypes.string,
  };

  getStyles() {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  }

  render() {
    return (
      <div className={`${baseClass} ${baseClass}--${this.props.align}`} style={this.getStyles()}>
        <AtomVisualMedia
          image={this.props.image}
          altText={this.props.altText}
          videoSrcMp4={this.props.videoSrcMp4}
          videoSettings={this.props.videoSettings}
        />
        <div className={`${baseClass}__children`}>{this.props.children}</div>
      </div>
    );
  }
}

export default MoleculeCheckerboard;
