import React from "react";
import PropTypes from "prop-types";
import { browserPropType } from "components/propTypes";
/*
  The goal of this component is to display an embedded youtube video. atom-visual-media can be used for mp4 videos and displays an image by default.
  You can learn more about the video props and component in this confluence: https://renttherunway.jira.com/wiki/spaces/Engage/pages/2707390648/Embedding+YouTube+Videos+in+Storefront
*/

const baseClass = "atom-visual-media";

export default class AtomYoutubeEmbeddedContent extends React.Component {
  static propTypes = {
    browser: browserPropType,
    videoSrcYouTube: PropTypes.string,
    videoSettings: PropTypes.shape({
      controls: PropTypes.bool,
      autoPlay: PropTypes.bool,
      height: PropTypes.string,
      modestbranding: PropTypes.bool,
      mobileHeight: PropTypes.string,
      desktopHeight: PropTypes.string,
    }),
    videoTitle: PropTypes.string.isRequired,
  };

  static defaultProps = {
    videoSettings: {
      controls: true,
      autoPlay: false,
      modestbranding: true,
      mobileHeight: 300,
      desktopHeight: 500,
    },
  };

  videoMarkup() {
    const {
      videoSettings: { controls, autoPlay, modestbranding, mobileHeight, desktopHeight },
      browser: { isMobileViewport },
      videoSrcYouTube,
      videoTitle,
    } = this.props;

    const videoControls = controls ? 1 : 0;
    const videoAutoPlay = autoPlay ? 1 : 0;
    const videoModestBranding = modestbranding ? 1 : 0;
    const videoHeight = isMobileViewport ? mobileHeight : desktopHeight;

    return (
      <iframe
        className={baseClass}
        type="text/html"
        title={videoTitle}
        height={videoHeight}
        width="100%"
        src={`${videoSrcYouTube}?autoplay=${videoAutoPlay}&controls=${videoControls}&modestbranding=${videoModestBranding}`}
        allowFullScreen
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    );
  }

  render() {
    return this.videoMarkup();
  }
}
