import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { suppressSkinnyBanner } from "actions/nav-actions";

/* NW [EXPLANATION] 10/11/21: a component used to suppress appearance of Skinny Banner promos on select paid marketing pages
 * (where the generic skinny banner promo code differs from a more specific promo code shown on the page, confusing users)
 * Documentation:  https://renttherunway.jira.com/wiki/spaces/CMS/pages/3342958629/Skinny+Banner+Suppression+on+Paid+Marketing+CMS+Pages */
export class SkinnyBannerSuppression extends React.Component {
  static propTypes = {
    suppressSkinnyBanner: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.suppressSkinnyBanner();
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    suppressSkinnyBanner: () => dispatch(suppressSkinnyBanner(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkinnyBannerSuppression);
