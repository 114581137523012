import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import { AddToBag } from "routes";
import { withQueryParams } from "../hoc/with-query-params";
import { authFormConstants } from "rtr-constants/auth";
import MoleculeCtaWithOptionalAuth from "./molecule-cta-with-optional-auth";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";
import RtrImage from "../shared/rtr-image";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

export class MoleculeAnnotatedCallToAction extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string, // Hex value
    callToAction: PropTypes.shape({
      /**
       * Version of the auth form to display if requiresAuthentication is true.
       */
      authFormStyle: PropTypes.shape({
        displayStyle: PropTypes.oneOf(Object.values(authFormConstants.DisplayStyle)),
        isFullScreen: PropTypes.bool,
      }),
      desktopType: PropTypes.string,
      href: PropTypes.string,
      mobileFullWidth: PropTypes.bool,
      mobileType: PropTypes.string,
      outline: PropTypes.string,
      /**
       * Trigger the login/auth form prior to navigating to the href if the user isn't authorized.
       */
      requiresAuthentication: PropTypes.bool,
      text: PropTypes.string,
    }),
    cmsUrl: PropTypes.string,
    colorScheme: PropTypes.string, // Black or white
    cta: PropTypes.shape({
      objectType: PropTypes.string,
      action: PropTypes.string,
      actionType: PropTypes.string,
    }),
    customClass: PropTypes.string, // Custom class in case you need it
    dekAttributes: atomTextAttributesPropType,
    dekText: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    hedText: PropTypes.string,
    imageAlt: PropTypes.string,
    imageURL: PropTypes.string,
    initialUrl: PropTypes.string,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    legalText: PropTypes.string,
    mobileDekAttributes: PropTypes.object,
    mobileDekText: PropTypes.string,
    mobileHedAttributes: atomTextAttributesPropType,
    mobileHedText: PropTypes.string,
    mobileImageURL: PropTypes.string,
    padding: PropTypes.string,
    queryParams: PropTypes.shape({
      source: PropTypes.string,
    }),
    rawUrl: PropTypes.instanceOf(URL),
    scrollAction: PropTypes.string,
    shouldLogScroll: PropTypes.bool,
    textAlign: PropTypes.string, // Left, right or center
    userData: PropTypes.shape({
      userProfile: PropTypes.object,
    }),
    width: PropTypes.string, // If narrow, the max-width of the component is 1200px; i.e. not full-bleed
  };

  static headerType = "h2";
  static className = "molecule-annotated-call-to-action";

  elementRef = React.createRef(null);

  componentDidMount() {
    this.scrollPixelLogger = this.createPixelLogger();
  }

  componentWillUnmount() {
    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  createPixelLogger = () => {
    const { shouldLogScroll, scrollAction } = this.props;

    if (!shouldLogScroll) {
      return null;
    }

    return createScrollIntoViewPixelLogger(this.elementRef, "scroll_read", {
      objectType: "scroll_read",
      action: scrollAction,
    });
  };

  getStyles = () => {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  };

  baseClassWith = element => {
    return [this.constructor.className, element].join("");
  };

  getCtaPixelData = () => {
    const { callToAction, cmsUrl, cta } = this.props;

    const text = callToAction?.text ?? "";
    const url = new URL(this.props.initialUrl).pathname;

    return {
      object_type: cta?.objectType ?? "cta_click",
      action: cta?.action ?? "molecule_annotated_call_to_action",
      action_type: cta?.actionType ?? "click_through_annotated_cta",
      context: "click",
      url,
      component_type: cmsUrl,
      copy: text,
    };
  };

  getWrapperClass = () => {
    const wrapperClass = [this.constructor.className];

    if (this.props.width) {
      wrapperClass.push(this.baseClassWith("--narrow"));
    }

    if (this.props.customClass) {
      wrapperClass.push(this.props.customClass);
    }

    return wrapperClass.join(" ");
  };

  getInnerClass = () => {
    const innerClass = [this.baseClassWith("__inner ")];
    if (this.props.colorScheme) {
      innerClass.push(this.props.colorScheme);
    }
    if (this.props.padding) {
      innerClass.push("padding");
    }
    if (this.props.textAlign) {
      innerClass.push(`text-align-${this.props.textAlign}`);
    }
    return innerClass.join(" ");
  };

  renderHed = () => {
    if (!this.props.hedText) {
      return false;
    }

    return (
      <AtomHed
        type={this.constructor.headerType}
        text={this.props.hedText}
        hedAttributes={this.props.hedAttributes}
        mobileText={this.props.mobileHedText}
        mobileHedAttributes={this.props.mobileHedAttributes}
      />
    );
  };

  renderDek = () => {
    if (!this.props.dekText) {
      return false;
    }

    return (
      <AtomDek
        text={this.props.dekText}
        dekAttributes={this.props.dekAttributes}
        mobileText={this.props.mobileDekText}
        mobileDekAttributes={this.props.mobileDekAttributes}
      />
    );
  };

  renderCta = () => {
    if (!this.props.callToAction || !Object.keys(this.props.callToAction).length) {
      return false;
    }

    let colorSchemeButton = this.props.colorScheme === "white" ? "cta-btn--light" : "btn";
    const desktopCtaType = this.props.callToAction.desktopType === "link" ? "cta-btn--link" : "";
    const mobileCtaType =
      this.props.callToAction.mobileType === "link" ? "cta-btn--mobile-link" : "cta-btn--mobile-btn";
    const mobileFullWidthClass = this.props.callToAction?.mobileFullWidth ? "mobile-full-width" : "";

    // If the `outline` property is present in the CTA, only show the outline-style button, rather than the filled button
    if (this.props.callToAction.outline) {
      colorSchemeButton = this.props.colorScheme === "white" ? "btn-editorial" : "btn-secondary";
    }

    const className = `${colorSchemeButton} ${desktopCtaType} ${mobileCtaType} ${mobileFullWidthClass}`;

    const ctaButton = (
      <MoleculeCtaWithOptionalAuth
        authFormDisplayStyle={this.props.callToAction.authFormStyle?.displayStyle}
        className={className}
        ctaText={this.props.callToAction.text}
        heapTriggeredBy={HEAP_AUTH_TRIGGER_TYPES.CLICK_CMS_CTA}
        href={this.getCtaLink(this.props.callToAction.href)}
        isFullScreenAuthModal={this.props.callToAction.authFormStyle?.isFullScreen}
        pixelData={this.getCtaPixelData()}
        requiresAuthentication={this.props.callToAction.requiresAuthentication}
      />
    );

    return <div className={this.baseClassWith("__cta")}>{ctaButton}</div>;
  };

  getCtaLink = href => {
    // this method is used to update the source url param to include the index bc a cms driven page may have multiple CTAs
    // that reference the same CTA content molecule
    const addToBagRoutes = Object.values(AddToBag);
    const { index, rawUrl, queryParams } = this.props;

    if (!rawUrl) {
      return href;
    }

    const { pathname } = new URL(href, rawUrl.href);

    if (!pathname) {
      return href;
    }

    if (!addToBagRoutes.includes(pathname) || !index) {
      return href;
    }

    if (!queryParams.source) {
      return href;
    } // if there isn't a source value, return original href

    const newSourceParam = `${queryParams.source}_${index}`; // append the index
    return href.replace(`source=${queryParams.source}`, `source=${newSourceParam}`); // replace source url param
  };

  renderLegal = () => {
    if (!this.props.legalText) {
      return false;
    }

    return <div className={this.baseClassWith("__legal")} dangerouslySetInnerHTML={{ __html: this.props.legalText }} />;
  };

  renderImage = () => {
    if (!this.props.imageURL || !this.props.mobileImageURL) {
      return false;
    }

    return (
      <div className={this.baseClassWith("__image")}>
        <RtrImage
          src={this.props.imageURL}
          alt={this.props.imageAlt}
          className={this.baseClassWith("__image--desktop")}
        />
        <RtrImage
          src={this.props.mobileImageURL}
          alt={this.props.imageAlt}
          className={this.baseClassWith("__image--mobile")}
        />
      </div>
    );
  };

  render() {
    return (
      <div className={this.getWrapperClass()} style={this.getStyles()} ref={this.elementRef}>
        <div className={this.getInnerClass()}>
          <div className={this.baseClassWith("__content")}>
            {this.renderHed()}
            {this.renderDek()}
            {this.renderCta()}
            {this.renderLegal()}
          </div>
          {this.renderImage()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ initialUrl }) => ({ initialUrl });

export default compose(withQueryParams("source"), connect(mapStateToProps))(MoleculeAnnotatedCallToAction);
