import { connect } from "react-redux";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomText from "components/source/atoms/atom-text";
import GenericHeroUnitPicture from "components/source/shared/generic-hero-unit-picture";
import { moleculeDualCtaHeroPropType } from "components/propTypes";
import ActionLogger from "action-logger";
import classnames from "classnames";
import MoleculeRotatingHedText from "components/source/molecules/molecule-rotating-hed-text";
import MoleculeVideo from "components/source/molecules/molecule-video-bucket/molecule-video";
import SmartComponentActions from "actions/smart-component-actions";
import RentMyStyleQuiz from "components/source/rent_my_style_quiz/rent-my-style-quiz-cta";

// Documentation: https://renttherunway.jira.com/wiki/spaces/EN/pages/864059579/Home+Hero+Unit
export function MoleculeDualCtaHero(props) {
  const LAYOUT_OPTIONS = {
    SINGLE_PANEL: "single_panel",
    THREE_PANELS: "three_panels",
  };

  const getContent = () => {
    // NW [EXPLANATION] 12/7/23: for backwards compatibility, both `content` and `attributes` fields must be checked. `attributes` is standard for a CMS content module but `content` was used in this component's original implementation.
    const attributes = props.content || props.attributes || {};
    if (!Object.keys(attributes || {}).length) {
      // NW [EXPLANATION] 1/8/23: when this molecule is used as a content-module within a container, the attributes are spread into props by content-module.jsx
      return props;
    } else {
      return attributes;
    }
  };

  const getLayout = () => {
    return getContent().layout || LAYOUT_OPTIONS.SINGLE_PANEL;
  };

  const renderBackgroundMediaWrapper = () => {
    const content = getContent();
    const { imageAlt, imageSrc, mobileImageSrc } = content;

    const mp4Key = props.isMobileViewport ? "mobileVideoSrcMp4" : "videoSrcMp4";
    const webmKey = props.isMobileViewport ? "mobileVideoSrcWebm" : "videoSrcWebm";
    const videoSrcMp4 = content[mp4Key];
    const videoSrcWebm = content[webmKey];

    if (videoSrcMp4 || videoSrcWebm) {
      return (
        <MoleculeVideo
          mobileVideoSrcMp4={content.mobileVideoSrcMp4}
          mobileVideoSrcMp4Poster={content.mobileVideoSrcMp4Poster}
          mobileVideoSrcMp4PosterAspectRatio={content.mobileVideoSrcMp4PosterAspectRatio}
          mobileVideoSrcWebm={content.mobileVideoSrcWebm}
          mobileVideoSrcWebmPoster={content.mobileVideoSrcWebmPoster}
          mobileVideoSrcWebmPosterAspectRatio={content.mobileVideoSrcWebmPosterAspectRatio}
          videoSrcMp4={content.videoSrcMp4}
          videoSrcMp4Poster={content.videoSrcMp4Poster}
          videoSrcMp4PosterAspectRatio={content.videoSrcMp4PosterAspectRatio}
          videoSrcWebm={content.videoSrcWebm}
          videoSrcWebmPoster={content.videoSrcWebmPoster}
          videoSrcWebmPosterAspectRatio={content.videoSrcWebmPosterAspectRatio}
        />
      );
    } else if (imageSrc || mobileImageSrc) {
      // NW [EXPLANATION] 2/27/24: if this hero unit is one of the first 3 modules on a page, its image should be given high priority for loading order
      const priority = typeof props.index === "number" && props.index < 3 ? "high" : "auto";
      const imageMobileAspectRatio = getContent().mobileImageSrcAspectRatio;
      const imageDesktopAspectRatio = getContent().imageSrcAspectRatio;

      return (
        <GenericHeroUnitPicture
          fetchpriority={priority}
          imageAdditionalClass="home-hero-media"
          imageDesktopAspectRatio={imageDesktopAspectRatio}
          imageMobileAspectRatio={imageMobileAspectRatio}
          mobileImageURL={mobileImageSrc}
          imageURL={imageSrc}
          imageAlt={imageAlt}
        />
      );
    }

    return null;
  };

  const getCtaClassNames = cta => {
    const { ctaOne, ctasInline, ctaTwo } = getContent();
    const { dek, type, typeMobile, style, styleMobile, borderWhite, borderWhiteMobile } = cta;
    const ctaStyle = props.isMobileViewport ? styleMobile : style;
    const ctaType = props.isMobileViewport ? typeMobile : type;

    return classnames("cta", {
      "btn": ctaType === "primary",
      "btn-primary": ctaType === "primary" && ctaStyle !== "transparent",
      "btn-secondary": ctaStyle === "transparent",
      "btn-secondary-light": ctaStyle === "transparent white",
      "underline-white": ctaStyle === "underline white",
      "underline-black": ctaStyle === "underline black",
      "btn-large": dek,
      "text-link": ctaType === "secondary",
      "btn-hero-light": ctaStyle === "white",
      "border-white": props.isMobileViewport ? borderWhiteMobile : borderWhite,
      "inline": ctasInline && !props.isMobileViewport,
      "single": ctaOne && !ctaTwo,
    });
  };

  const renderCta = (cta = {}) => {
    const { url, onClick, triggersRentMyStyleQuiz } = cta;

    if (url) {
      return renderLink(cta);
    } else if (onClick || triggersRentMyStyleQuiz) {
      return renderButton(cta);
    }

    return null;
  };

  const renderLink = cta => {
    const { dataId, dek, hed, url } = cta;

    return (
      <div className={getCtaClassNames(cta)}>
        <a href={url} onClick={getOnClick(cta)} data-test-id={dataId} heap-data-id={dataId}>
          {renderCtaText(hed, dek)}
        </a>
      </div>
    );
  };

  const renderButton = cta => {
    const { dataId, dek, hed = {} } = cta;

    return (
      <button className={getCtaClassNames(cta)} onClick={getOnClick(cta)} data-test-id={dataId} heap-data-id={dataId}>
        {renderCtaText(hed, dek)}
      </button>
    );
  };

  const renderCtaText = (hed, dek) => {
    if (!dek) {
      return hed;
    } else {
      return (
        <div className="copy-container">
          <p>{hed}</p>
          <p>{dek}</p>
        </div>
      );
    }
  };

  const renderHed = () => {
    const { hedAttributes, hedText, mobileHedAttributes, mobileHedText, rotatingWordOptions } = getContent();
    return (
      <MoleculeRotatingHedText
        hedAttributes={hedAttributes}
        hedText={hedText}
        isMobileViewport={props.isMobileViewport}
        mobileHedAttributes={mobileHedAttributes}
        mobileHedText={mobileHedText}
        rotatingWordOptions={rotatingWordOptions}
      />
    );
  };

  const renderLegal = () => {
    const { legalCtaLink, legalText } = getContent();

    if (!legalText) {
      return null;
    }

    if (!legalCtaLink) {
      return <p className="hero__legal">{legalText}</p>;
    }

    return (
      <div className="hero__legal">
        <p>{legalText}</p>
        <a href={legalCtaLink.href} className="hero__legal-link">
          {legalCtaLink.link}
        </a>
      </div>
    );
  };

  const renderMainHero = () => {
    const {
      ctaOne,
      ctaTwo,
      mobileVerticalPosition,
      dekText,
      mobileDekText,
      dekAttributes,
      mobileDekAttributes,
      align,
      mobileAlign,
      surHeroText,
      surHeroTextAttributes,
      ctasInline,
    } = getContent();

    const backgroundContainerClasses = classnames("molecule-dual-cta-hero", {
      [props.additionalClassname]: props.additionalClassname,
    });
    const copyAndCtaWrapperClasses = classnames("copy", {
      [`mobile-vertical-position-${mobileVerticalPosition}`]: mobileVerticalPosition,
      "mobile-align-left": mobileAlign === "left",
      "mobile-align-right": mobileAlign === "right",
      "additional-padding": !dekText && ctaTwo?.type !== "secondary",
      "align-left": align === "left",
      "align-right": align === "right",
    });
    const ctasClasses = classnames("cta-container", {
      inline: ctasInline && !props.isMobileViewport,
    });

    const { backgroundColor, backgroundColorMobile, heroLink } = getContent();
    const background = props.isMobileViewport ? backgroundColorMobile : backgroundColor;
    const style = { backgroundColor: background };

    const innerContent = () => (
      <>
        {renderBackgroundMediaWrapper()}
        <div className={copyAndCtaWrapperClasses}>
          <AtomText text={surHeroText} textAttributes={surHeroTextAttributes} />
          {renderHed()}
          <AtomText
            text={dekText}
            mobileText={mobileDekText}
            textAttributes={dekAttributes}
            mobileTextAttributes={mobileDekAttributes}
            customClass="dek"
          />
          {getLayout() === LAYOUT_OPTIONS.SINGLE_PANEL && (
            <div className={ctasClasses}>
              {renderCta(ctaOne)}
              {renderCta(ctaTwo)}
            </div>
          )}
          {renderLegal()}
        </div>
      </>
    );

    return heroLink ? (
      <a className={backgroundContainerClasses} href={heroLink} data-test-id={props.dataTestId} style={style}>
        {innerContent()}
      </a>
    ) : (
      <div className={backgroundContainerClasses} data-test-id={props.dataTestId} style={style}>
        {innerContent()}
      </div>
    );
  };

  const getOnClick = (cta = {}) => {
    const { url, onClick, triggersRentMyStyleQuiz } = cta;
    const { launchRentMyStyleQuiz } = props;

    if (url) {
      const { pixelData } = cta;

      return () => ActionLogger.inferAction(pixelData);
    } else if (onClick || triggersRentMyStyleQuiz) {
      const { pixelData = {} } = cta;
      return e => {
        //Prevent click events on the button from bubbling to the wrapper div (which has the same handler)
        e.stopPropagation();

        if (Object.keys(pixelData).length) {
          ActionLogger.logAction(pixelData);
        }

        triggersRentMyStyleQuiz ? launchRentMyStyleQuiz() : onClick();
      };
    }
  };

  const renderRentMyStyleQuizModal = () => {
    const { ctaOne = {}, ctaTwo = {} } = getContent();

    if (ctaOne.triggersRentMyStyleQuiz || ctaTwo.triggersRentMyStyleQuiz) {
      return <RentMyStyleQuiz />;
    }

    return null;
  };

  const renderCtaPanel = cta => {
    const { url } = cta;

    const panelChildren = (
      <>
        <AtomHed
          hedAttributes={cta.panelHedAttributes}
          mobileHedAttributes={cta.panelMobileHedAttributes}
          mobileText={cta.panelMobileHedText}
          text={cta.panelHedText}
        />
        <AtomText
          customClass="cta-panel-dek"
          text={cta.panelDekText}
          textAttributes={cta.panelDekAttributes}
          mobileText={cta.panelMobileDekText}
          mobileTextAttributes={cta.panelMobileDekAttributes}
        />
        <div className={getCtaClassNames(cta)}>{renderCtaText(cta.hed, cta.dek)}</div>
      </>
    );
    const panelClassName = "molecule-dual-cta-hero__cta-panel-container__cta-panel";
    const panelStyle = { backgroundColor: cta.panelBackgroundColor };

    if (url) {
      return (
        <a
          className={panelClassName}
          href={url}
          style={panelStyle}
          onClick={getOnClick(cta)}
          data-test-id={cta.dataId}
          heap-data-id={cta.dataId}>
          {panelChildren}
        </a>
      );
    } else {
      return (
        <button className={panelClassName} style={panelStyle} data-test-id={cta.dataId} onClick={getOnClick(cta)}>
          {panelChildren}
        </button>
      );
    }
  };

  const renderMainContent = () => {
    if (getLayout() === LAYOUT_OPTIONS.THREE_PANELS) {
      const { ctaOne, ctaTwo } = getContent();

      return (
        <div
          className="molecule-dual-cta-hero molecule-dual-cta-hero__three-panel-container"
          data-test-id="molecule-dual-cta-hero-three-panel-container">
          {renderMainHero()}
          <div className="molecule-dual-cta-hero__cta-panel-container">
            {renderCtaPanel(ctaOne)}
            {renderCtaPanel(ctaTwo)}
          </div>
        </div>
      );
    } else {
      return renderMainHero();
    }
  };

  return (
    <>
      {renderMainContent()}
      {renderRentMyStyleQuizModal()}
    </>
  );
}

MoleculeDualCtaHero.propTypes = {
  additionalClassname: PropTypes.string,
  attributes: moleculeDualCtaHeroPropType,
  content: moleculeDualCtaHeroPropType, // legacy field that was used for `attributes` - should be removed when we can assert that CMS is no longer referencing content field. `attributes` is standard for a CMS content module
  dataTestId: PropTypes.string,
  index: PropTypes.number, // index of this module in a CMS LP
  isMobileViewport: PropTypes.bool,
  launchRentMyStyleQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isMobileViewport: state.browser?.isMobileViewport,
  };
};

const mapDispatchToProps = {
  launchRentMyStyleQuiz: () => SmartComponentActions.getRentMyStyleQuiz(true),
};

export default connect(mapStateToProps, mapDispatchToProps)(MoleculeDualCtaHero);
