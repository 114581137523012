import PropTypes from "prop-types";
import Constants from "rtr-constants";
import RtrImage from "./rtr-image";
import classNames from "classnames";

const GenericHeroUnitPicture = props => {
  // We're using a max-width of 768px as our CSS treats mobile and tablet as the same when
  // switching between mobile and desktop copy etc.

  const { imageAdditionalClass, imageDesktopAspectRatio, imageMobileAspectRatio } = props;

  const imageClassName = classNames("hero", {
    [imageAdditionalClass]: imageAdditionalClass,
  });

  return (
    <picture>
      <source srcSet={props.mobileImageURL} media={Constants.mediaQueries.MOBILE_AND_TABLET_BREAKPOINT} />
      <RtrImage
        src={props.imageURL}
        alt={props.imageAlt}
        className={imageClassName}
        style={{
          "--mobile-aspect-ratio": imageMobileAspectRatio || "auto",
          "--desktop-aspect-ratio": imageDesktopAspectRatio || "auto",
        }}
        fetchpriority={props.fetchpriority}
      />
    </picture>
  );
};

GenericHeroUnitPicture.propTypes = {
  mobileImageURL: PropTypes.string,
  fetchpriority: PropTypes.oneOf(["high", "low", "auto"]),
  imageDesktopAspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageMobileAspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageAdditionalClass: PropTypes.string,
  imageURL: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default GenericHeroUnitPicture;

export const { propTypes } = GenericHeroUnitPicture;
