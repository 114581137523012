import React from "react";
import _ from "underscore";
import AtomHed from "components/source/atoms/atom-hed";
import MoleculeMadlibsForm from "components/source/molecules/molecule-madlibs-form";
import PropTypes from "prop-types";
import { emitDeprecationEvent } from "helpers/deprecation-alert";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  backgroundColor: PropTypes.string, // Hex value
  hedText: PropTypes.string,
  imageUrl: PropTypes.string,
  dateOptions: PropTypes.shape({
    dateStart: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
    blackoutDays: PropTypes.array,
  }).isRequired,
};

const baseClass = "molecule-madlibs";

class MoleculeMadlibs extends React.Component {
  componentDidMount() {
    emitDeprecationEvent("MoleculeMadlibs");
  }

  getStyles() {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  }

  baseClassWith(element) {
    return [baseClass, element].join("");
  }

  renderHed() {
    if (_.isEmpty(this.props.hedText)) {
      return;
    }

    return (
      <AtomHed
        text={this.props.hedText}
        hedAttributes={this.props.hedAttributes}
        mobileText={this.props.mobileHedText}
        mobileHedAttributes={this.props.mobileHedAttributes}
      />
    );
  }

  renderImage() {
    if (_.isEmpty(this.props.imageUrl)) {
      return;
    }

    return (
      <div className={this.baseClassWith("__image")}>
        <RtrImage src={this.props.imageUrl} alt={this.props.imageAlt} />
      </div>
    );
  }

  render() {
    return (
      <div className={baseClass} style={this.getStyles()}>
        {this.renderHed()}
        <div className={this.baseClassWith("__inner")}>
          {this.renderImage()}
          <MoleculeMadlibsForm dateOptions={this.props.dateOptions} cmsUrl={this.props.cmsUrl} />
        </div>
      </div>
    );
  }
}

MoleculeMadlibs.propTypes = propTypes;

export default MoleculeMadlibs;
