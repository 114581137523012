import React from "react";
import PropTypes from "prop-types";
import { browserPropType } from "components/propTypes";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import MoleculeCheckerboardYoutube from "components/source/molecules/molecule-checkerboard-youtube";

const propTypes = {
  image: PropTypes.string.isRequired,
  align: PropTypes.oneOf(["right", "left"]).isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  videoSrcYouTube: PropTypes.string,
  videoSettings: PropTypes.object,
  videoTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  browser: browserPropType,
  mobileHeader: PropTypes.string,
  mobileDescription: PropTypes.string,
};

class MoleculeCheckerboardBasicYoutube extends React.Component {
  render() {
    return (
      <MoleculeCheckerboardYoutube
        image={this.props.image}
        videoSrcYouTube={this.props.videoSrcYouTube}
        videoSettings={this.props.videoSettings}
        videoTitle={this.props.videoTitle}
        align={this.props.align}
        backgroundColor={this.props.backgroundColor}
        imageAlt={this.props.imageAlt}
        browser={this.props.browser}>
        <AtomHed text={this.props.header} mobileText={this.props.mobileHeader} />
        <AtomDek text={this.props.description} mobileText={this.props.mobileDescription} />
      </MoleculeCheckerboardYoutube>
    );
  }
}

MoleculeCheckerboardBasicYoutube.propTypes = propTypes;
export default MoleculeCheckerboardBasicYoutube;
