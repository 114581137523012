import { useSelector } from "react-redux";
import AtomFullWidthLinkButton from "components/source/atoms/atom-full-width-link-button";
import classNames from "classnames";
import { midGridContentPropType } from "components/propTypes";
import { getRentalIntent } from "helpers/rental-intent-helper";

import Hanger from "images/sprites/reb/svg/hanger_recycle.svg";
import Lightning from "images/sprites/reb/svg/lightning.svg";
import Clipboard from "images/sprites/reb/svg/clipboard_x.svg";
import Calendar from "images/sprites/reb/svg/rent-my-style-unlimited.svg";
import MeasuringTape from "images/sprites/reb/svg/measuring_tape.svg";
import Leaf from "images/sprites/reb/svg/leaf.svg";
import { isActiveMembership } from "helpers/membership-helpers";

const MoleculeMidGridEducationalMessage = props => {
  const currentIntent = useSelector(s => getRentalIntent(s.userData));
  const isRentableGrid = useSelector(s => s.gridView?.isRentable);
  const isActiveSubscriber = useSelector(s => isActiveMembership(s.membershipState));

  const getContent = () => props[currentIntent];

  const getFooterClass = content => {
    return classNames("content-footer", {
      "no-image": !content.image,
    });
  };

  const getContainerClass = () => {
    return classNames("mid-grid-message-container", {
      "no-blocks-message": !getContent().blocks.length,
    });
  };

  const renderGridBody = () => {
    if (getContent().blocks.length) {
      return <div className="grid-message-body">{renderBlocks(getContent().blocks)}</div>;
    }
    return null;
  };

  const renderBlocks = blocks => {
    return blocks.map((block, i) => (
      <div className="content-container" key={i}>
        <div className="image">{MoleculeMidGridEducationalMessage.ICONS[block?.image]}</div>
        <div className="content-header">{block.header}</div>
        <div className={getFooterClass(block)}>{block.footer}</div>
      </div>
    ));
  };

  if (isActiveSubscriber || !isRentableGrid || !getContent() || !Object.keys(getContent()).length) {
    return null;
  }

  const { style, title, footer, cta } = getContent();
  return (
    <section className="mid-grid-message" style={style} data-test-id="mid-grid-educational-message">
      <div className={getContainerClass()}>
        <h2 className="grid-message-title">{title}</h2>
        {footer && <p className="grid-message-footer">{footer}</p>}
        {renderGridBody()}
        <div className="btn-container">
          <AtomFullWidthLinkButton buttonText={cta.text} href={cta.path} style={cta.style} />
        </div>
      </div>
    </section>
  );
};

MoleculeMidGridEducationalMessage.ICONS = {
  Hanger: <Hanger />,
  Lightning: <Lightning />,
  Clipboard: <Clipboard />,
  Calendar: <Calendar />,
  MeasuringTape: <MeasuringTape />,
  Leaf: <Leaf />,
};

MoleculeMidGridEducationalMessage.propTypes = midGridContentPropType;

export default MoleculeMidGridEducationalMessage;
