import React from "react";
import PropTypes from "prop-types";
import RtrImage from "../shared/rtr-image";
/*
  The goal of this component is to display an image by default, but a video if provided.
*/

const propTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  videoSrcMp4: PropTypes.string,
  videoSettings: PropTypes.shape({
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
  }),
  customClass: PropTypes.string,
};

const defaultProps = {
  videoSettings: {
    controls: true,
    autoPlay: false,
    loop: false,
  },
  customClass: "",
};

const baseClass = "atom-visual-media";

class AtomVisualMedia extends React.Component {
  videoMarkup() {
    const controls = this.props.videoSettings.controls ? "controls" : "";
    const autoPlay = this.props.videoSettings.autoPlay ? "autoPlay" : "";
    const loop = this.props.videoSettings.loop ? "loop" : "";

    return (
      <video
        className={`${baseClass} ${this.props.customClass}`}
        preload="auto"
        controls={controls}
        autoPlay={autoPlay}
        loop={loop}>
        <source src={this.props.videoSrcMp4} type="video/mp4" />
      </video>
    );
  }

  imageMarkup() {
    return (
      <RtrImage className={`${baseClass} ${this.props.customClass}`} src={this.props.image} alt={this.props.altText} />
    );
  }

  render() {
    if (this.props.videoSrcMp4) {
      return this.videoMarkup();
    }

    return this.imageMarkup();
  }
}

AtomVisualMedia.propTypes = propTypes;
AtomVisualMedia.defaultProps = defaultProps;

export default AtomVisualMedia;
