import React from "react";
import PropTypes from "prop-types";
import TextInputPair from "components/source/shared/text-input-pair";

class CopyField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onCopySuccess: PropTypes.func,
    wrapperClass: PropTypes.string,
    value: PropTypes.string.isRequired,
    hideCopyMessage: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    var className = "copy-text-field";
    if (props.className) {
      className = "copy-field-" + props.className;
    }

    this.state = {
      className: className,
    };
  }

  copy = () => {
    var link = document.getElementsByClassName(this.state.className)[0];
    const range = document.createRange();

    //make link content editable for safari mobile fix
    link.contentEditable = true;
    link.readOnly = false;

    // sets the range to contain the contents of the link
    range.selectNodeContents(link);

    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    link.setSelectionRange(0, link.value.length);

    // switch link back to read only
    link.contentEditable = false;
    link.readOnly = true;

    if (document.execCommand("copy")) {
      this.setState({
        message: "Copied to clipboard.",
      });
    } else {
      //If anything goes wrong the user will be
      //prompted to manually copy to clipboard
      this.setState({
        message: "Please copy to your clipboard.",
      });
    }

    if (this.props.onCopySuccess) {
      this.props.onCopySuccess();
    }
  };

  renderStatusMessage = () => {
    if (!this.state.message) {
      return null;
    }

    return <div className="italic body-copy copy-field__message">{this.state.message}</div>;
  };

  render() {
    var classes = "copy-field " + (this.props.wrapperClass || "");
    return (
      <div className={classes}>
        <TextInputPair
          label={this.props.hideCopyMessage ? "" : "Click below to copy"}
          inputProps={{ onFocus: this.copy, className: this.state.className }}
          defaultValue={this.props.value}
          readOnly={true}
        />
        {this.renderStatusMessage()}
      </div>
    );
  }
}

export default CopyField;
