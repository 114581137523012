import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import classNames from "classnames";
import { browserPropType, idPropType, atomHedAttributesPropType, productPropType } from "components/propTypes";
import AtomHed from "components/source/atoms/atom-hed";
import productRatingsActions from "actions/product-ratings-actions";
import RatingEducationTooltipsActions from "actions/rating-education-tooltips-actions";
import { analytics } from "rtr-constants";
import Product from "components/source/new_taxonomy/product";
import sharedActions from "actions/shared-actions";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import { getUserId, userDataPropType } from "../../hoc/with-user-data";
import styles from "./grid-query-carousels.module.scss";

export class GridQueryCarouselsComponent extends React.Component {
  state = {};
  static DEAD_END_SEARCH_RESULTS_OBJECT_TYPE = "dead_end_search_results";
  static DEAD_END_SEARCH_MODULE_TYPE = "dead_end_search_carousel";
  static propTypes = {
    browser: browserPropType,
    carouselInGrid: PropTypes.bool,
    carouselMinimum: PropTypes.number, // Minimum number of products in order for the carousel to display
    closeModal: PropTypes.func.isRequired,
    cmsUrl: PropTypes.string,
    deadEndCarousel: PropTypes.bool,
    deadEndSearchData: PropTypes.object,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    extraPixelData: PropTypes.object,
    favorites: PropTypes.array,
    fetchUserProductRatings: PropTypes.func.isRequired,
    getIsHeartEducationTooltipDismissed: PropTypes.func.isRequired,
    headerType: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    hedText: PropTypes.string,
    isFullBleedCarousel: PropTypes.bool,
    isPeekCarousel: PropTypes.bool,
    isUnlimitedRentableGrid: PropTypes.bool,
    mobileHedAttributes: atomHedAttributesPropType,
    mobileHedText: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    productCarousels: PropTypes.arrayOf(
      PropTypes.shape({
        alternateCarouselId: PropTypes.string,
        extraKey: PropTypes.string,
        gridQueryUrlString: PropTypes.string, // If present, populates the "View All" link
        id: idPropType,
        priceDisplayOverrides: PropTypes.shape({
          showClassicPricing: PropTypes.bool,
          showMembershipPricing: PropTypes.bool,
          showOnlyRetailPrice: PropTypes.bool,
        }),
        products: PropTypes.arrayOf(productPropType),
        requestId: idPropType,
        title: PropTypes.string,
      })
    ),
    queryData: PropTypes.arrayOf(Object),
    shortlists: PropTypes.array,
    slimVersion: PropTypes.bool, // Using CSS, we hide a bunch of attributes on the carousel, e.g. price and annotations
    userData: userDataPropType,
  };

  static defaultProps = {
    carouselMinimum: 7,
    favorites: [],
  };

  componentDidMount() {
    const { userData } = this.props;
    const userId = getUserId(userData);
    if (this.props.deadEndCarousel) {
      this.props.productCarousels.forEach((_, idx) => this.triggerShowDeadEndCarouselPixel(idx));
    }
    if (userId) {
      this.props.fetchUserProductRatings();
    }

    this.props.getIsHeartEducationTooltipDismissed();
  }

  componentDidUpdate(prevProps) {
    const currentUserId = getUserId(this.props.userData);
    const prevUserId = getUserId(prevProps.userData);
    if (currentUserId && currentUserId !== prevUserId) {
      this.props.fetchUserProductRatings();
    }
  }

  getCarouselIndex = carouselData => {
    this.setState({
      maxItemsPerPage: carouselData.maxItemsPerPage,
    });
  };

  atLeastOneCarouselHasProducts() {
    const { productCarousels } = this.props;

    return productCarousels?.some(pc => pc && pc.products?.length);
  }

  isBuyNow() {
    return Boolean(
      this.props.queryData?.buyNow === "true" ||
        this.props.queryData?.find(queryObj => queryObj?.query && queryObj.query.toLowerCase().includes("buynow=true"))
    );
  }

  shouldIncludeProductPrice() {
    return !this.props.isUnlimitedRentableGrid;
  }

  logViewAll(type, carouselId, index) {
    const { deadEndCarousel, deadEndSearchData, extraPixelData = {} } = this.props;
    if (deadEndCarousel) {
      const APIAndCarouselData = this.browseAPIAndCarouselData(index);
      const dead_end_module_type = this.constructor.DEAD_END_SEARCH_MODULE_TYPE;
      const deadEndPixelData = {
        module_type: dead_end_module_type,
        ...deadEndSearchData,
        ...APIAndCarouselData,
      };
      ActionLogger.inferAction({
        object_type: this.constructor.DEAD_END_SEARCH_RESULTS_OBJECT_TYPE,
        action_type: "click_view_all",
        ...deadEndPixelData,
      });
    }

    const dataToLog = {
      action: "click_view_all",
      nth_carousel: index,
      carousel_id: carouselId,
      position: type,
      ...extraPixelData,
    };

    ActionLogger.inferAction(dataToLog);
  }

  logStylesInView = (styleNames, carousel, index, carouselId, previousIndex, newIndex) => {
    const { deadEndCarousel, extraPixelData = {} } = this.props;
    if (!deadEndCarousel) {
      const dataToLog = {
        action: "visible_products",
        products: JSON.stringify(styleNames),
        request_id: carousel.requestId,
        nth_carousel: newIndex,
        carousel_id: carouselId,
        ...extraPixelData,
      };

      ActionLogger.logAction(dataToLog);
    }
  };

  logClickArrow(direction, carouselId, index) {
    const { extraPixelData = {} } = this.props;
    const action = direction === 1 ? "click_right_arrow" : "click_left_arrow";
    const dataToLog = {
      action: action,
      nth_carousel: index,
      carousel_id: carouselId,
      ...extraPixelData,
    };

    ActionLogger.logAction(dataToLog);
  }

  scrollLoggingOptions = index => {
    const { productCarousels: carousels, extraPixelData = {} } = this.props;
    const carousel = carousels[index];

    if (carousel && this.state.maxItemsPerPage) {
      const styleNames = (carousel.products || []).map(product => product.id);

      const firstProducts = Array.isArray(styleNames) ? styleNames.slice(0, this.state.maxItemsPerPage) : false;

      const options = {
        ...extraPixelData,
        request_id: carousel.requestId,
        nth_carousel: index,
        carousel_id: carousel.id,
        page_length: this.state.maxItemsPerPage,
        filters: JSON.stringify(this.props.extraPixelData?.filters),
        products: JSON.stringify(styleNames),
        ...(firstProducts && { first_products: JSON.stringify(firstProducts) }),
      };

      Object.keys(options).forEach(k => {
        if (typeof options[k] === "undefined") {
          delete options[k];
        }
      });

      return {
        action: "show_carousel",
        options: options,
        callback: () => {
          this.logStylesInView(firstProducts, carousel, index);
        },
      };
    }
  };

  renderHed() {
    if (!this.props.hedText) {
      return null;
    }

    const headerType = this.props.headerType || "h2";

    return (
      <AtomHed
        text={this.props.hedText}
        type={headerType}
        hedAttributes={this.props.hedAttributes}
        mobileText={this.props.mobileHedText}
        mobileHedAttributes={this.props.mobileHedAttributes}
        customClass={styles["full-bleed-hed"]}
      />
    );
  }

  browseAPIAndCarouselData(index) {
    const { productCarousels: carousels } = this.props;
    const carousel = carousels[index];
    return {
      carousel_id: carousel.id,
      nth_carousel: index,
      request_id: carousel.requestId,
    };
  }

  triggerShowDeadEndCarouselPixel(index) {
    const { deadEndSearchData, productCarousels } = this.props;
    const APIAndCarouselData = this.browseAPIAndCarouselData(index);
    const dead_end_module_type = this.constructor.DEAD_END_SEARCH_MODULE_TYPE;
    const prodArray = productCarousels[index].products.map(product => product.id);
    const deadEndPixelData = {
      module_type: dead_end_module_type,
      products: JSON.stringify(prodArray),
      first_products: JSON.stringify(prodArray.slice(0, 5)),
      ...deadEndSearchData,
      ...APIAndCarouselData,
    };
    ActionLogger.logAction({
      object_type: this.constructor.DEAD_END_SEARCH_RESULTS_OBJECT_TYPE,
      action_type: "show_carousel",
      ...deadEndPixelData,
    });
  }

  renderCarousel(carousel, carouselId, index) {
    let productsMarkup = this.renderProducts(carousel.products, carousel.priceDisplayOverrides, index);
    // Only add the viewAll card onto the carousel if we have a link for it
    if (carousel.gridQueryUrlString) {
      const viewAllMarkup = this.renderViewAllCard(carousel.gridQueryUrlString, index, carouselId);
      productsMarkup = [productsMarkup, viewAllMarkup].flat();
    }

    const mobilePageSize = this.props.isFullBleedCarousel ? 1.5 : 2;
    const pageSize = this.props.isPeekCarousel ? 4.5 : this.props.isFullBleedCarousel ? 4 : 5;

    return (
      <SwipeableCarousel
        getCarouselIndex={this.getCarouselIndex}
        logClickArrow={direction => {
          this.logClickArrow(direction, carouselId, index, carouselId);
        }}
        logStylesInView={(styleNames, carousel, index, previousIndex, newIndex) =>
          this.logStylesInView(styleNames, carousel, index, carouselId, previousIndex, newIndex)
        }
        deadEndCarousel={this.props.deadEndCarousel}
        deadEndSearchData={this.props.deadEndSearchData}
        deadEndAPIAndCarouselData={this.browseAPIAndCarouselData(index)}
        carousel={carousel}
        carouselInGrid={this.props.carouselInGrid}
        pixelData={this.scrollLoggingOptions(index, carouselId)}
        mobilePageSize={mobilePageSize}
        pageSize={pageSize}
        hideInactiveButtons={this.props.isFullBleedCarousel}
        isFullBleedCarousel={this.props.isFullBleedCarousel}
        isPeekCarousel={this.props.isPeekCarousel}
        useSmoothScroll={this.props.isFullBleedCarousel}
        fullBleedCarouselButtonSelector={`.grid-product-card-image-wrapper`}>
        {productsMarkup}
      </SwipeableCarousel>
    );
  }

  renderViewAllCard(gridQueryUrlString, index, carouselId) {
    return (
      <div key="view-all-cta">
        <div className={styles["view-all-carousel-item"]}>
          <a
            className={styles["grid-link"]}
            onClick={() => {
              this.logViewAll("card", carouselId, index);
            }}
            href={gridQueryUrlString}>
            View All
          </a>
        </div>
      </div>
    );
  }

  renderProducts(products, priceDisplayOverrides, index) {
    const isBuyNow = this.isBuyNow();
    return products?.map((product, i) => {
      return (
        <Product
          browser={this.props.browser}
          closeModal={() => {
            this.props.closeModal();
          }}
          deadEndCarousel={this.props.deadEndCarousel}
          deadEndSearchData={this.props.deadEndSearchData}
          deadEndAPIAndCarouselData={this.browseAPIAndCarouselData(index)}
          productList={this.props.productCarousels[index].products}
          displayedModal={this.props.displayedModal}
          favorited={this.props.favorites?.includes(product.id)}
          key={`${product.id}-${i}`}
          isBuyNow={isBuyNow}
          location={analytics.ACTION_LOCATIONS.CAROUSEL}
          openModal={modalName => {
            this.props.openModal(modalName);
          }}
          product={product}
          productIndex={i}
          priceDisplayOverrides={priceDisplayOverrides}
          shortlists={this.props.shortlists}
          shouldIncludeProductPrice={this.shouldIncludeProductPrice()}
          userData={this.props.userData}
          extraPixelData={this.props.extraPixelData}
        />
      );
    });
  }

  renderTitle(title) {
    const titleClassName = classNames(styles["carousel-title"], {
      "universal-large--semibold": !this.props.isFullBleedCarousel || this.props.productCarousels.length > 1,
      [styles["main-title"]]: this.props.isFullBleedCarousel && this.props.productCarousels.length === 1,
    });

    return <p className={titleClassName}>{title}</p>;
  }

  renderViewAllLink(carousel, carouselId, index) {
    // Only show the viewAll link if we have a URL to which it can point
    if (!carousel.gridQueryUrlString) {
      return;
    }

    return (
      <a
        className={styles["grid-link"]}
        onClick={() => {
          this.logViewAll("title", carouselId, index);
        }}
        href={carousel.gridQueryUrlString}>
        View All
      </a>
    );
  }

  render() {
    if (!this.atLeastOneCarouselHasProducts()) {
      return null;
    }

    const { productCarousels, isFullBleedCarousel } = this.props;

    const rootClassName = classNames(styles["grid-query-carousels"], {
      [styles["full-bleed-carousels"]]: this.props.isFullBleedCarousel,
    });

    const carouselClassName = classNames(styles["grid-query-carousel"], {
      [styles["slim"]]: this.props.slimVersion,
    });

    return (
      <div className={rootClassName}>
        {this.renderHed()}
        {productCarousels?.map((carousel, j) => {
          if (!carousel) {
            return;
          }
          const carouselId = carousel.alternateCarouselId ? carousel.alternateCarouselId : `${this.props.cmsUrl}-${j}`;

          // Only render carousel with more than the carousel minimum number of items
          if (carousel.products?.length < this.props.carouselMinimum) {
            return;
          }

          return (
            <div className={carouselClassName} key={j}>
              {isFullBleedCarousel ? (
                <div className={styles["full-bleed-title-wrapper"]}>
                  {this.renderTitle(carousel.title || carousel.name)}
                  {this.renderViewAllLink(carousel, carouselId, j)}
                </div>
              ) : (
                <>
                  {this.renderTitle(carousel.title || carousel.name)}
                  {this.renderViewAllLink(carousel, carouselId, j)}
                </>
              )}
              {this.renderCarousel(carousel, carouselId, j)}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  closeModal: () => sharedActions.displayModal(false),
  openModal: modalName => sharedActions.displayModal(modalName),
  fetchUserProductRatings: productRatingsActions.loadUserProductRatings,
  getIsHeartEducationTooltipDismissed: RatingEducationTooltipsActions.getIsHeartEducationTooltipDismissed,
};

export default connect(null, mapDispatchToProps)(GridQueryCarouselsComponent);
