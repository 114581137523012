import React from "react";
import PropTypes from "prop-types";
import { membershipStatePropType, rentMyStyleQuizContentPropType } from "components/propTypes";
import RentMyStyleQuizModal from "components/source/paid_marketing/rent-my-style/rent-my-style-quiz-modal";
import { connect } from "react-redux";
import { RENT_MY_STYLE_QUIZ_MODAL_NAME, allMaternityFilters } from "rtr-constants";
import SharedActions from "actions/shared-actions";
import ProductsActions from "actions/products-actions";
import HoldPreferencesActions from "actions/hold-preferences-actions";
import getSelectedFilters from "modules/get-selected-filters";
import { isActiveMembership } from "helpers/membership-helpers";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

export class RentMyStyleQuizCTA extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    dateOptions: PropTypes.object,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    membershipState: membershipStatePropType,
    onCloseRentMyStyleQuiz: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    rentMyStyleQuizContent: rentMyStyleQuizContentPropType,
    workingFilters: PropTypes.object,
  };

  elementRef = React.createRef(null);

  componentDidMount() {
    this.scrollPositionLogger = createScrollIntoViewPixelLogger(this.elementRef, "view", {
      object_type: "quiz_cta",
    });
  }

  componentWillUnmount() {
    if (this.scrollPositionLogger) {
      this.scrollPositionLogger.disconnect();
    }
  }

  onFilterChange = (filterDatum, secondFilterDatum) => {
    let selectedFilters = getSelectedFilters(filterDatum, this.props.workingFilters);
    if (!selectedFilters) {
      return;
    }

    // if a secondFilterDatum is passed (usually duration in a datepicker) we merge both filter objects together
    if (secondFilterDatum) {
      const secondSelectedFilter = getSelectedFilters(secondFilterDatum, this.props.workingFilters);
      selectedFilters = { ...secondSelectedFilter, ...selectedFilters };
    }

    this.props.onFilterChange(selectedFilters);
  };

  onMaternityFilterChange = () => {
    // apply all maternity filters in one click
    // calling `onFilterChange` multiple times may cause race conditions
    let updatedFilters;
    if (this.props.workingFilters?.epicMaternity?.length) {
      updatedFilters = { ...this.props.workingFilters, epicMaternity: [] };
    } else {
      updatedFilters = { ...this.props.workingFilters, ...allMaternityFilters };
    }
    this.props.onFilterChange(updatedFilters);
  };

  render() {
    const { displayedModal, membershipState, rentMyStyleQuizContent } = this.props;
    const modalIsOpen = displayedModal === RENT_MY_STYLE_QUIZ_MODAL_NAME;

    if (isActiveMembership(membershipState)) {
      return null;
    }

    return (
      <div ref={this.elementRef}>
        {this.props.children}
        {modalIsOpen && rentMyStyleQuizContent && (
          <RentMyStyleQuizModal
            showBackgroundImage={false}
            isClosable={true}
            closeModal={this.props.onCloseRentMyStyleQuiz}
            displayedModal={this.props.displayedModal}
            dateOptions={this.props.dateOptions}
            onChange={this.onFilterChange}
            filters={this.props.workingFilters}
            quizContent={this.props.rentMyStyleQuizContent}
            onMaternityFilterChange={this.onMaternityFilterChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dateOptions: state.dateOptions,
    displayedModal: state.displayedModal,
    membershipState: state.membershipState,
    rentMyStyleQuizContent: state.smartComponentState?.rentMyStyleQuizContent?.quizContent,
    workingFilters: state.workingFilters,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseRentMyStyleQuiz: () => dispatch(SharedActions.displayModal(false)),
    onFilterChange: selectedFilters => {
      dispatch(ProductsActions.changeFilters(selectedFilters));
      dispatch(HoldPreferencesActions.writeHoldPreferences(selectedFilters));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentMyStyleQuizCTA);
