import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Cookies, withCookies } from "react-cookie";
import PropTypes from "prop-types";

import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import ActionLogger from "action-logger";
import sharedActions from "actions/shared-actions";
import AtomLinkButton from "components/source/atoms/atom-link-button";
import AiSearchFeedbackModal from "components/source/new_taxonomy/ai-search-feedback-modal";
import AiSearchFeedbackConfirmationModal from "components/source/new_taxonomy/ai-search-feedback-confirmation-modal";
import { flagsAndExperimentNames } from "components/source/hoc/with-flags-and-experiments";
import AtomHed from "components/source/atoms/atom-hed";
import { selectLDFeatureFlagEnabled } from "selectors/featureFlagSelectors";
import { CMSHedTypes, loyalty } from "rtr-constants";
import styles from "./molecule-generic-grid-topper.module.scss";
import { atomTextLinksPropType } from "../../propTypes/atom-text-attributes-prop-type";
import { awardWithMatchingProperties } from "../membership/settings/rtr-insider/progress-tile-helper";
import { membershipStatePropType } from "components/propTypes";

export const MoleculeGenericGridTopper = ({
  cookies,
  hedText,
  subText,
  subTextLinks,
  eyebrowFontStyle,
  eyebrowText,
  mobileEyebrowText,
  topperType = "standard",
  size = "small",
  textAlignment = "centerAlign",
  backgroundColor,
  backgroundImageDesktop,
  backgroundImageMobile,
  hedFontStyle = "normal",
  subFontStyle = "normal",
  fontColor = "#000",
  isAISearchEnabled,
  isMobileViewport,
  defaultGridTitle,
  displayedModal,
  searchText,
  displayModal,
  fetchLDFlag,
  userId,
  membershipState,
}) => {
  useEffect(() => {
    if (typeof isAISearchEnabled === "undefined" && !!fetchLDFlag) {
      fetchLDFlag(flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX);
    }
  }, []);

  const buildSearchTitle = () => {
    if (!searchText || !searchText.trim().length) return null;
    return `Results for "${searchText.trim()}"`;
  };

  const getTitleText = () => {
    return buildSearchTitle() || hedText || defaultGridTitle;
  };

  const getSubTitleText = () => {
    return subText;
  };

  const openAiSearchFeedbackModal = () => {
    displayModal(AiSearchFeedbackModal.modalName);
  };

  const onClickFeedbackSubmit = (selectedFeedback, text) => {
    ActionLogger.logAction({
      search_text: searchText,
      selected_feedback: selectedFeedback || "n/a",
      user_id: userId || -1,
      device_id: cookies.get("RTR_ID"),
      action: "click_feedback_response_option",
      object_type: "ai_search_feedback_modal",
      text_feedback: text || "n/a",
    });
    displayModal(AiSearchFeedbackConfirmationModal.modalName);
  };

  const renderAISearchFeedbackModal = () => {
    if (!buildSearchTitle()) return null;

    return (
      <>
        <div
          className={`${styles.aiSearchFeedback} ${isAISearchEnabled ? styles.visible : ""}`}
          data-test-id="ai-search">
          <span>How&apos;s your search experience?&nbsp;</span>
          <AtomLinkButton onClick={openAiSearchFeedbackModal}>Let us know</AtomLinkButton>
        </div>
        <AiSearchFeedbackModal
          key="aiSearchFeedbackModal"
          onChange={onClickFeedbackSubmit}
          isMobileViewport={isMobileViewport}
          onRequestClose={() => displayModal(false)}
          displayedModal={displayedModal}
        />
        <AiSearchFeedbackConfirmationModal
          className="ai-search-feedback-confirmation-modal"
          key="aiSearchFeedbackConfirmationModal"
          onRequestClose={() => displayModal(false)}
          isMobileViewport={isMobileViewport}
          displayedModal={displayedModal}
        />
      </>
    );
  };

  const renderText = () => {
    if (
      getTitleText() === "New Arrivals" &&
      awardWithMatchingProperties(membershipState?.loyaltyAwards, {
        rewardType: loyalty.types.NEW_ARRIVALS,
        awardState: loyalty.states.ALWAYS_ON,
      })
    ) {
      return;
    }
    return (
      <>
        {eyebrowText && (
          <AtomHed
            type={CMSHedTypes.H2}
            text={eyebrowText}
            mobileText={mobileEyebrowText}
            customClass={`${styles[eyebrowFontStyle]} ${styles.eyebrowText}`}
          />
        )}
        <AtomHed
          type={CMSHedTypes.H1}
          customClass={`${styles[hedFontStyle]} ${styles.hedText}`}
          text={getTitleText()}
        />
        {getSubTitleText() && (
          <AtomHed
            type={CMSHedTypes.H2}
            customClass={`${styles[subFontStyle]} ${styles.subText}`}
            text={getSubTitleText()}
            textLinks={subTextLinks}
          />
        )}
        {renderAISearchFeedbackModal()}
      </>
    );
  };

  const wrapperInlineStyles = {
    backgroundColor,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    ["--font-color"]: fontColor,
  };
  const backgroundImage = isMobileViewport ? backgroundImageMobile : backgroundImageDesktop;
  if (backgroundImage) {
    wrapperInlineStyles.backgroundImage = `url(${backgroundImage})`;
  }

  // NW [EXPLANATION] 6/7/2024: the CMS team is "hiding" titles by supplying a whitespace `hedText` to override `defaultGridTitle`. In this case they do not want the text below the image to be rendered.
  const hasText = getTitleText()?.match(/[^\s\\]/);
  const shouldRenderTextBelow = isMobileViewport && topperType === "special" && size === "large";

  return (
    !!getTitleText() && (
      <>
        <div
          className={`${styles.topper} ${styles[topperType]} ${styles[size]} ${styles[textAlignment]} ${
            getSubTitleText() ? styles.hasSubText : ""
          }`}
          style={wrapperInlineStyles}
          data-test-id="topper-wrapper">
          {!shouldRenderTextBelow && renderText()}
        </div>
        {shouldRenderTextBelow && hasText && (
          <div
            className={`${styles.mobileTextBelow} ${getSubTitleText() ? styles.hasSubText : ""}`}
            data-test-id="mobile-text-below">
            {renderText()}
          </div>
        )}
      </>
    )
  );
};

MoleculeGenericGridTopper.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImageDesktop: PropTypes.string,
  backgroundImageMobile: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  displayModal: PropTypes.func,
  eyebrowFontStyle: PropTypes.oneOf(["normal", "italic", "oblique"]),
  eyebrowText: PropTypes.string,
  mobileEyebrowText: PropTypes.string,
  fetchLDFlag: PropTypes.func,
  filters: PropTypes.shape({
    searchText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }),
  fontColor: PropTypes.string,
  hedFontStyle: PropTypes.oneOf(["normal", "italic", "oblique"]),
  hedText: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  subFontStyle: PropTypes.oneOf(["normal", "italic", "oblique"]),
  subText: PropTypes.string,
  subTextLinks: atomTextLinksPropType,
  textAlignment: PropTypes.oneOf(["leftAlign", "centerAlign", "rightAlign", "flexAlign"]),
  topperType: PropTypes.oneOf(["standard", "special"]),
  searchText: PropTypes.string,
  isAISearchEnabled: PropTypes.bool,
  isMobileViewport: PropTypes.bool,
  defaultGridTitle: PropTypes.string,
  displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  userId: PropTypes.number,
  membershipState: membershipStatePropType,
};

const mapStateToProps = state => ({
  searchText: Array.isArray(state.workingFilters?.searchText)
    ? state.workingFilters?.searchText[0]
    : state.workingFilters?.searchText,
  isAISearchEnabled: selectLDFeatureFlagEnabled(flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX)(state),
  isMobileViewport: state.browser?.isMobileViewport,
  defaultGridTitle: state.title || state.gridMetaData?.title, //adding gridMetaData as Next grids store title inside gridMetaData which comes from Disco
  displayedModal: state.displayedModal,
  userId: state.userData?.userProfile?.id,
  membershipState: state.membershipState,
});

const mapDispatchToProps = dispatch => ({
  displayModal: modalName => dispatch(sharedActions.displayModal(modalName)),
  fetchLDFlag: flagName => dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagName)),
});

export default compose(withCookies, connect(mapStateToProps, mapDispatchToProps))(MoleculeGenericGridTopper);
