/*
  // typical usage:
  componentDidMount() {
    const { displayName, name } = this.constructor
    emitDeprecationEvent(displayName || name);
  }
*/

export const emitDeprecationEvent = name => {
  window?.Sentry?.captureEvent({
    level: "warning",
    message: `Deprecation: accessed deprecated codepath ${name}`,
  });
};
