import React from "react";
import _ from "underscore";
import AtomDek from "components/source/atoms/atom-dek";

import PropTypes from "prop-types";
import { atomTextAttributesPropType } from "components/propTypes";
import RtrImage from "../shared/rtr-image";

const propTypes = {
  bulletIcon: PropTypes.string,
  retinaBulletIcon: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ).isRequired,
  listItemAttributes: atomTextAttributesPropType,
  mobileListItemAttributes: atomTextAttributesPropType,
};

export class AtomUnorderedList extends React.Component {
  baseClassWith(element) {
    return ["atom-unordered-list", element].join("");
  }

  renderItems() {
    return _.map(
      this.props.listItems,
      function (item, index) {
        return this.renderItem(item, index);
      },
      this
    );
  }

  renderItem(item, index) {
    var itemIndex = "item-" + index,
      icon;

    if (this.props.retinaBulletIcon) {
      icon = (
        <div className={this.baseClassWith("__retina-bullet")}>
          <RtrImage src={this.props.retinaBulletIcon} />
        </div>
      );
    } else if (this.props.bulletIcon) {
      icon = (
        <div className={this.baseClassWith("__bullet")}>
          <RtrImage src={this.props.bulletIcon} />
        </div>
      );
    }

    return (
      <li className={this.baseClassWith("__li")} key={itemIndex}>
        {icon}
        <AtomDek
          text={item.text}
          dekAttributes={this.props.listItemAttributes}
          mobileText={item.text}
          mobileDekAttributes={this.props.mobileListItemAttributes}
        />
      </li>
    );
  }

  render() {
    return <ul className="atom-unordered-list">{this.renderItems(this.props.listItems)}</ul>;
  }
}

AtomUnorderedList.propTypes = propTypes;

export default AtomUnorderedList;
