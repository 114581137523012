import React from "react";
import _ from "underscore";
import ActionLogger from "action-logger";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";

import PropTypes from "prop-types";
import AtomUnorderedList from "components/source/atoms/atom-unordered-list";
import RentalOptionIntent, { RentalOptions, ActionsTaken } from "helpers/rental-option-intent-helper";
import { withUserData } from "../hoc/with-user-data";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";
import RtrImage from "../shared/rtr-image";

export class MoleculeInformationBoxesComponent extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    bulletIcon: PropTypes.string, // Icon for the unordered list
    cmsUrl: PropTypes.string,
    customClass: PropTypes.string,
    hedAttributes: atomHedAttributesPropType, // Attributes for the main heading of the molecule
    hedText: PropTypes.string, // The main heading of the molecule
    imageType: PropTypes.string,
    listBodyAttributes: atomTextAttributesPropType,
    listBulletTextAttributes: atomTextAttributesPropType,
    listDekAttributes: atomTextAttributesPropType,
    listHedAttributes: atomTextAttributesPropType, // Attributes for the various text items below the image
    listItems: PropTypes.arrayOf(
      // Can be an array of 1, 2, or 3 items
      PropTypes.shape({
        body: PropTypes.string, // The left-indented text above the ul
        bulletListItems: PropTypes.arrayOf(
          // The items for the ul
          PropTypes.shape({
            text: PropTypes.string,
          })
        ),
        ctaButton: PropTypes.shape({
          href: PropTypes.string, // The CTA below the ul (button)
          text: PropTypes.string,
          action: PropTypes.string,
        }),
        ctaLink: PropTypes.shape({
          href: PropTypes.string, // The CTA below the ul (link)
          text: PropTypes.string,
          action: PropTypes.string,
        }),
        dek: PropTypes.string, // The text below the head
        hed: PropTypes.string, // The title of the list item
        image: PropTypes.string, // The main image for the item
        imageAlt: PropTypes.string,
      })
    ).isRequired,
    listSpacer: PropTypes.bool, // Show a spacer between dek and body (default false)
    retinaBulletIcon: PropTypes.string, // The same icon @2x
  };

  // still rendered as of 2021-06-14 at: /the-knot
  // componentDidMount() {
  //   emitDeprecationEvent("MoleculeInformationBoxes");
  // }

  baseClassWith(element) {
    return ["molecule-information-boxes", element].join("");
  }

  getStyles() {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  }

  logCtaAction(cta) {
    const text = cta?.text;

    switch (cta.action) {
      case "rtr_update_cta":
        RentalOptionIntent.setLastIntent(RentalOptions.NONE, ActionsTaken.UPDATE_CTA);
        break;
      case "rtr_unlimited_cta":
        RentalOptionIntent.setLastIntent(RentalOptions.NONE, ActionsTaken.UNLIMITED_CTA);
        break;
    }

    ActionLogger.inferAction({
      object_type: "subscription_plans_page",
      action: cta.action,
      context: "click",
      url: window.location.pathname,
      component_type: this.props.cmsUrl,
      copy: text,
    });
  }

  renderItems() {
    return _.map(
      this.props.listItems,
      function (item, index) {
        return this.renderItem(item, index);
      },
      this
    );
  }

  renderItem(item, index) {
    const imageStatus = this.props.imageType || "";

    return (
      <li className={`${this.baseClassWith("__li")} ${imageStatus}`} key={index}>
        {this.renderItemImage(item)}
        <div className={this.baseClassWith("__item-content")}>
          {this.renderItemHed(item)}
          {this.renderItemDek(item)}
          {this.renderItemSpacer()}
          {this.renderItemBody(item)}
          {this.renderItemBulletList(item)}
          {this.renderItemCtaButton(item)}
          {this.renderItemCtaLink(item)}
        </div>
      </li>
    );
  }

  renderItemImage(item) {
    return <RtrImage alt={item.imageAlt} className={this.baseClassWith("__image")} src={item.image} />;
  }

  renderItemHed(item) {
    return (
      <AtomDek
        text={item.hed}
        mobileText={item.hed}
        dekAttributes={this.props.listHedAttributes}
        customClass={this.baseClassWith("__hed")}
      />
    );
  }

  renderItemDek(item) {
    return (
      <AtomDek
        text={item.dek}
        mobileText={item.dek}
        dekAttributes={this.props.listDekAttributes}
        customClass={this.baseClassWith("__dek")}
      />
    );
  }

  renderItemSpacer() {
    if (this.props.listSpacer) {
      return <hr className={this.baseClassWith("__item-spacer")} />;
    } else {
      return null;
    }
  }

  renderItemBody(item) {
    return (
      <AtomDek
        text={item.body}
        mobileText={item.body}
        dekAttributes={this.props.listBodyAttributes}
        customClass={this.baseClassWith("__body")}
      />
    );
  }

  renderItemBulletList(item) {
    return (
      <AtomUnorderedList
        bulletIcon={this.props.bulletIcon}
        retinaBulletIcon={this.props.retinaBulletIcon}
        listItems={item.bulletListItems}
        listItemAttributes={this.props.listBulletTextAttributes}
      />
    );
  }

  renderItemCtaButton(item) {
    if (_.isEmpty(item.ctaButton)) {
      return;
    }

    return (
      <a
        href={item.ctaButton.href}
        className={this.baseClassWith("__cta--button")}
        onClick={() => {
          this.logCtaAction(item.ctaButton);
        }}>
        {item.ctaButton.text}
      </a>
    );
  }

  renderItemCtaLink(item) {
    if (_.isEmpty(item.ctaLink)) {
      return;
    }

    return (
      <a
        href={item.ctaLink.href}
        className={this.baseClassWith("__cta--link")}
        onClick={() => {
          this.logCtaAction(item.ctaLink);
        }}>
        {item.ctaLink.text}
      </a>
    );
  }

  render() {
    const customClassName = this.props.customClass ? this.baseClassWith(`--${this.props.customClass}`) : "";
    const numberOfItemsClass = this.baseClassWith(`--${this.props?.listItems?.length}`);

    return (
      <div className={`molecule-information-boxes ${customClassName} ${numberOfItemsClass}`} style={this.getStyles()}>
        <AtomHed text={this.props.hedText} type="h2" hedAttributes={this.props.hedAttributes} />
        <ul className={this.baseClassWith("__ul")}>{this.renderItems(this.props.listItems)}</ul>
      </div>
    );
  }
}

export default withUserData(MoleculeInformationBoxesComponent);
