import React from "react";
import _ from "underscore";
// Sometimes we want both JSON and HTML to paint a page. Instead of using
// escaping your HTML to be valid JSON, or using separate CMS entries, this
// Component allows you to define your HTML using JSON.
//
// `markup` can be a single object, or an array of objects
// an object has the html tag as the key and a "definition" as the value
// a `definition` can be a
//   1) string: results in a tag with the string as its content
//     ex. { h1: "Rent the Runway" }
//     result: <h1>Rent the Runway</h1>
//
//   2) array of objects: takes this process from the top with the array
//     ex. {
//       div: [
//         { h2: "Unlimited" },
//         { h3: "Your Closet in the Cloud" }
//       ]
//     }
//     result: <div><h2>Unlimited</h2><h3>Your Closet in the Cloud</h3></div>
//
//   3) an object, with the required key `children`.
//     a) `children` can either be a string (1) or an array (2) from above
//     b) all remaining key/value pairs are passed to the element as props
//     ex. {
//       div: {
//         className: "dek",
//         children: "$89/month"
//       }
//     }
//     result: <div class="dek">$89/month</div>
//
//
// >>> Example JSON to pass in the `markup` prop
//
// [
//   {
//     div: {
//       className: "headline",
//       children: [
//         { em: 'RTR' },
//         { span: 'Unlimited' }
//       ]
//     }
//   },
//   {
//     div: {
//       className: "dek",
//       children: "Unlimited Rentals for $159/month. Cancel anytime."
//     }
//   },
//   { hr: null },
//   {
//     ul: [
//       { li: "Pick 4 pieces at a time. Swap out pieces whenever you’d like." },
//       { li: "Free shipping, dry cleaning and insurance." },
//       { li: "Purchase pieces you love at a members-only discount." }
//     ]
//   },
//   {
//     div: {
//       className: "highlight",
//       children: "$99 for your first month with code CLEANSE99"
//     }
//   }
// ]
//
// >>> rendered HTML (whitespace added):
//
// <div class="cms-json-html">
//   <div class="headline">
//     <em>RTR</em>
//     <span>Unlimited</span>
//   </div>
//   <div class="dek">Unlimited Rentals for $159/month. Cancel anytime.</div>
//   <hr>
//   <ul>
//     <li>Pick 4 pieces at a time. Swap out pieces whenever you’d like.</li>
//     <li>Free shipping, dry cleaning and insurance.</li>
//     <li>Purchase pieces you love at a members-only discount.</li>
//   </ul>
//   <div class="highlight">$99 for your first month with code CLEANSE99</div>
// </div>

import PropTypes from "prop-types";

export const CLASSNAME = "json-markup";

const propTypes = {
  className: PropTypes.string,
  markup: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default class JsonMarkup extends React.Component {
  // definition can be:
  //   1. a string (which get printed)
  //   2. an array, whose elements are each rendered through the same process
  //   3. an object, with the special key `children`
  //     a. children will be rendered in a similar style to 1 & 2
  //     b. all other key/values become the props of the element
  renderHtmlElement(TagName, definition, key) {
    let children;
    const props = {};

    if (_.isObject(definition) && !_.isArray(definition)) {
      // when our Babel supports more destructuring, replace these lines with:
      // ({ children, ...props }) = definition;
      ({ children } = definition);
      _.extend(props, definition);
      // remove children key so it's not set as a prop on `TagName`
      delete props.children;
    } else {
      children = definition;
    }

    return (
      <TagName {...props} key={key}>
        {this.renderHtml(children)}
      </TagName>
    );
  }

  // definitions is an object
  renderHtmlFromObject(definitions, key) {
    if (!definitions) {
      return;
    }

    // iterate over key value pairs (usually only one) creating elements
    return _.map(definitions, (val, tag) => {
      return this.renderHtmlElement(tag, val, `${key}-${tag}`);
    });
  }

  // elements is an array
  renderHtmlFromArray(elements) {
    if (!elements) {
      return;
    }

    const elementArray = _.isArray(elements) ? elements : [elements];

    return _.map(elementArray, (ele, i) => this.renderHtmlFromObject(ele, i));
  }

  renderHtml(markup) {
    if (_.isString(markup)) {
      return markup;
    }

    return this.renderHtmlFromArray(markup);
  }

  getClassname() {
    return `${CLASSNAME} ${this.props.className || ""}`.trim();
  }

  render() {
    const markup = this.props.markup;

    if (!markup) {
      return null;
    }

    return <div className={this.getClassname()}>{this.renderHtml(markup)}</div>;
  }
}

JsonMarkup.propTypes = propTypes;
