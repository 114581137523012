import React from "react";
import PropTypes from "prop-types";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { dateFnsFormats } from "rtr-constants";
import { addDays, format as formatDate } from "date-fns";

class AvailabilityFiltersInterface extends React.Component {
  static defaultDuration = 4;
  static dateFilterKey = "date";
  static durationFilterKey = "duration";

  static propTypes = {
    component: PropTypes.any.isRequired,
    filters: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    optionalClass: PropTypes.string,
    submitFilters: PropTypes.func.isRequired,
  };

  getDuration = () => {
    return parseInt(this.props.filters.duration || this.constructor.defaultDuration, 10);
  };

  durationIsSelected = duration => {
    return this.getDuration() === duration;
  };

  selectFilterValue = filter => {
    const { filters, onChange, submitFilters } = this.props;

    onChange(filter);

    if (
      filter.filterGroupKey === this.constructor.durationFilterKey &&
      !Object.prototype.hasOwnProperty.call(filters, this.constructor.dateFilterKey)
    ) {
      return;
    }

    submitFilters();
  };

  changeZip = event => {
    this.props.onChange({
      filterGroupKey: "zip_code",
      name: event.target.value,
    });
  };

  getDateRange = () => {
    const date = this.props.filters.date;
    const duration = this.getDuration();

    if (date && duration) {
      const parsedStartDate = parseISOWithoutTime(date);
      const startDate = formatDate(parsedStartDate, dateFnsFormats.ddd_MMM_D);
      const endDate = formatDate(addDays(parsedStartDate, duration - 1), dateFnsFormats.ddd_MMM_D);

      return startDate + " – " + endDate;
    }
  };

  render() {
    const Component = this.props.component;

    return (
      <Component
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...this.props}
        getDuration={this.getDuration}
        durationIsSelected={this.durationIsSelected}
        selectFilterValue={this.selectFilterValue}
        changeZip={this.changeZip}
        getDateRange={this.getDateRange}
        optionalClass={this.props.optionalClass}
        duration={this.getDuration()}
      />
    );
  }
}

export default AvailabilityFiltersInterface;
