import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { eventIconsPanelPropType, optionsPropType } from "../../../propTypes/rent-my-style-quiz-content-prop-type";
import RtrImage from "../../shared/rtr-image";

const EventIconsPanel = ({ panel, options, skipQuiz, skipToGridUrl, onClickEventIcon }) => {
  const renderJustBrowsing = () => {
    const { skipToGridText } = panel;

    if (!skipToGridText) {
      return;
    }

    return (
      <a className={styles["just-browsing-link"]} data-test-id="skip-to-grid" onClick={skipQuiz} href={skipToGridUrl}>
        {skipToGridText}
      </a>
    );
  };

  const renderEventTiles = () => {
    const { panelOptions = [] } = panel;

    return (
      <div className={styles["event-tiles"]}>
        {panelOptions.map(optionKey => {
          const optionContent = options[optionKey];
          return (
            <a
              className={styles["event-tiles__icon-wrapper"]}
              key={optionKey}
              onClick={e => onClickEventIcon(optionContent, optionKey, e)}
              href={optionContent.gridUrl}>
              <div className={styles["event-tiles__icon-wrapper"]}>
                <RtrImage src={optionContent.icon} className={styles["event-tiles__icon"]} />
                <p>{optionContent.label}</p>
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  const { dek, header } = panel;

  return (
    <div>
      <div className={styles["header-copy"]}>
        <h3 dangerouslySetInnerHTML={{ __html: header }} />
        <p dangerouslySetInnerHTML={{ __html: dek }} />
      </div>
      {renderEventTiles()}
      {renderJustBrowsing()}
    </div>
  );
};

EventIconsPanel.propTypes = {
  panel: eventIconsPanelPropType.isRequired,
  options: PropTypes.objectOf(optionsPropType).isRequired,
  skipQuiz: PropTypes.func.isRequired,
  skipToGridUrl: PropTypes.string.isRequired,
  onClickEventIcon: PropTypes.func.isRequired,
};

export default EventIconsPanel;
