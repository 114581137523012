import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const baseClassName = "atom-rotating-word";

export default class AtomRotatingWord extends React.Component {
  renderOptions() {
    return this.props.options.map(option => {
      return (
        <span key={option} className={`${baseClassName}__item`}>
          {option}
        </span>
      );
    });
  }

  render() {
    return <span className={baseClassName}>{this.renderOptions()}</span>;
  }
}

AtomRotatingWord.propTypes = propTypes;
