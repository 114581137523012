import React, { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { rentMyStyleQuizContentPropType } from "components/propTypes";
import DateSelectPanel from "components/source/paid_marketing/rent-my-style/date-select-panel";
import objectToQueryString from "modules/object-to-query-string";
import ActionLogger from "action-logger";
import EventIconsPanel from "components/source/paid_marketing/rent-my-style/event-icons-panel";
import ListPanel from "components/source/paid_marketing/rent-my-style/list-panel";
import ZipSizePanel from "components/source/paid_marketing/rent-my-style/zip-size-panel";
import MarketingContentPanel from "components/source/paid_marketing/rent-my-style/marketing-content-panel";
import { navigateTo } from "helpers/location-helpers";
import styles from "./styles.module.scss";

const ZIP_REGEX = /^\d{5}$/;

export class RentMyStyleQuiz extends Component {
  static filterInputs = Object.fromEntries(["zip_code", "duration", "date"].map(f => [f, `filters[${f}]`]));

  static propTypes = {
    dateOptions: PropTypes.shape({
      blackoutDays: PropTypes.arrayOf(PropTypes.string).isRequired,
      dateEnd: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
    }).isRequired,
    duration: PropTypes.number.isRequired,
    filters: PropTypes.shape({
      date: PropTypes.string,
      zip_code: PropTypes.string,
      canonicalSizes: PropTypes.arrayOf(PropTypes.string),
      maternity: PropTypes.arrayOf(PropTypes.string),
    }),
    idSuffix: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onMaternityFilterChange: PropTypes.func,
    quizContent: rentMyStyleQuizContentPropType,
  };

  constructor(props) {
    super(props);

    const startPanelKey = this.props.quizContent.startPanel;
    const { maxPanels } = this.props.quizContent;
    const baseGridUrl = this.props.quizContent.defaultGridUrl;

    this.state = {
      currentPanel: startPanelKey,
      panelKeys: [startPanelKey],
      numPanels: null,
      maxPanels: maxPanels,
      index: 0,
      errors: {},
      baseGridUrl: baseGridUrl,
      currentZip: "",
    };
  }

  componentDidMount() {
    // We need to log a panel load for the first panel.
    this.logPanelLoad("forward", null, this.state.currentPanel);
  }

  getBaseLoggingObj() {
    const { quizContent: { extraLoggingData = {}, loggingObjectType, quizLaunch } = {} } = this.props;

    return {
      object_type: loggingObjectType,
      quiz_launch: quizLaunch,
      ...extraLoggingData,
    };
  }

  getTypeRenderFn(type) {
    const typeRenderFunctions = {
      // `type` is specified in the CMS for the panel. Each `type` corresponds
      // to a render function in this component.
      list: this.renderListPanel,
      zipAndSize: this.renderZipSizePanel,
      dateSelect: this.renderDateSelectPanel,
      eventIcons: this.renderEventIconsPanel,
      marketingContent: this.renderMarketingContent,
    };

    return typeRenderFunctions[type];
  }

  renderCurrentPanel(panelKey) {
    if (panelKey === "grid") {
      return;
    }

    // Returns a function where the content for the panel is already set.
    const {
      quizContent: { panels },
    } = this.props;
    const panel = panels[panelKey];
    const { type } = panel;
    const typeRenderFn = this.getTypeRenderFn(type);

    return typeRenderFn(panel);
  }

  getEventGridUrl = () => {
    //Parse the base grid URL (this comes from a prop ultimately derived from CMS)
    const [path, params] = this.state.baseGridUrl.split("?");
    const baseQueryParams = new URLSearchParams(params);
    const queryParams = Object.fromEntries(baseQueryParams);
    const { filters } = this.props;

    //Append any additional query params
    if (filters && Object.keys(filters).length) {
      Object.assign(queryParams, {
        filters: filters,
      });
    }

    return path + "?" + objectToQueryString(queryParams);
  };

  getCtaCopy = panelKey => {
    if (panelKey === "grid") {
      return "Unlock My Styles";
    }
    return "Next";
  };

  // Not set as `state` because `setState` is async and triggers rerender. This
  // isn't great since it ties the quiz intimately with a concept of an event,
  // but it'll do for now.
  eventDetails = {};

  cleanEventDetails(panelKey) {
    // If a user goes backward, we need to clear some, but not all, of their
    // event details. This isn't great because it uses specific knowledge of the
    // quiz content at launch in that some events ask for occasion or dress
    // code, and some don't. Both dress code and occasions are of the type
    // "eventList", so a check can be made to remove the extra event details. If
    // the ordering of the question changes, this will need to be generalized.
    const { quizContent } = this.props;
    const panelContent = quizContent.panels[panelKey];

    if (panelContent.type === "eventList") {
      this.eventDetails = _.omit(this.eventDetails, "event_child_type", "event_child_value");
    }
  }

  skipQuiz = event => {
    const {
      quizContent: { skipToGridUrl },
    } = this.props;

    event.preventDefault();

    ActionLogger.inferAction({
      ...this.getBaseLoggingObj(),
      action: "skip_to_grid",
    });

    navigateTo(skipToGridUrl);
  };

  finishQuiz = event => {
    if (event) {
      event.preventDefault();
    }

    ActionLogger.inferAction({
      ...this.getBaseLoggingObj(),
      action: "complete_quiz",
    });
    navigateTo(this.getEventGridUrl());
  };

  logPanelLoad(direction, fromPanel, toPanel) {
    const { filters, filters: { maternity } = {} } = this.props;
    // As requested by analytics, we only want to log a filter if it's nonempty.
    // There was also a specific keyname that was asked for.
    const filterLoggingKeys = {
      canonicalSizes: "canonicalSizes",
      zip_code: "zip",
      date: "rentBegin",
      maternity: "maternity",
    };
    const filterLoggingObj = {};
    const hasMaternityFilter = !!(maternity && Object.keys(maternity).length);

    Object.keys(filters).forEach(function (filterName) {
      const filterValue = filters[filterName];
      if (!filterValue || !Object.keys(filterValue).length) {
        return;
      }

      let filterValueString = filterValue;

      if (typeof filterValueString !== "string") {
        filterValueString = JSON.stringify(filterValueString);
      }

      const key = filterLoggingKeys[filterName];
      filterLoggingObj[key] = filterValueString;
    });

    ActionLogger.logAction({
      ...this.getBaseLoggingObj(),
      ...filterLoggingObj,
      ...this.eventDetails,
      trimester_filter: hasMaternityFilter,
      direction: direction,
      action: "panel_load",
      from_panel: fromPanel,
      to_panel: toPanel,
    });
  }

  loadNextPanel = (panelKey, baseGridUrl) => {
    if (panelKey === "grid") {
      return this.finishQuiz();
    }

    const toPanel = panelKey;
    const fromPanel = this.state.currentPanel;

    this.logPanelLoad("forward", fromPanel, toPanel);

    this.setState({
      currentPanel: toPanel,
      panelKeys: this.state.panelKeys.slice().concat([panelKey]),
      // Due to how the quiz is structured at launch, the following line works
      // because an option must have a url associated with and the user cannot
      // get to the grid without going through the quiz, or leaving on the first
      // screen. In the future, we should keep a stack of url's, and pop url's
      // off the stack if a user goes backwards.
      baseGridUrl: baseGridUrl || this.state.baseGridUrl,
    });

    this.changePanel(1);
  };

  loadPreviousPanel = () => {
    const fromPanel = this.state.currentPanel;
    const updatedPanelKeys = this.state.panelKeys.slice(0, -1);
    const toPanel = updatedPanelKeys[updatedPanelKeys.length - 1];

    this.cleanEventDetails(fromPanel);

    this.logPanelLoad("backward", fromPanel, toPanel);

    this.setState({
      currentPanel: toPanel,
      panelKeys: updatedPanelKeys,
    });

    this.changePanel(-1);
  };

  changePanel(direction) {
    const newIndex = direction + this.state.index;

    this.setState({
      index: newIndex,
    });
  }

  onChangeZip = event => {
    const zipCode = event.target.value.trim();

    this.props.onChange({
      filterGroupKey: "zip_code",
      name: zipCode,
    });
  };

  onChangeMaternity = () => {
    this.props.onMaternityFilterChange();
  };

  onChangeSize = event => {
    const size = event.name;
    const currentCanonicalSizes = this.props.filters.canonicalSizes || [];
    let canonicalSizes = [];

    if (currentCanonicalSizes.includes(size)) {
      // The size was already a filter, and clicked again, so the user is
      // deselected it. Therefore, we should remove it in `canonicalSizes`
      // before logging it.
      canonicalSizes = _.without(currentCanonicalSizes, size);
    } else {
      canonicalSizes = _.union(currentCanonicalSizes, [size]);
    }

    ActionLogger.logAction({
      ...this.getBaseLoggingObj(),
      action: "select_size",
      canonicalSizes: JSON.stringify(canonicalSizes),
    });

    this.props.onChange(event);
  };

  onChangeDate = dateValue => {
    const { onChange, duration } = this.props;
    // adding and passing the duration to the onChange function
    const durationKey = {
      filterGroupKey: "duration",
      name: duration,
    };

    onChange(dateValue, durationKey);
  };

  onSubmitZipSize = panel => {
    const zipCodeFilter = this.props.filters.zip_code;
    let zipSizeLoggingObj = {
      action: "click_next_fit_zip",
    };
    const hasValidZip = ZIP_REGEX.test(zipCodeFilter);

    if (zipCodeFilter) {
      if (hasValidZip) {
        zipSizeLoggingObj = {
          ...zipSizeLoggingObj,
          valid_zip: true,
        };

        // Don't log `enter_zipcode` if the user's zipcode hasn't changed. The zipcode
        // can already be set if they're going back and forth in the quiz.
        if (zipCodeFilter !== this.state.currentZip) {
          ActionLogger.logAction({
            ...this.getBaseLoggingObj(),
            zip: zipCodeFilter,
            action: "enter_zipcode",
          });
        }

        this.setState({
          errors: { "filters[zip_code]": null },
          currentZip: zipCodeFilter,
        });
      } else {
        zipSizeLoggingObj = {
          ...zipSizeLoggingObj,
          valid_zip: false,
        };

        this.setState({
          errors: { "filters[zip_code]": true },
        });
      }
    }

    ActionLogger.logAction({
      ...this.getBaseLoggingObj(),
      ...zipSizeLoggingObj,
    });

    if (zipCodeFilter && !hasValidZip) {
      return;
    }
    // Do not advance to the next panel if a user entered a zip, and it was invalid.
    // Empty zip codes are okay.
    this.loadNextPanel(panel.leadsTo, null);
  };

  onClickListItem = (optionContent, panelName, value) => {
    ActionLogger.logAction({
      ...this.getBaseLoggingObj(),
      action: `${panelName}_select`,
      value: value,
    });

    this.loadNextPanel(optionContent.leadsTo, optionContent.gridUrl);
  };

  onClickEventIcon = (optionContent, optionKey, event) => {
    event.preventDefault();

    ActionLogger.logAction({
      ...this.getBaseLoggingObj(),
      action: "event_select",
      value: optionKey,
    });

    this.eventDetails = {
      ...this.eventDetails,
      event: optionKey,
    };

    this.loadNextPanel(optionContent.leadsTo, optionContent.gridUrl);
    this.setState({
      numPanels: optionContent.numPanels,
    });
  };

  renderEventIconsPanel = panel => {
    return (
      <EventIconsPanel
        panel={panel}
        skipQuiz={this.skipQuiz}
        options={this.props.quizContent.options}
        onClickEventIcon={this.onClickEventIcon}
        skipToGridUrl={this.props.quizContent.skipToGridUrl}
      />
    );
  };

  renderListPanel = panel => {
    return (
      <ListPanel
        panel={panel}
        skipQuiz={this.skipQuiz}
        options={this.props.quizContent.options}
        onClick={this.onClickListItem}
        skipToGridUrl={this.props.quizContent.skipToGridUrl}
      />
    );
  };

  renderZipSizePanel = panel => {
    return (
      <ZipSizePanel
        panel={panel}
        errors={this.state.errors}
        onSubmitZipSize={this.onSubmitZipSize}
        idSuffix={this.props.idSuffix}
        filters={this.props.filters}
        onChangeZip={this.onChangeZip}
        onChangeSize={this.onChangeSize}
        onChangeMaternity={this.onChangeMaternity}
        zipFilterInput={this.constructor.filterInputs.zip_code}
        getCtaCopy={this.getCtaCopy}
      />
    );
  };

  renderDateSelectPanel = panel => {
    const datepickerLogging = {
      objectType: this.props.quizContent.loggingObjectType,
      zipCode: this.props.filters.zip_code || "",
      sizes: this.props.filters.canonicalSizes || [],
      maternity: this.props.filters.maternity || [],
    };

    return (
      <DateSelectPanel
        panel={panel}
        dateOptions={this.props.dateOptions}
        date={this.props.filters.date}
        onChange={this.onChangeDate}
        idSuffix={this.props.idSuffix}
        dateFilterInput={this.constructor.filterInputs.date}
        datepickerLogging={datepickerLogging}
        getEventGridUrl={this.getEventGridUrl}
        finishQuiz={this.finishQuiz}
      />
    );
  };

  renderMarketingContent = panel => {
    return <MarketingContentPanel panel={panel} loadNextPanel={this.loadNextPanel} />;
  };

  renderHeader() {
    return (
      <div className={styles["header"]}>
        {this.renderBackBtn()}
        <div>RTR</div>
      </div>
    );
  }

  renderBackBtn() {
    // If there's less than two panels, then there's nothing to go back to.
    if (this.state.panelKeys.length < 2) {
      return null;
    }

    return (
      <button className={styles["back-btn"]} onClick={this.loadPreviousPanel}>
        Back
      </button>
    );
  }

  renderProgressBar() {
    const panelKey = this.state.currentPanel;
    const {
      quizContent: { panels },
    } = this.props;
    const panel = panels[panelKey];
    const { type } = panel;

    if (type === "marketingContent") {
      return null;
    }
    // In the future, we might need a better way to determine the number of
    // panels. But, for now, since the panels can only fork at one point, let's
    // use the naive approach where the first event option determines the actual
    // number of the panels.

    const currentPanel = this.state.index + 1;
    const totalPanels = this.state.numPanels ? this.state.numPanels : this.state.maxPanels;
    const width = (currentPanel / totalPanels) * 100;
    const style = {
      width: width + "%",
    };

    return (
      <div className={styles["progress-bar-wrapper"]}>
        <div className={styles["progress-bar"]} style={style}></div>
      </div>
    );
  }

  render() {
    const { currentPanel } = this.state;

    return (
      <div className={styles["rent-my-style-quiz"]}>
        {this.renderHeader()}
        {this.renderProgressBar()}
        {this.renderCurrentPanel(currentPanel)}
      </div>
    );
  }
}

export default RentMyStyleQuiz;
