import React from "react";
import _ from "underscore";
class UnderscoreComponent extends React.Component {
  render() {
    var innerHTML;

    innerHTML = _.template(this.props.template_html)(this.props);

    return <div dangerouslySetInnerHTML={{ __html: innerHTML }} />;
  }
}

export default UnderscoreComponent;
