import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import RtrImage from "components/source/shared/rtr-image";
import styles from "./styles.module.scss";
import { marketingContentPanelPropType } from "../../../propTypes/rent-my-style-quiz-content-prop-type";

const MarketingContentPanel = ({ loadNextPanel, panel }) => {
  const isMobile = useSelector(state => state.browser?.isMobileViewport);

  const renderImage = () => {
    if (isMobile) {
      return null;
    }

    return <RtrImage src={panel.imageSrc} style={{ "--aspect-ratio": panel.mobileImageSrcAspectRatio }} />;
  };

  const renderMobileImage = () => {
    if (!isMobile) {
      return null;
    }

    return <RtrImage src={panel.mobileImageSrc} style={{ "--aspect-ratio": panel.imageSrcAspectRatio }} />;
  };

  const renderCopy = () => {
    const { header, dek, message, messageTwo } = panel;

    return (
      <div className={styles["header-copy"]}>
        <h3>{header}</h3>
        {dek && <h4>{dek}</h4>}
        {message && (
          <div>
            <p>{message}</p>
            {messageTwo && <p>{messageTwo}</p>}
          </div>
        )}
      </div>
    );
  };

  const renderCtas = () => {
    const { leadsTo, primaryCtaHref, primaryCtaText, secondaryCtaText } = panel;

    return (
      <div className={styles["cta-container"]}>
        <a className="btn" href={primaryCtaHref}>
          {primaryCtaText}
        </a>
        <button className={styles["secondary"]} onClick={() => loadNextPanel(leadsTo, null)}>
          {secondaryCtaText}
        </button>
      </div>
    );
  };

  return (
    <div className={styles["marketing-content"]}>
      {renderMobileImage()}
      <div className={styles["copy-container"]}>
        {renderCopy()}
        {renderCtas()}
      </div>
      {renderImage()}
    </div>
  );
};

MarketingContentPanel.propTypes = {
  loadNextPanel: PropTypes.func.isRequired,
  panel: marketingContentPanelPropType.isRequired,
};

export default MarketingContentPanel;
