import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { facebookShareMethods } from "rtr-constants";
import { browserPropType } from "components/propTypes";
import { withShareChannelBehavior } from "./with-share-channel-behavior";

export class FacebookShare extends Component {
  static propTypes = {
    browser: browserPropType,
    buttonClassNames: PropTypes.string,
    buttonCopy: PropTypes.string,
    onLogShare: PropTypes.func,
    onShareSuccess: PropTypes.func,
    shareMethod: PropTypes.string,
    shortenedUrl: PropTypes.string,
    updateShareStatus: PropTypes.func,
  };

  static defaultProps = {
    buttonCopy: "",
    hasSharedStyles: false,
    shareMethod: facebookShareMethods.SHARE,
    browser: { isMobileViewport: false },
  };

  getFacebookData() {
    const { shareMethod, shortenedUrl } = this.props;
    const facebookDataObj = { method: shareMethod };
    const linkObj = shareMethod === facebookShareMethods.SEND ? { link: shortenedUrl } : { href: shortenedUrl };

    return _.extend({}, facebookDataObj, linkObj);
  }

  handleShareFailure(response) {
    if (response) {
      this.props.onLogShare("unsuccessful_" + response.error_code);
    }

    if (this.props.updateShareStatus) {
      this.props.updateShareStatus(false);
    }

    return false;
  }

  mobileSendShare() {
    window.open(`fb-messenger://share?link=${encodeURIComponent(this.props.shortenedUrl)}`);
    this.props.onShareSuccess();
  }

  share = () => {
    if (this.props.browser.isMobileViewport && this.props.shareMethod === facebookShareMethods.SEND) {
      this.mobileSendShare();
    }

    window.FB.ui(this.getFacebookData(), response => {
      if (!response || response.error_code) {
        return this.handleShareFailure(response);
      }
      return this.props.onShareSuccess(response);
    });
  };

  render() {
    return (
      <button onClick={this.share} className={this.props.buttonClassNames}>
        {this.props.buttonCopy}
      </button>
    );
  }
}

export default withShareChannelBehavior({ type: "facebook" })(FacebookShare);
