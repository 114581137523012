import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import MoleculeIconWithText from "components/source/molecules/molecule-icon-with-text";
import RentMyStyleQuizCTA from "components/source/rent_my_style_quiz/rent-my-style-quiz-cta";
import { withLaunchRentMyStyleQuiz } from "components/source/rent_my_style_quiz/with-launch-rent-my-style-quiz";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";

export class MoleculeIconList extends React.Component {
  static propTypes = {
    analytics: PropTypes.object,
    backgroundColor: PropTypes.string,
    cmsUrl: PropTypes.string,
    dekAttributes: atomTextAttributesPropType,
    extraPixelData: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    hedText: PropTypes.string,
    isTiled: PropTypes.bool,
    listTitle: PropTypes.string,
    listDek: PropTypes.string,
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        href: PropTypes.string,
      })
    ).isRequired,
    launchRentMyStyleQuiz: PropTypes.func.isRequired,
    optionalLink: PropTypes.shape({
      linkCopy: PropTypes.string,
      linkHref: PropTypes.string,
    }),
    quizDek: PropTypes.string,
    quizCtaText: PropTypes.string,
    textClass: PropTypes.string,
  };

  static defaultProps = {
    listTitle: null,
    isTiled: false,
    backgroundColor: "background-one",
    optionalLink: {},
    textClass: "dek",
  };

  static className = "molecule-icon-list";
  static colors = ["background-one", "background-two", "background"];
  static headerType = "h2";

  baseClassWithColor() {
    let background = this.props.backgroundColor;

    if (!MoleculeIconList.colors.includes(background)) {
      background = MoleculeIconList.colors[0];
    }

    return classNames(MoleculeIconList.className, {
      [background]: background,
    });
  }

  baseClassWith(element) {
    return classNames(`${MoleculeIconList.className}${element}`, {
      [`${MoleculeIconList.className}--is-tiled${element}`]: this.props.isTiled,
    });
  }

  logTileClick = (tile, index) => {
    const text = tile?.label.toLowerCase().replace(" ", "_");

    const objectToLog = {
      object_type: this.props.analytics?.objectType ?? "molecule_icon_list",
      action: "click",
      component_type: this.props.cmsUrl,
      copy: text,
      nth_tile: index,
      ...this.props.extraPixelData,
    };

    ActionLogger.inferAction(objectToLog);
  };

  renderLinkedItem(item, index) {
    return (
      <li className={this.baseClassWith("__li")} key={index}>
        <a className={this.baseClassWith("__a")} href={item.href} onClick={() => this.logTileClick(item, index)}>
          <MoleculeIconWithText
            icon={item.icon}
            image={item.image}
            text={item.label}
            textClass={this.props.textClass}
          />
        </a>
      </li>
    );
  }

  renderItem(item, index) {
    return (
      <li className={this.baseClassWith("__li")} key={index} data-test-id="list-item">
        <MoleculeIconWithText icon={item.icon} image={item.image} text={item.label} textClass={this.props.textClass} />
      </li>
    );
  }

  renderList() {
    return (
      <ul className={this.baseClassWith("__ul")}>
        {this.props.listItems.map((item, index) => {
          if (item.href) {
            return this.renderLinkedItem(item, index);
          } else {
            return this.renderItem(item, index);
          }
        })}
      </ul>
    );
  }

  renderTitle() {
    const hedText = this.props.hedText ? this.props.hedText : this.props.listTitle;

    return <AtomHed text={hedText} type={this.constructor.headerType} hedAttributes={this.props.hedAttributes} />;
  }

  renderDek() {
    if (this.props.listDek) {
      return (
        <AtomDek text={this.props.listDek} mobileText={this.props.listDek} dekAttributes={this.props.dekAttributes} />
      );
    }
  }

  renderLinkBelowList() {
    if (this.props.optionalLink && Object.keys(this.props.optionalLink).length) {
      return (
        <a className={this.baseClassWith("__a") + " standalone"} href={this.props.optionalLink.linkHref}>
          {this.props.optionalLink.linkCopy}
        </a>
      );
    }
  }

  renderQuizCTA() {
    if (!this.props.quizCtaText) {
      return null;
    }

    return (
      <RentMyStyleQuizCTA>
        <p className={this.baseClassWith("__rent-my-style-quiz-cta")}>
          {this.props.quizDek}
          <button className="universal-underline universal-small--semibold" onClick={this.props.launchRentMyStyleQuiz}>
            {this.props.quizCtaText}
          </button>
        </p>
      </RentMyStyleQuizCTA>
    );
  }

  render() {
    return (
      <div className={this.baseClassWithColor()} data-test-id="molecule-icon-list">
        {this.renderTitle()}
        {this.renderDek()}
        {this.renderQuizCTA()}
        {this.renderList()}
        {this.renderLinkBelowList()}
      </div>
    );
  }
}

export default withLaunchRentMyStyleQuiz()(MoleculeIconList);
