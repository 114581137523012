import React from "react";
import PropTypes from "prop-types";
import InputWrapper from "./input-wrapper";

class TextInputPair extends React.Component {
  static propTypes = {
    inputProps: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    inputType: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    inline: PropTypes.bool,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  render() {
    var options = {};

    if (this.props.readOnly) {
      options.readOnly = "readOnly";
    }

    if (this.props.placeholder) {
      options.placeholder = this.props.placeholder;
    }

    return (
      <InputWrapper inline={this.props.inline} {...this.props}>
        <input
          {...this.props.inputProps}
          id={this.props.id}
          name={this.props.name}
          onChange={this.props.onChange}
          type={this.props.inputType || "text"}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          {...options}
        />
      </InputWrapper>
    );
  }
}

export default TextInputPair;
