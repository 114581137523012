import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getLocationPathname } from "helpers/location-helpers";
import { onEnterPress } from "helpers/a11y-helper";
import styles from "./molecule-grid-topper-with-toggles.module.scss";
import classnames from "classnames";

const MoleculeGridTopperWithTiles = ({ backgroundColor, color, description, options, pageTitle, subhead }) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState();

  useEffect(() => {
    const selected = options.findIndex(option => {
      const pathFromCms = option.href.split("?")[0];
      const currentPath = getLocationPathname();

      //Handling a common scenario where /shop/ rewrites to /pdp/shop
      if (pathFromCms.startsWith("/shop")) {
        return `/pdp${pathFromCms}` === currentPath;
      } else {
        return pathFromCms === currentPath;
      }
    });

    setSelectedOptionIndex(selected);
  }, []);

  const renderOptions = (option, index) => {
    const selectionClasses = classnames("label", {
      [styles["selected"]]: selectedOptionIndex === index,
      [styles["not-selected"]]: selectedOptionIndex !== index,
    });

    return (
      <div
        className={styles["selection"]}
        key={index}
        onClick={() => setSelectedOptionIndex(index)}
        onKeyPress={onEnterPress(() => setSelectedOptionIndex(index))}
        role="link"
        tabIndex={0}>
        <a href={option.href} className={selectionClasses}>
          {option.title}
        </a>
      </div>
    );
  };

  const renderSubhead = () => {
    if (!subhead) {
      return;
    }

    if (subhead.link) {
      return (
        <a href={subhead.link}>
          <div className="dek-one">{subhead.text}</div>
        </a>
      );
    }

    return <div className="h3">{subhead.text}</div>;
  };

  const renderDescription = () => {
    if (!description) {
      return;
    }

    return <div className="dek-one">{description}</div>;
  };

  const renderTitle = () => {
    const { link, text } = pageTitle;

    if (link) {
      return (
        <a href={link}>
          <h2 className={styles["title"]}>{text}</h2>
        </a>
      );
    }

    return <h2 className={styles["title"]}>{text}</h2>;
  };

  const style = {
    backgroundColor,
    color,
  };

  return (
    <div style={style} className={styles["social-topper-wrapper"]}>
      <div>
        <div className={styles["text-content"]}>
          {renderSubhead()}
          {renderTitle()}
          {renderDescription()}
        </div>
        <div className={styles["selection-container"]}>{options.map((o, i) => renderOptions(o, i))}</div>
      </div>
    </div>
  );
};

MoleculeGridTopperWithTiles.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  pageTitle: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
  subhead: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
};

export default MoleculeGridTopperWithTiles;
