import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomDek from "components/source/atoms/atom-dek";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import RtrImage from "../shared/rtr-image";

class MoleculeReviewsCarousel extends React.Component {
  static propTypes = {
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        imageURL: PropTypes.string,
        mobileImageURL: PropTypes.string,
        description: PropTypes.string,
        rating: PropTypes.number,
        dekText: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    logCarouselClickArrow: PropTypes.func,
  };

  static defaultProps = {
    logCarouselClickArrow: _.noop,
  };

  static baseClass = "molecule-reviews-carousel";

  renderDescription = reviewDescription => {
    if (!reviewDescription) {
      return;
    }

    return <div className={`${this.constructor.baseClass}__description`}>{reviewDescription}</div>;
  };

  renderName = reviewName => {
    if (!reviewName) {
      return;
    }

    return <div className={`${this.constructor.baseClass}__reviewer`}>– {reviewName}</div>;
  };

  renderReviews() {
    if (_.isEmpty(this.props.reviews)) {
      return [];
    }

    const ratingToStarConversion = 2;

    return _.map(this.props.reviews, (review, i) => {
      const rating = review.rating * ratingToStarConversion;
      return (
        <div className={`${this.constructor.baseClass}__review`} key={i}>
          <div className={`${this.constructor.baseClass}__review-img`}>
            <picture>
              <source srcSet={review.mobileImageURL} media="(max-width: 480px)" />
              <RtrImage src={review.imageURL} alt={review.name} />
            </picture>
          </div>
          {this.renderDescription(review.description)}
          <div className={`${this.constructor.baseClass}__review-stars reb-gold-stars-${rating}`}>{review.star}</div>
          <AtomDek
            text={review.dekText}
            mobileText={review.mobileDekText}
            customClass={`${this.constructor.baseClass}__review-quote`}
          />
          {this.renderName(review.name)}
        </div>
      );
    });
  }

  render() {
    return (
      <div className={this.constructor.baseClass}>
        <SwipeableCarousel
          mobilePageSize={1}
          displayCarouselProgressDots={true}
          wideWidthChildren={true}
          carousel="reviews-carousel"
          logClickArrow={this.props.logCarouselClickArrow}>
          {this.renderReviews()}
        </SwipeableCarousel>
      </div>
    );
  }
}

export default MoleculeReviewsCarousel;
