import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ActionLogger from "action-logger";
import { analytics, FetchPriority } from "rtr-constants";
import PropTypes from "prop-types";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import { atomHedAttributesPropType, visualNavItemPropType } from "../../propTypes";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import RtrImage from "../shared/rtr-image";
import MoleculeVideo from "./molecule-video-bucket/molecule-video";

const MoleculeVisualNav = ({
  backgroundColor,
  cardAlignment = "left",
  hedAttributes,
  hedText,
  isFullBleed,
  mobileHedAttributes,
  mobileHedText,
  products = [],
  visualNavSize,
  isTopVisualNav = false,
}) => {
  const path = useSelector(s => s.path);
  const isMobile = useSelector(s => s.browser?.isMobileViewport);

  useEffect(() => logPixel(analytics.ACTION_TYPE.VIEW), []);

  const renderHed = () => {
    if (!hedText) {
      return null;
    }

    return (
      <AtomHed
        text={hedText}
        hedAttributes={hedAttributes}
        mobileText={mobileHedText ?? hedText}
        mobileHedAttributes={mobileHedAttributes}
      />
    );
  };

  const renderProductCardAnnotation = product => {
    const {
      title,
      titleAttributes,
      mobileTitle,
      mobileTitleAttributes,
      subTitle,
      subTitleAttributes,
      mobileSubTitle,
      mobileSubTitleAttributes,
    } = product;

    if (!title) {
      return null;
    }

    const titleHtml = (
      <AtomDek
        customClass="product-tile__title"
        text={title}
        textAttributes={titleAttributes}
        mobileText={mobileTitle ?? title}
        mobileAttributes={mobileTitleAttributes}
      />
    );
    const subTitleHtml = subTitle && (
      <AtomDek
        customClass="product-tile__subtitle"
        text={subTitle}
        textAttributes={subTitleAttributes}
        mobileText={mobileSubTitle ?? subTitle}
        mobileAttributes={mobileSubTitleAttributes}
      />
    );

    return (
      <div className="visual-nav-image-name">
        {titleHtml}
        {subTitleHtml}
      </div>
    );
  };

  const renderImage = product => {
    return (
      <RtrImage
        alt={product.imageAlt}
        src={isMobile ? product.mobileImage : product.image}
        className="visual-nav-image"
        style={{
          "--mobile-aspect-ratio": product.mobileImageAspectRatio || "auto",
          "--desktop-aspect-ratio": product.imageAspectRatio || "auto",
        }}
        fetchPriority={isTopVisualNav ? FetchPriority.HIGH : FetchPriority.AUTO}
      />
    );
  };

  const renderVideo = product => {
    return (
      <MoleculeVideo
        mobileVideoSrcMp4={product.mobileVideoSrcMp4}
        mobileVideoSrcMp4Poster={product.mobileVideoSrcMp4Poster}
        mobileVideoSrcMp4PosterAspectRatio={product.mobileVideoSrcMp4PosterAspectRatio}
        mobileVideoSrcWebm={product.mobileVideoSrcWebm}
        mobileVideoSrcWebmPoster={product.mobileVideoSrcWebmPoster}
        mobileVideoSrcWebmPosterAspectRatio={product.mobileVideoSrcWebmPosterAspectRatio}
        videoSrcMp4={product.videoSrcMp4}
        videoSrcMp4Poster={product.videoSrcMp4Poster}
        videoSrcMp4PosterAspectRatio={product.videoSrcMp4PosterAspectRatio}
        videoSrcWebm={product.videoSrcWebm}
        videoSrcWebmPoster={product.videoSrcWebmPoster}
        videoSrcWebmPosterAspectRatio={product.videoSrcWebmPosterAspectRatio}
      />
    );
  };

  const renderProduct = (product, index) => {
    return (
      <a
        className="visual-nav-container"
        key={index}
        href={product.targetUrl}
        onClick={() => logPixel("quick_nav_tile_click", true, product, index)}>
        {product.videoSrcMp4 || product.videoSrcWebm ? renderVideo(product) : renderImage(product)}
        {renderProductCardAnnotation(product)}
      </a>
    );
  };

  const logSwipeToIndex = (index, pageSize) => {
    const tilesInView = products.slice(index, index + pageSize);
    const tileTitles = tilesInView.map(tiles => tiles.title);
    logPixel("quick_nav_scroll", false, tileTitles);
  };

  const logPixel = (action, infer, content, index) => {
    if (infer) {
      ActionLogger.inferAction({
        object_type: "quick_nav",
        action: action,
        grid_path: path,
        curation_link: content.targetUrl,
        title_copy: content.title,
        tile_position: index + 1,
      });
      return;
    }

    ActionLogger.logAction({
      object_type: "quick_nav",
      action: action,
      grid_path: path,
      tiles_in_view: content,
    });
  };

  const visualNavClassName = classNames("molecule-visual-nav", {
    "full-bleed": isFullBleed,
    "center-align": cardAlignment === "center",
  });

  const className = classNames("visual-nav", {
    "four-items": products?.length === 4 && !isMobile && !isFullBleed,
    [visualNavSize]: visualNavSize,
  });

  const styles = {
    backgroundColor,
  };

  if (!products.length) {
    return null;
  }

  return (
    <div className={visualNavClassName} style={styles}>
      {renderHed()}
      <SwipeableCarousel
        additionalClassName={className}
        displayCarouselProgressDots={false}
        wideWidthChildren={true}
        hideSideButtons={isMobile}
        scrollBySingleItem={true}
        isMobile={isMobile}
        logSwipeToIndex={logSwipeToIndex}
        useSmoothScroll={true}
        isFullBleedCarousel={isFullBleed}
        fullBleedCarouselButtonSelector={".visual-nav-image"}>
        {products?.map(renderProduct)}
      </SwipeableCarousel>
    </div>
  );
};

MoleculeVisualNav.propTypes = {
  backgroundColor: PropTypes.string, // Hex code background color
  /**
   * Specifying "center" will center justify the cards in full bleed visual nav when they don't fill the row.
   * Otherwise they will left-justify, leaving whitespace at the end of the row.
   */
  cardAlignment: PropTypes.oneOf(["left", "center"]),
  hedAttributes: atomHedAttributesPropType,
  hedText: PropTypes.string,
  isFullBleed: PropTypes.bool,
  mobileHedAttributes: atomHedAttributesPropType,
  mobileHedText: PropTypes.string,
  products: PropTypes.arrayOf(visualNavItemPropType),
  visualNavSize: PropTypes.oneOf(["small", "medium", "large"]),
  isTopVisualNav: PropTypes.bool,
};

export default MoleculeVisualNav;
