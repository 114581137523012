import React from "react";
import PropTypes from "prop-types";

import MoleculeImageCarousel from "components/source/molecules/molecule-image-carousel";
import ReferralSubscriptionFriendHeader from "components/source/referral/referral-subscription-friend-header";
import ReserveUpsellHeroUnit from "components/source/upsells/reserve-upsell-hero-unit";
import SkinnyBannerSuppression from "components/source/navigation/skinny-banner-suppression";
import MoleculePlans from "components/source/molecules/molecule-plans";
import MoleculePlansPagePromo from "components/source/molecules/molecule-plans-page-promo";
import AtomSpacer from "components/source/atoms/atom-spacer";
import ExtendableContentBuckets from "./extendable-content-buckets";
import NoopComponent from "./noop-component";
import CmsHtml from "./cms-html";
import ResponsiveScalableVariableImages from "./responsive-scalable-variable-images";
import FullBleedFiftyFifty from "./full_bleed_bucket/full-bleed-fifty-fifty";
import UnderscoreComponent from "./underscore-component";
import MoleculeGridTopperWithToggles from "components/source/molecules/molecule-grid-topper-with-toggles";
import LandingPageHeader from "./landing-page-header";
import GenericHeroUnit from "components/source/shared/generic-hero-unit";
import MoleculeAccordion from "components/source/molecules/molecule-accordion";
import MoleculeBradyBunch from "components/source/molecules/molecule-brady-bunch";
import MoleculeBulletList from "components/source/molecules/molecule-bullet-list";
import MoleculeCheckerboardBasic from "components/source/molecules/molecule-checkerboard-basic";
import MoleculeCheckerboardBasicYoutube from "components/source/molecules/molecule-checkerboard-basic-youtube";
import MoleculeGenericGridTopper from "components/source/molecules/molecule-generic-grid-topper";
import MoleculeImageContentBlock from "components/source/molecules/molecule_image_content_block";
import MoleculeTilesAcross from "components/source/molecules/molecule-tiles-across";
import MoleculeMadlibs from "components/source/molecules/molecule-madlibs";
import MoleculeReviewsCarousel from "components/source/molecules/molecule-reviews-carousel";
import MoleculeComparisonTable from "components/source/molecules/molecule-comparison-table";
import MoleculeStatsCircles from "components/source/molecules/molecule-stats-circles";
import MoleculeIconList from "components/source/molecules/molecule-icon-list";
import MoleculeAnnotatedCallToAction from "components/source/molecules/molecule-annotated-call-to-action";
import GridQueryCarousels from "../shared/carousels/grid-query-carousels";
import MoleculeInformationBoxes from "../molecules/molecule-information-boxes";
import MoleculeVisualNav from "components/source/molecules/molecule-visual-nav";
import MoleculeDualCtaHero from "components/source/molecules/molecule-dual-cta-hero";
import MoleculeRentalIntentGridTopper from "components/source/molecules/molecule-rental-intent-grid-topper";
import MoleculeMidGridEducationalMessage from "../molecules/molecule-mid-grid-educational-message";
import MoleculeVideo from "components/source/molecules/molecule-video-bucket/molecule-video";
import { isProduction } from "helpers/environment-helpers";
import { withQueryParams } from "../hoc/with-query-params";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

const Components = {
  AtomSpacer,
  CmsHtml,
  ExtendableContentBuckets,
  GenericHeroUnit,
  GridQueryCarousels,
  LandingPageHeader,
  MoleculeAccordion,
  MoleculeAnnotatedCallToAction,
  MoleculeBradyBunch,
  MoleculeBulletList,
  MoleculeCheckerboardBasic,
  MoleculeCheckerboardBasicYoutube,
  MoleculeComparisonTable,
  MoleculeDualCtaHero,
  MoleculeGenericGridTopper,
  MoleculeGridTopperWithToggles,
  MoleculeIconList,
  MoleculeImageCarousel,
  MoleculeImageContentBlock,
  MoleculeInformationBoxes,
  MoleculeMadlibs,
  MoleculeMidGridEducationalMessage,
  MoleculePlans,
  MoleculePlansPagePromo,
  MoleculeRentalIntentGridTopper,
  MoleculeReviewsCarousel,
  MoleculeStatsCircles,
  MoleculeTilesAcross,
  MoleculeVideo,
  MoleculeVisualNav,
  NoopComponent,
  ReferralSubscriptionFriendHeader,
  ReserveUpsellHeroUnit,
  ResponsiveScalableVariableImages,
  SkinnyBannerSuppression,
  /**
   * This component's primary use changed over time and it was renamed.
   * This exists for backwards compatibility.
   */
  SocialTopper: MoleculeGridTopperWithToggles,
  UnderscoreComponent,
  FullBleedFiftyFifty,
};

export class ContentModule extends React.Component {
  static propTypes = {
    attributes: PropTypes.object,
    cms_url: PropTypes.string.isRequired, // The cms_url is used by components for various pixel logging purposes. You must have this!
    component: PropTypes.string.isRequired, // The Component is the actual component from the list above that will be shown. Also required!
    fromCache: PropTypes.bool,
    globalProps: PropTypes.object,
    id: PropTypes.string,
    index: PropTypes.number, // Used for scrolling pixels so that we know how far down the component is in a long list of components
    queryParams: PropTypes.shape({
      [this.DEBUG_FLAG]: PropTypes.string,
    }),
    template: PropTypes.string, // NW [TODO] 8/21/23: is this always the same as cms_url and if so can it be removed?
    template_html: PropTypes.string, // NW [TODO] 8/21/23: deprecated?
    treatment: PropTypes.string,
  };

  static DEBUG_FLAG = "highlight_content_modules";

  componentDidMount() {
    if (this.cmsDebugFlagEnabled()) {
      this.setDebugBorderColor();
    }

    if (!Components[this.props.component]) {
      window.Sentry?.captureMessage(`Rendering error: Failed to render ${this.props.component}`);
    }

    this.scrollPixelLogger = createScrollIntoViewPixelLogger(this.elementRef, this.props.template, {
      index: this.props.index,
      component_type: this.props.cms_url,
    });
  }

  componentWillUnmount() {
    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  state = {};
  elementRef = React.createRef(null);

  isUnderscore = () => {
    return this.props.template_html;
  };

  moduleClassFactory = () => {
    if (this.isUnderscore()) {
      return Components.UnderscoreComponent;
    }

    return Components[this.props.component];
  };

  moduleClassName = () => {
    const templateName = this.props.template.split("_").join("-");

    return ["templatized-content", templateName, (this.props.attributes || {}).className || ""].join(" ");
  };

  renderCmsDebugInfo() {
    const cmsUrl = this.props.cms_url ? `CMS URL: /content-modules/${this.props.cms_url}.` : "";
    const componentName = this.props.component ? `React Component: ${this.props.component}.` : "";
    const cacheStatus = this.props.fromCache ? "From Cache" : "From CMS";
    const text = `${cmsUrl} ${componentName} ${cacheStatus}`;

    return <p>{text}</p>;
  }

  // Typically we render multiple content modules on a single page.
  // To keep them visually distinct in debug, use different colors from module to module.
  static counter = 0;
  static increment = () => this.counter++;
  setDebugBorderColor() {
    const colors = ["red", "blue", "green", "purple"];
    const index = ContentModule.increment() % (colors.length + 1);

    this.setState({ debugBorderColor: colors[index] });
  }

  renderModule() {
    const ComponentClass = this.moduleClassFactory(this.props);

    if (!ComponentClass) {
      return null;
    }

    // @todo: remove template_html.
    // it is a bad implementation for getting underscore to render in React

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <div id={this.props.id} className={this.moduleClassName()} ref={this.elementRef}>
        <ComponentClass
          {...this.props.attributes}
          {...this.props.globalProps}
          cmsUrl={this.props.cms_url}
          index={this.props.index}
          template_html={this.props.template_html}
        />
      </div>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  }

  /**
   * Render the component with a colored border and markup indicating its source in the CMS.
   */
  renderWithDebugWrapper() {
    const className = `dev-wrapper__${this.state.debugBorderColor}`;

    return (
      <div className={className}>
        {this.renderCmsDebugInfo()}
        {this.renderModule()}
      </div>
    );
  }

  cmsDebugFlagEnabled() {
    return this.props.queryParams?.[ContentModule.DEBUG_FLAG] === "true";
  }

  render() {
    if (!isProduction() && this.cmsDebugFlagEnabled()) {
      return this.renderWithDebugWrapper();
    }

    return this.renderModule();
  }
}

export default withQueryParams(ContentModule.DEBUG_FLAG)(ContentModule);
