import React from "react";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import MoleculeCheckerboard from "components/source/molecules/molecule-checkerboard";
import MoleculeCtaWithOptionalAuth from "components/source/molecules/molecule-cta-with-optional-auth";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";
import { authFormConstants } from "rtr-constants/auth";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import { connect } from "react-redux";

export class MoleculeCheckerboardBasic extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(["right", "left"]).isRequired,
    backgroundColor: PropTypes.string,
    cta: PropTypes.shape({
      /**
       * Version of the auth form to display if requiresAuthentication is true.
       */
      authFormStyle: PropTypes.shape({
        displayStyle: PropTypes.oneOf(Object.values(authFormConstants.DisplayStyle)),
        isFullScreen: PropTypes.bool,
      }),
      desktopAlignment: PropTypes.oneOf(["left", "center", "right"]),
      href: PropTypes.string,
      mobileAlignment: PropTypes.oneOf(["left", "center", "right"]),
      requiresAuthentication: PropTypes.bool,
      text: PropTypes.string,
    }),
    description: PropTypes.string.isRequired,
    descriptionAttributes: atomTextAttributesPropType,
    header: PropTypes.string.isRequired,
    headerAttributes: atomHedAttributesPropType,
    headerClassName: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    isMobileViewport: PropTypes.bool.isRequired,
    mobileDescription: PropTypes.string,
    mobileDescriptionAttributes: atomTextAttributesPropType,
    mobileHeader: PropTypes.string,
    mobileHeaderAttributes: atomHedAttributesPropType,
    mobileImage: PropTypes.string,
    videoSettings: PropTypes.shape({
      autoPlay: PropTypes.bool,
      controls: PropTypes.bool,
    }),
    videoSrcMp4: PropTypes.string,
  };

  renderCta() {
    if (!this.props.cta?.text) {
      return;
    }

    const {
      cta: {
        authFormStyle: { displayStyle, isFullScreen } = {},
        desktopAlignment,
        href,
        mobileAlignment,
        requiresAuthentication,
        text,
      } = {},
    } = this.props;

    const desktopAlignmentClass = desktopAlignment ? desktopAlignment : "left";
    const mobileAligmentClass = mobileAlignment ? `mobile-${mobileAlignment}` : `mobile-${desktopAlignmentClass}`;

    return (
      <div className={`cta-container ${desktopAlignmentClass} ${mobileAligmentClass}`}>
        <MoleculeCtaWithOptionalAuth
          authFormDisplayStyle={displayStyle}
          isFullScreenAuthModal={isFullScreen}
          ctaText={text}
          disabled={false}
          href={href}
          heapTriggeredBy={HEAP_AUTH_TRIGGER_TYPES.CLICK_CMS_CTA}
          requiresAuthentication={requiresAuthentication}
          className={"btn"}
        />
      </div>
    );
  }

  getImage() {
    return this.props.isMobileViewport && this.props.mobileImage ? this.props.mobileImage : this.props.image;
  }

  render() {
    return (
      <MoleculeCheckerboard
        image={this.getImage()}
        videoSrcMp4={this.props.videoSrcMp4}
        videoSettings={this.props.videoSettings}
        align={this.props.align}
        backgroundColor={this.props.backgroundColor}
        imageAlt={this.props.imageAlt}>
        <AtomHed
          text={this.props.header}
          hedAttributes={this.props.headerAttributes}
          mobileText={this.props.mobileHeader}
          mobileHedAttributes={this.props.mobileHeaderAttributes}
          customClass={this.props.headerClassName}
        />
        <AtomDek
          text={this.props.description}
          dekAttributes={this.props.descriptionAttributes}
          mobileText={this.props.mobileDescription}
          mobileDekAttributes={this.props.mobileDescriptionAttributes}
        />
        {this.renderCta()}
      </MoleculeCheckerboard>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMobileViewport: state.browser?.isMobileViewport,
  };
};

export default connect(mapStateToProps)(MoleculeCheckerboardBasic);
