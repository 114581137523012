import React from "react";
import PropTypes from "prop-types";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import ActionLogger from "action-logger";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import { atomTextAttributesPropType, browserPropType } from "components/propTypes";
import RtrImage from "../shared/rtr-image";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

const propTypes = {
  browser: browserPropType,
  dekAttributes: atomTextAttributesPropType,
  hedText: PropTypes.string,
  mobileHedText: PropTypes.string,
  dekText: PropTypes.string,
  hedAttributes: PropTypes.shape({
    tagType: PropTypes.string,
  }),
  mobileDekAttributes: PropTypes.object,
  mobileDekText: PropTypes.string,
  mobileHedAttributes: PropTypes.object,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string.isRequired,
      mobileImageURL: PropTypes.string.isRequired,
      link: PropTypes.string,
      hedText: PropTypes.string,
      dekText: PropTypes.string,
      linkText: PropTypes.string,
      backgroundHex: PropTypes.string,
    })
  ).isRequired,
  analytics: PropTypes.shape({
    objectType: PropTypes.string.isRequired,
    moduleType: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
  displayCarouselProgressDots: PropTypes.bool,
};

export default class MoleculeImageCarousel extends React.Component {
  elementRef = React.createRef(null);

  componentDidMount() {
    this.scrollPixelLogger = this.createPixelLogger();
  }

  componentWillUnmount() {
    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  basePixelData() {
    const { analytics } = this.props;

    return {
      object_type: analytics.objectType,
      module_type: analytics.moduleType,
      action: analytics.action,
    };
  }

  createPixelLogger = () => {
    const { analytics } = this.props;

    // Do not send a pixel if analytic data is not specified
    if (!analytics) {
      return null;
    }

    const images = this.props.images;
    const tiles = [];
    images.forEach(child => {
      tiles.push(child.link);
    });

    const pixelData = this.basePixelData();
    pixelData["action"] = "scroll";
    pixelData["tiles"] = JSON.stringify(tiles);

    return createScrollIntoViewPixelLogger(this.elementRef, "carousel", pixelData);
  };

  logImageClick(image, i) {
    const { analytics } = this.props;

    // Do not send a pixel if analytic data is not specified
    if (!analytics) {
      return null;
    }
    const pixelData = this.basePixelData();
    pixelData["url"] = image.link;
    pixelData["nth_tile"] = i;
    ActionLogger.inferAction(pixelData);
  }

  renderHed() {
    if (!this.props.hedText) {
      return null;
    }
    const tagType = this.props.hedAttributes?.tagType ?? "h1";

    return (
      <AtomHed
        type={tagType}
        text={this.props.hedText}
        hedAttributes={this.props.hedAttributes}
        mobileText={this.props.mobileHedText}
        mobileHedAttributes={this.props.mobileHedAttributes}
      />
    );
  }

  renderDekText() {
    if (!this.props.dekText) {
      return null;
    }

    return (
      <AtomDek
        text={this.props.dekText}
        dekAttributes={this.props.dekAttributes}
        mobileText={this.props.mobileDekText}
        mobileDekAttributes={this.props.mobileDekAttributes}
      />
    );
  }

  renderChildren() {
    return this.props.images.map((image, i) => {
      const imageURL = this.props.browser.isMobileViewport ? image.mobileImageURL : image.imageURL;
      const styles = { backgroundColor: image.backgroundHex };
      return (
        /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        <div
          className="molecule-image-carousel__child"
          style={styles}
          key={i}
          role="img"
          onClick={this.logImageClick.bind(this, image, i)}>
          <a href={image.link}>
            <RtrImage src={imageURL} />
            <AtomDek
              text={image.hedText}
              dekAttributes={image.hedAttributes}
              mobileText={image.mobileHedText}
              mobileDekAttributes={image.mobileHedAttributes}
            />
            <AtomDek
              text={image.dekText}
              dekAttributes={image.dekAttributes}
              mobileText={image.mobileDekText}
              mobileDekAttributes={image.mobileDekAttributes}
            />
            <div className="molecule-image-carousel__child-link">{image.linkText}</div>
          </a>
        </div>
      );
    });
  }

  render() {
    if (!this.props.images || !this.props.images.length) return null;

    return (
      <div className="molecule-image-carousel" ref={this.elementRef}>
        <div className="molecule-image-carousel__title">
          {this.renderHed()}
          {this.renderDekText()}
        </div>
        <SwipeableCarousel
          mobilePageSize={1}
          carousel={"molecule-image-carousel"}
          displayCarouselProgressDots={this.props.displayCarouselProgressDots}
          wideWidthChildren={true}>
          {this.renderChildren()}
        </SwipeableCarousel>
      </div>
    );
  }
}

MoleculeImageCarousel.propTypes = propTypes;
