import { connect } from "react-redux";
import { getDisplayName } from "components/source/hoc/utils";
import { RENT_MY_STYLE_QUIZ_MODAL_NAME } from "rtr-constants";
import SharedActions from "actions/shared-actions";
import SmartComponentActions from "actions/smart-component-actions";
import ActionLogger from "action-logger";

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withLaunchRentMyStyleQuiz() {
  const mapStateToProps = () => {
    return {};
  };

  const mapDispatchToProps = dispatch => {
    return {
      launchRentMyStyleQuiz: () => {
        ActionLogger.logAction({
          object_type: "quiz_cta",
          action: "click",
        });
        dispatch(SmartComponentActions.getRentMyStyleQuiz());
        dispatch(SharedActions.displayModal(RENT_MY_STYLE_QUIZ_MODAL_NAME));
      },
    };
  };

  return WrappedComponent => {
    const C = connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);

    C.displayName = `withLaunchRentMyStyleQuiz(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}
