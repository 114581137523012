import React from "react";
import PropTypes from "prop-types";

class AtomIcon extends React.Component {
  static propTypes = {
    iconName: PropTypes.string.isRequired,
  };

  static baseClass = "icon";
  static wrapperClass = "icon-wrapper";

  iconClassNames = () => {
    return [AtomIcon.baseClass, this.props.iconName].join(" ");
  };

  render() {
    return (
      <div className={AtomIcon.wrapperClass} data-test-id="icon">
        <div className={this.iconClassNames()} />
      </div>
    );
  }
}

export default AtomIcon;
