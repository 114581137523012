import React from "react";
import PropTypes from "prop-types";
import { browserPropType, atomTextAttributesPropType, atomHedAttributesPropType } from "components/propTypes";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import classNames from "classnames";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import MoleculeIconWithText from "components/source/molecules/molecule-icon-with-text";
import { addQueryParam } from "../../../helpers/location-helpers";
import RtrImage from "../shared/rtr-image";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

class MoleculeImageContentBlock extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    browser: browserPropType,
    customClass: PropTypes.string,
    dekHedAttributes: PropTypes.object,
    dekText: PropTypes.string,
    dekAttributes: atomTextAttributesPropType,
    dekTextAttributes: atomTextAttributesPropType,
    mobileDekText: PropTypes.string,
    mobileDekTextAttributes: atomTextAttributesPropType,
    mobileDekAttributes: atomTextAttributesPropType,
    halfWidthColumn: PropTypes.bool, // If true, make the content block width 50%
    hedAttributes: atomHedAttributesPropType,
    mobileHedAttributes: atomHedAttributesPropType,
    hedText: PropTypes.string,
    maxWidth: PropTypes.string,
    mobileHedText: PropTypes.string,
    imageType: PropTypes.string, // If "fullBleed", the image goes from edge to edge, if "leftAlign", the image aligns to the left of the text, defaults to centered above the text
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        dekHed: PropTypes.string,
        dekText: PropTypes.string,
        href: PropTypes.string,
        image: PropTypes.string,
        imageAlt: PropTypes.string,
        itemPixelObject: PropTypes.object, // an object containing analytics data specific to each list item
        retinaImage: PropTypes.string,
      })
    ).isRequired,
    listItemsImageOrientation: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    listWidth: PropTypes.string,
    listItemHeight: PropTypes.string,
    mobileListItemsImageOrientation: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    onClick: PropTypes.func,
    queryParam: PropTypes.string, // A query param that will be appended to all links
    scrollPixelObject: PropTypes.object, // an object containing analytics data to be logged when this content is scrolled into view
    scrollAction: PropTypes.func,
    shouldLogScroll: PropTypes.bool,
    textAlign: PropTypes.oneOf(["left", "right", "center"]), // default is center
    useMobileScrollCarousel: PropTypes.bool,
    useSmoothScrollMobileCarousel: PropTypes.bool, //Only applies if useMobileScrollCarousel is true
  };

  static className = "molecule-image-content-block";

  static defaultProps = {
    onClick: () => {},
  };

  elementRef = React.createRef(null);

  componentDidMount() {
    this.scrollPixelLogger = this.createPixelLogger();
  }

  componentWillUnmount() {
    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  createPixelLogger = () => {
    const { scrollPixelObject, shouldLogScroll, scrollAction } = this.props;

    if (!scrollPixelObject && !shouldLogScroll) {
      return null;
    }

    const pixelData = scrollPixelObject ?? {
      objectType: "scroll_read",
      action: scrollAction,
    };

    return createScrollIntoViewPixelLogger(this.elementRef, "scroll_read", pixelData);
  };

  getStyles = () => {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  };

  baseClassWith = element => {
    return [this.constructor.className, element].join("");
  };

  renderHed = () => {
    if (!this.props.hedText) {
      return;
    }

    return (
      <AtomHed
        text={this.props.hedText}
        customClass={this.baseClassWith("__atom-hed")}
        hedAttributes={this.props.hedAttributes}
        mobileText={this.props.mobileHedText}
        mobileHedAttributes={this.props.mobileHedAttributes}
      />
    );
  };

  renderDek = () => {
    if (!this.props.dekText) {
      return;
    }

    return (
      <AtomDek
        text={this.props.dekText}
        customClass={this.baseClassWith("__atom-dek")}
        dekAttributes={this.props.dekAttributes}
        mobileText={this.props.mobileDekText}
        mobileDekAttributes={this.props.mobileDekAttributes}
      />
    );
  };

  getListClass = () => {
    const numberOfItems = this.props?.listItems?.length ?? 0;
    return classNames(this.baseClassWith("__ul"), this.baseClassWith(`--${numberOfItems}`));
  };

  getListWidth = () => {
    const { listWidth, browser: { isMobileViewport } = {} } = this.props;
    if (!isMobileViewport && listWidth) {
      return { width: listWidth };
    }
  };

  renderItems = () => {
    const {
      browser: { isMobileViewport } = {},
      listItems,
      useMobileScrollCarousel,
      useSmoothScrollMobileCarousel,
    } = this.props;

    const items = listItems.map((li, index) => {
      return this.renderItem(li, index);
    });

    // If the screen is mobile and we want a swipeable layout instead of stackable, return a carousel.
    if (isMobileViewport && useMobileScrollCarousel) {
      return (
        <SwipeableCarousel
          displayCarouselProgressDots={true}
          hideSideButtons={true}
          mobilePageSize={1}
          useSmoothScroll={useSmoothScrollMobileCarousel}>
          {items}
        </SwipeableCarousel>
      );
    }

    return (
      <ul className={this.getListClass()} style={this.getListWidth()} data-test-id="molecule-list">
        {items}
      </ul>
    );
  };

  renderItem = (item, index) => {
    const itemIndex = "item-" + index;
    const itemStatus = item.image || item.retinaImage ? "image" : "no-image";
    const imageStatus = this.props.imageType ? this.props.imageType : "";
    const textStatus = this.props.textAlign ? this.props.textAlign : "center";
    const listClassName = classNames(this.baseClassWith(`__li ${itemStatus} text-align-${textStatus}`), {
      [imageStatus]: imageStatus,
      "no-bullets": item.listIcon,
      //Gross but CMS needs to be backwards compatible with existing entries
      //In some entries we suppress bullets but don't want to replace them with an image
      //This is typically done by passing a listIcon of " " (an empty string with length 1)
      "has-icon": item.listIcon && item.listIcon.length > 2,
    });

    return (
      <li
        className={listClassName}
        key={itemIndex}
        style={{ "margin-top": `${this.props.listItemHeight}` }}
        data-test-id="molecule-image-content-block-list-item">
        {this.renderItemInner(item)}
      </li>
    );
  };

  getImageMarkup = (image, retinaImage, mobileImage, imageAlt) => {
    const desktopImageClass = mobileImage ? "mq__tablet-and-desktop" : "";
    const imageMarkup = [];

    if (retinaImage) {
      imageMarkup.push(
        <RtrImage
          className={this.baseClassWith(`__retina-image ${desktopImageClass}`)}
          src={retinaImage}
          alt={imageAlt}
          key={`retina-${imageAlt}`}
        />
      );
    } else if (image) {
      imageMarkup.push(
        <RtrImage
          className={this.baseClassWith(`__image ${desktopImageClass}`)}
          src={image}
          alt={imageAlt}
          key={`standard-${imageAlt}`}
        />
      );
    }

    if (mobileImage) {
      imageMarkup.push(
        <RtrImage
          className={this.baseClassWith("__retina-image mq__mobile")}
          src={mobileImage}
          alt={imageAlt}
          key={`mobile-${imageAlt}`}
        />
      );
    }

    return imageMarkup;
  };

  // This allows us to wrap the inner content in an <a> tag if there is an href present
  renderItemInner = ({
    image,
    retinaImage,
    mobileImage,
    dekHed,
    dekText,
    mobileDekText,
    href,
    imageAlt,
    itemPixelObject,
    listIcon,
  }) => {
    const {
      browser: { isMobileViewport } = {},
      listItemsImageOrientation,
      mobileListItemsImageOrientation,
    } = this.props;
    const imageOrientation = isMobileViewport ? mobileListItemsImageOrientation : listItemsImageOrientation;
    const innerContent = [];
    const wrappingClass = classNames(this.baseClassWith("__inner"), {
      [`image-${imageOrientation}`]: imageOrientation,
    });

    innerContent.push(this.getImageMarkup(image, retinaImage, mobileImage, imageAlt));

    if (dekHed || dekText) {
      const textContent = [];

      if (dekHed) {
        textContent.push(<AtomHed text={dekHed} key="hed" type="h3" hedAttributes={this.props.dekHedAttributes} />);
      }

      if (listIcon && /\S/.test(listIcon)) {
        textContent.push(<MoleculeIconWithText icon={listIcon} image={listIcon} />);
      }

      if (dekText || mobileDekText) {
        textContent.push(
          <AtomDek
            text={dekText}
            dekAttributes={this.props.dekTextAttributes}
            key="dek"
            mobileText={mobileDekText}
            mobileDekAttributes={this.props.mobileDekTextAttributes}
            customClass="list-item"
          />
        );
      }

      innerContent.push(
        <div className={this.baseClassWith("__text")} key="text">
          {textContent}
        </div>
      );
    }

    if (href) {
      let hrefToUse = href;
      if (this.props.queryParam) {
        hrefToUse = addQueryParam(hrefToUse, this.props.queryParam);
      }

      const pixelCategory = dekHed || dekText || mobileDekText;
      const pixelOptions = {
        click_category: pixelCategory,
        ...itemPixelObject,
      };

      return (
        <a
          className={wrappingClass}
          href={hrefToUse}
          onClick={() => {
            this.props.onClick(pixelOptions);
          }}>
          {" "}
          {innerContent}{" "}
        </a>
      );
    }

    return <div className={wrappingClass}> {innerContent} </div>;
  };

  render() {
    const containerClasses = classNames(this.baseClassWith("__container"), {
      [this.baseClassWith(`--${this.props.customClass}`)]: this.props.customClass,
      [this.baseClassWith(`__container--${this.props.maxWidth}`)]: this.props.maxWidth,
      [this.baseClassWith("__container--half-width-column")]: this.props.halfWidthColumn,
    });

    return (
      <div
        className={containerClasses}
        style={this.getStyles()}
        ref={this.elementRef}
        data-test-id="molecule-image-content-block">
        {this.renderHed()}
        {this.renderDek()}
        {this.renderItems()}
      </div>
    );
  }
}

export default MoleculeImageContentBlock;
