import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomRotatingWord from "components/source/atoms/atom-rotating-word";

function MoleculeRotatingHedText(props) {
  const ROTATING_WORD_PLACEHOLDER = "{rotatingWord}";

  const getText = () => {
    const { hedText, isMobileViewport, mobileHedText } = props;
    return isMobileViewport ? mobileHedText : hedText;
  };

  const hasRotatingWordInHed = () => {
    return (getText() || "").includes(ROTATING_WORD_PLACEHOLDER);
  };

  if (hasRotatingWordInHed()) {
    const { rotatingWordOptions } = props;

    // This function splits the h1 text around a placeholder, {rotatingWord}.
    // If it is present, we use the two elements in the array to sandwich the
    // AtomRotatingWord component.
    const textParts = (getText() || "").split(ROTATING_WORD_PLACEHOLDER);

    if (textParts.length === 2) {
      return (
        <h1 className="hero hero__rotating-word">
          <span dangerouslySetInnerHTML={{ __html: textParts[0] }}></span>
          <AtomRotatingWord options={rotatingWordOptions} />
          <span dangerouslySetInnerHTML={{ __html: textParts[1] }}></span>
        </h1>
      );
    }
  }

  const { hedAttributes, hedText, mobileHedAttributes, mobileHedText } = props;

  return (
    <AtomHed
      text={hedText}
      mobileText={mobileHedText}
      hedAttributes={hedAttributes}
      mobileHedAttributes={mobileHedAttributes}
      type="h1"
    />
  );
}
MoleculeRotatingHedText.propTypes = {
  hedAttributes: PropTypes.object,
  hedText: PropTypes.string,
  isMobileViewport: PropTypes.bool,
  mobileHedAttributes: PropTypes.object,
  mobileHedText: PropTypes.string,
  rotatingWordOptions: PropTypes.arrayOf(PropTypes.string),
};

export default MoleculeRotatingHedText;
