import React from "react";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import { atomHedAttributesPropType, atomTextAttributesPropType, browserPropType } from "components/propTypes";

const commonShape = PropTypes.shape({
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  modifier: PropTypes.string,
  fontSize: PropTypes.string,
});

const rowHedObjectArray = PropTypes.arrayOf(commonShape);

const columnObjectArray = PropTypes.arrayOf(
  PropTypes.shape({
    columnListItems: PropTypes.arrayOf(commonShape),
  })
);

const propTypes = {
  backgroundColor: PropTypes.string,
  browser: browserPropType,
  columns: columnObjectArray.isRequired,
  columnsMobile: columnObjectArray.isRequired,
  dekAttributes: atomTextAttributesPropType,
  hedAttributes: atomHedAttributesPropType,
  hedDek: PropTypes.string,
  hedText: PropTypes.string,
  mobileDekAttributes: atomTextAttributesPropType,
  mobileHedAttributes: atomHedAttributesPropType,
  mobileHedDek: PropTypes.string,
  rowHed: rowHedObjectArray.isRequired,
  rowHedMobile: rowHedObjectArray.isRequired,
};

const baseClass = "molecule-comparison-table";

class MoleculeComparisonTable extends React.Component {
  baseClassWith(element) {
    return `${baseClass}${element}`;
  }

  getStyles() {
    return {
      backgroundColor: this.props.backgroundColor,
    };
  }

  getCellStyles(item) {
    const styles = {
      backgroundColor: item?.backgroundColor || "#FFFFFF",
      color: item?.fontColor || "#000000",
      textAlign: item?.modifier || "left",
      fontSize: item?.fontSize || "18",
    };

    return styles;
  }

  getHeaderStyles(item) {
    const styles = {
      backgroundColor: item?.backgroundColor || "#FFFFFF",
      color: item?.fontColor || "#000000",
      textAlign: item?.modifier || "left",
      fontSize: item?.fontSize || "18",
    };

    return styles;
  }

  headRow() {
    const {
      rowHed,
      rowHedMobile,
      browser: { isMobileViewport },
    } = this.props;

    const rowHead = isMobileViewport ? rowHedMobile : rowHed;
    return (
      <tr>
        <th></th>
        {rowHead.map((cell, i) => (
          <th key={i} className={cell.modifier} style={{ ...this.getHeaderStyles(cell) }}>
            {cell.text}
          </th>
        ))}
      </tr>
    );
  }

  contentRows() {
    const {
      columns,
      columnsMobile,
      browser: { isMobileViewport },
    } = this.props;

    const cols = isMobileViewport ? columnsMobile : columns;
    const rows = cols[0].columnListItems;

    return rows.map((row, i) => (
      <tr key={i}>
        {cols.map((col, j) => {
          const cellItem = col.columnListItems[i];
          const cellContent = cellItem.href ? (
            <a className="linkStyle" href={cellItem.href}>
              <div>{cellItem.text}</div>
            </a>
          ) : (
            <div>{cellItem.text}</div>
          );

          return (
            <td
              key={j}
              style={{
                ...this.getCellStyles(cellItem),
                cursor: cellItem.href ? "pointer" : "default",
              }}>
              {cellContent}
            </td>
          );
        })}
      </tr>
    ));
  }

  render() {
    const {
      hedText,
      mobileHedDek,
      hedDek,
      dekAttributes,
      mobileDekAttributes,
      hedAttributes,
      mobileHedAttributes,
    } = this.props;

    return (
      <div style={this.getStyles()}>
        <AtomHed text={hedText} type="h2" hedAttributes={hedAttributes} mobileHedAttributes={mobileHedAttributes} />
        <AtomDek
          text={hedDek}
          mobileText={mobileHedDek}
          dekAttributes={dekAttributes}
          mobileDekAttributes={mobileDekAttributes}
          customClass={this.baseClassWith("__dek")}
        />

        <table>
          {this.headRow()}
          {this.contentRows()}
        </table>
      </div>
    );
  }
}

MoleculeComparisonTable.propTypes = propTypes;

export default MoleculeComparisonTable;
