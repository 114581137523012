import PropTypes from "prop-types";
import { membershipLens, RENTAL_INTENT } from "rtr-constants";

const ctaPropType = PropTypes.shape({
  /**
   * Primary - styled as a button element
   * Secondary - styled as clickable text
   */
  type: PropTypes.oneOf(["primary", "secondary"]),
  hed: PropTypes.string.isRequired,
  dek: PropTypes.string,
  /**
   * Styling for primary CTAs
   */
  style: PropTypes.oneOf(["black", "transparent"]),
  /**
   * Clicking will navigate to this URL.
   */
  url: PropTypes.string,
  /**
   * Clicking will update the current lens and reload the grid.
   */
  updatedLens: PropTypes.oneOf(Object.values(membershipLens)),
});

const intentPropType = PropTypes.shape({
  condensed: PropTypes.bool,
  hedText: PropTypes.string.isRequired,
  dekText: PropTypes.string,
  ctaOne: ctaPropType.isRequired,
  ctaTwo: ctaPropType,
  align: PropTypes.oneOf(["center", "left", "right"]),
  imageSrc: PropTypes.string,
  mobileImageSrc: PropTypes.string,
  mobileVerticalPosition: PropTypes.oneOf(["top", "center", "bottom"]),
});

export const moleculeRentalIntentGridTopperPropType = {
  [RENTAL_INTENT.MEMBERSHIP]: intentPropType.isRequired,
  [RENTAL_INTENT.RESERVE]: intentPropType.isRequired,
  [RENTAL_INTENT.NO_INTENT]: intentPropType.isRequired,
};
