import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { withShareChannelBehavior } from "./with-share-channel-behavior";
import { stringify } from "qs";

export class PinterestShare extends Component {
  static propTypes = {
    buttonClassNames: PropTypes.string,
    buttonCopy: PropTypes.string,
    canonicalUrl: PropTypes.string,
    description: PropTypes.string,
    media: PropTypes.string,
    shortenedUrl: PropTypes.string,
    style: PropTypes.oneOf(["icon", "link", "button"]),
  };

  static defaultProps = {
    hasSharedStyles: false,
  };

  state = {
    shareUrl: "https://www.pinterest.com/pin/create/button/?",
  };

  componentDidMount() {
    // unlike other share channels, Pinterest puts this info into the
    // dom element, so to allow for server-side rendering, checking URL
    // parameters must happen here. (other share channels do this onclick)
    const params = {
      media: this.props.media,
      description: this.props.description,
      url: this.props.shortenedUrl,
    };

    // componentDidMount only gets called once, right?
    this.setState({
      shareUrl: this.state.shareUrl + stringify(_.pick(params, _.identity)),
    });
  }

  openModal = () => {
    this.logShare("pin_pending");
  };

  render() {
    let copy;

    if (this.props.style === "button") {
      copy = this.props.buttonCopy;
    }

    return (
      <a
        data-pin-custom="true"
        data-pin-do="buttonPin"
        data-pin-url={this.props.canonicalUrl}
        data-pin-media={this.props.media}
        data-pin-description={this.props.description}
        href={this.state.shareUrl}
        onClick={this.openModal}
        target="_blank"
        rel="noopener noreferrer">
        <i className={this.props.buttonClassNames} />
        {copy}
      </a>
    );
  }
}

export default withShareChannelBehavior({ type: "pinterest" })(PinterestShare);
