import React from "react";
import { emitDeprecationEvent } from "helpers/deprecation-alert";

import ResponsiveScalableImage from "./responsive-scalable-image.jsx";

class ResponsiveScalableVariableImages extends React.Component {
  componentDidMount() {
    emitDeprecationEvent("ResponsiveScalableVariableImages");
  }

  getImages = () => {
    let key;

    if (this.props.urlParams) {
      key = this.props.urlParams[this.props.keyParam];
    }

    if (!(key in this.props.imageSets)) {
      return this.props.imageSets[this.props.defaultValue];
    }

    return this.props.imageSets[key];
  };

  render() {
    return <ResponsiveScalableImage {...this.props} images={this.getImages()} />;
  }
}

export default ResponsiveScalableVariableImages;
