import React from "react";
class CmsHtml extends React.Component {
  // still rendered as of 2021-06-14
  // componentDidMount() {
  //   emitDeprecationEvent("CmsHtml");
  // }

  render() {
    return <div className="cms-content" dangerouslySetInnerHTML={{ __html: this.props.content }} />;
  }
}

export default CmsHtml;
