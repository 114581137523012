import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";
import classNames from "classnames";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

const propTypes = {
  dekHedAttributes: atomHedAttributesPropType,
  dekTextAttributes: atomTextAttributesPropType,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      hedText: PropTypes.string,
      dekText: PropTypes.string,
      href: PropTypes.string,
      itemPixelObject: PropTypes.object, // an object containing analytics data specific to each list item
      backgroundColor: PropTypes.string,
      ctaText: PropTypes.string,
      btnId: PropTypes.string,
    })
  ).isRequired,
  listItemHeight: PropTypes.string,
  scrollPixelObject: PropTypes.object, // an object containing analytics data to be logged when this content is scrolled into view
  scrollAction: PropTypes.func,
  shouldLogScroll: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "right", "center"]), // default is center
};

const MoleculeTilesAcross = ({
  dekHedAttributes,
  dekTextAttributes,
  listItems,
  listItemHeight,
  scrollPixelObject,
  scrollAction,
  shouldLogScroll,
  textAlign,
}) => {
  const className = "molecule-tiles-across";
  const elementRef = useRef(null);

  useEffect(() => {
    let scrollPixelLogger = null;

    if (!scrollPixelObject && !shouldLogScroll) {
      return;
    }

    const pixelData = scrollPixelObject ?? {
      objectType: "scroll_read",
      action: scrollAction,
    };

    scrollPixelLogger = createScrollIntoViewPixelLogger(elementRef, "scroll_read", pixelData);

    return () => {
      if (scrollPixelLogger) {
        scrollPixelLogger.disconnect();
      }
    };
  }, []);

  function getStyles(item) {
    const styles = {};

    if (item.backgroundColor) {
      styles.backgroundColor = item.backgroundColor;
    }
    return styles;
  }

  function baseClassWith(element) {
    return [className, element].join("");
  }

  function getListClass() {
    const numberOfItems = listItems?.length ?? 0;
    return classNames(baseClassWith("__ul"), baseClassWith(`--${numberOfItems}`));
  }

  function renderItems() {
    const items = listItems?.map((li, index) => {
      return renderItem(li, index);
    });

    return <ul className={getListClass()}>{items}</ul>;
  }

  function renderItem(item, index) {
    const itemIndex = "item-" + index;
    const textStatus = textAlign ? textAlign : "left";
    const listClassName = classNames(baseClassWith("__li"), `text-align-${textStatus}`);

    return (
      <li
        className={listClassName}
        key={itemIndex}
        style={
          ({
            "margin-top": `${listItemHeight}`,
          },
          getStyles(item))
        }>
        {renderItemInner(item)}
      </li>
    );
  }

  function renderItemInner(item) {
    const { hedText, dekText, mobileDekText, ctaText, href, btnId } = item;
    const innerContent = [];
    const wrappingClass = baseClassWith("__inner");
    if (hedText || dekText) {
      const textContent = [];

      if (hedText) {
        textContent.push(
          <AtomHed text={hedText} key="hed" type="h3" hedAttributes={dekHedAttributes} customClass="list-head-item" />
        );
      }

      if (dekText) {
        textContent.push(
          <AtomDek
            text={dekText}
            dekAttributes={dekTextAttributes}
            key="dek"
            mobileText={mobileDekText}
            customClass="list-item"
          />
        );
      }

      innerContent.push(
        <div className={baseClassWith("__content")} key="content">
          <div className={baseClassWith("__text")}>{textContent}</div>
          <div className={baseClassWith("__btn-div")}>
            <a className={baseClassWith("__btn")} href={href} data-heap-id={{ btnId }}>
              {ctaText}
            </a>
          </div>
        </div>
      );
    }

    return <div className={wrappingClass}> {innerContent} </div>;
  }

  const containerClasses = classNames(baseClassWith("__container"), {
    [baseClassWith(`--${propTypes.customClass}`)]: propTypes.customClass,
  });

  return (
    <div className={containerClasses} ref={elementRef}>
      {renderItems()}
    </div>
  );
};

MoleculeTilesAcross.propTypes = propTypes;

export default MoleculeTilesAcross;
