import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { browserPropType } from "components/propTypes";
import styles from "./atom-spacer.module.scss";

class AtomSpacer extends React.Component {
  static propTypes = {
    backgroundHex: PropTypes.string,
    borderColor: PropTypes.string,
    borderLine: PropTypes.string,
    borderLineStrength: PropTypes.string,
    browser: browserPropType,
    mobileOnly: PropTypes.bool,
    mobileSpaceHeight: PropTypes.string,
    mobileSpaceMaxWidth: PropTypes.string,
    spaceHeight: PropTypes.string,
    spaceMaxWidth: PropTypes.string,
  };

  static className = "atom-spacer";
  static bottomBorder = "bottom";
  static topBorder = "top";

  getStyles = () => {
    const styles = {};
    if (this.props.backgroundHex) {
      styles.backgroundColor = this.props.backgroundHex;
    }
    if (this.props.borderLine === this.constructor.bottomBorder) {
      styles.borderBottom = "solid " + this.props.borderLineStrength + " " + this.props.borderColor;
    } else if (this.props.borderLine === this.constructor.topBorder) {
      styles.borderTop = "solid " + this.props.borderLineStrength + " " + this.props.borderColor;
    }
    // Default is 40px (desktop) and 30px (mobile)
    if (!_.isEmpty(this.props.spaceHeight) && !this.props.browser.isMobileViewport) {
      styles.height = this.props.spaceHeight;
    } else if (!_.isEmpty(this.props.mobileSpaceHeight) && this.props.browser.isMobileViewport) {
      styles.height = this.props.mobileSpaceHeight;
    }

    if (!_.isEmpty(this.props.spaceMaxWidth) && !this.props.browser.isMobileViewport) {
      styles.maxWidth = this.props.spaceMaxWidth;
    } else if (!_.isEmpty(this.props.mobileSpaceMaxWidth) && this.props.browser.isMobileViewport) {
      styles.maxWidth = this.props.mobileSpaceMaxWidth;
    }

    return styles;
  };

  baseClassWith = element => {
    return [this.constructor.className, element].join("");
  };

  render() {
    if (this.props.mobileOnly && !this.props.browser.isMobileViewport) {
      return null;
    }
    return <div className={styles["atom-spacer__container"]} data-test-id="atom-spacer" style={this.getStyles()}></div>;
  }
}

export default AtomSpacer;
