import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { browserPropType } from "components/propTypes";
import AtomUnorderedList from "components/source/atoms/atom-unordered-list";
import RtrImage from "../shared/rtr-image";

const bradyBunchShape = PropTypes.shape({
  totalSpinningImages: PropTypes.number,
  animationType: PropTypes.oneOf(["fade", "spin"]),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          type: "single",
          imageUrl: PropTypes.string,
          imageAlt: PropTypes.string,
        }),
        PropTypes.shape({
          type: "multiple",
          images: PropTypes.arrayOf(
            PropTypes.shape({
              imageUrl: PropTypes.string,
              imageAlt: PropTypes.string,
            })
          ),
        }),
      ])
    )
  ),
  bulletListIcon: PropTypes.string,
  bulletListItems: PropTypes.arrayOf({
    text: PropTypes.string,
  }),
  bulletListOrientation: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  bulletListTextAttributes: PropTypes.object,
});

class MoleculeBradyBunch extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    browser: browserPropType.isRequired,
    desktop: bradyBunchShape,
    extraClasses: PropTypes.string,
    mobile: bradyBunchShape,
  };

  renderFlippingImage(flippingImage) {
    if (!flippingImage) {
      return;
    }
    const { browser: { isMobileViewport } = {} } = this.props;
    const propsToUse = isMobileViewport ? this.props.mobile : this.props.desktop;
    const animationTypeClass = propsToUse.animationType === "fade" ? "fade" : "flip";
    const { totalSpinningImages } = propsToUse;
    const numFaces = flippingImage.images.length;

    const loopTime = totalSpinningImages * 1.5;
    const startAt = (flippingImage.order - 1) * 1.5;
    return (
      <div
        data-test-id="brady-bunch-image-wrap"
        className={`brady-bunch__image-wrap brady-bunch__image-wrap--${flippingImage.order}`}>
        <div className={`brady-bunch__cube brady-bunch__cube--${flippingImage.images.length}`}>
          {flippingImage.images?.map((image, i) => {
            const style = {
              backgroundImage: `url('${image.imageUrl}')`,
              animationDelay: `-${i * loopTime + startAt}s`,
              animationDuration: `${loopTime * numFaces}s`,
              animationIterationCount: "infinite",
              animationTimingFunction: animationTypeClass === "fade" ? "ease-in" : "linear",
              animationName: `${animationTypeClass}-${numFaces}`,
            };
            return (
              <div
                key={i}
                data-test-id={image.imageAlt}
                style={style}
                alt={image.imageAlt}
                className={`brady-bunch__face brady-bunch__face--${i}`}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderRow(row, index) {
    return (
      <div data-test-id="brady-bunch-row" className={`brady-bunch__row brady-bunch__row--${index}`}>
        {row?.map(image => {
          if (image.type === "multiple") {
            return this.renderFlippingImage(image);
          }

          return (
            <div key={image.imageUrl} data-test-id="brady-bunch-image-wrap" className="brady-bunch__image-wrap">
              <RtrImage className="static" alt={image.imageAlt} src={image.imageUrl} />
            </div>
          );
        })}
      </div>
    );
  }

  renderRows(rows) {
    return rows?.map((row, index) => {
      return this.renderRow(row, index);
    });
  }

  renderBulletList(bradyBunchContent) {
    const { bulletListIcon, bulletListItems = [], bulletListTextAttributes } = bradyBunchContent;

    if (!bulletListItems.length) {
      return null;
    }

    return (
      <AtomUnorderedList
        bulletIcon={bulletListIcon}
        listItems={bulletListItems}
        listItemAttributes={bulletListTextAttributes}
      />
    );
  }

  render() {
    const { browser: { isMobileViewport } = {} } = this.props;
    const propsToUse = isMobileViewport ? this.props.mobile : this.props.desktop;
    const animationTypeClass = propsToUse.animationType === "fade" ? "brady-bunch--fade" : "brady-bunch--spin";
    const extraClasses = this.props?.extraClasses ?? "";
    const backgroundColor = this.props?.backgroundColor ?? "";
    const style = { backgroundColor };

    const wrapperClassName = classNames("brady-bunch-wrapper", {
      [`${extraClasses}`]: extraClasses,
      [`bullet-list-${propsToUse.bulletListOrientation}`]: propsToUse.bulletListOrientation,
    });

    return (
      <div className={wrapperClassName} style={style}>
        {this.renderBulletList(propsToUse)}
        <div className={`brady-bunch brady-bunch--total-${propsToUse.totalSpinningImages} ${animationTypeClass}`}>
          {this.renderRows(propsToUse.rows)}
        </div>
      </div>
    );
  }
}

export default MoleculeBradyBunch;
