import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { browserPropType } from "components/propTypes";
import EmailShare from "./email-share";
import EmailLinkShare from "./email-link-share";
import FacebookShare from "./facebook-share";
import PublicLinkShare from "./public-link-share";
import PinterestShare from "./pinterest-share";
import SmsShare from "./sms-share";
import TwitterShare from "./twitter-share";
const Components = {
  EmailShare,
  EmailLinkShare,
  FacebookShare,
  FacebookMsgShare: FacebookShare,
  PublicLinkShare,
  PinterestShare,
  SmsShare,
  TwitterShare,
};

class ShareBlock extends React.Component {
  static propTypes = {
    browser: browserPropType,
    buttonStyle: PropTypes.oneOf(["icons", "buttons"]),
    channels: PropTypes.arrayOf(
      PropTypes.shape(Components.EmailShare.propTypesShape) // Any of the Share Components have this defined.
    ).isRequired,
    updateShareStatus: PropTypes.func,
  };

  static defaultProps = {
    buttonStyle: "icons",
    updateShareStatus: null,
  };

  channelName = word => {
    if (!word.length) {
      return;
    }

    return word[0].toUpperCase() + word.slice(1) + "Share";
  };

  className = () => {
    return this.props.buttonStyle + " share-block";
  };

  render() {
    return (
      <div className={this.className()}>
        {_.map(
          this.props.channels,
          function (channel, i) {
            const ComponentClass = Components[this.channelName(channel.type)];

            if (!ComponentClass) {
              return;
            }

            return (
              <ComponentClass
                browser={this.props.browser}
                key={i}
                updateShareStatus={this.props.updateShareStatus}
                {...channel}
              />
            );
          },
          this
        )}
      </div>
    );
  }
}

export default ShareBlock;
