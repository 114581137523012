import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ClosableModal from "../shared/closable-modal";

import { withShareChannelBehavior } from "./with-share-channel-behavior";
import CopyField from "../shared/copy-field";

export class PublicLinkShare extends Component {
  static propTypes = {
    buttonClassNames: PropTypes.string,
    buttonCopy: PropTypes.string,
    buttonDisabled: PropTypes.bool.isRequired,
    linkWrapperClassName: PropTypes.string,
    modalShare: PropTypes.bool,
    onLogShare: PropTypes.func, // provided by withShareChannelBehavior
    onShareSuccess: PropTypes.func,
    shortenedUrl: PropTypes.string,
  };

  static defaultProps = {
    hasSharedStyles: false,
    modalShare: true,
  };

  state = {
    modalOpen: false,
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });

    this.props.onLogShare("modal_closed");
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });

    this.props.onLogShare("modal_opened");
  };

  renderDialog() {
    return (
      <ClosableModal isOpen={this.state.modalOpen} onRequestClose={this.closeModal} optionalClass="public-link-modal">
        <p className="dek-one public-link-modal__title">Here&#39;s Your Personal Link</p>

        <p className="body-copy">
          You can copy and share it with your friends via text message, Instagram, or on your blog.
        </p>

        <CopyField value={this.props.shortenedUrl} onCopySuccess={this.onShareSuccess} />
      </ClosableModal>
    );
  }

  copyLink = () => {
    this.$linkRef.select();

    if (document.execCommand("copy")) {
      this.props.onShareSuccess();
    }
  };

  renderLinkAndCta() {
    return (
      <div className="share-channel publicLink-button no-modal" onClick={this.copyLink}>
        <div className={classNames("copy-link-wrapper", this.props.linkWrapperClassName)}>
          <input
            ref={ref => {
              this.$linkRef = ref;
            }}
            type="text"
            className="copy-link copy-text-field"
            value={this.props.shortenedUrl}
            readOnly={true}
            aria-label="Copy and share this link"
          />
        </div>
        <button className="btn btn-secondary" disabled={this.props.buttonDisabled}>
          {this.props.buttonCopy}
        </button>
      </div>
    );
  }

  render() {
    if (!this.props.modalShare) {
      return this.renderLinkAndCta();
    }

    let dialog;

    if (this.state.modalOpen) {
      dialog = this.renderDialog();
    }

    return (
      // We're using a fragment so the dialog modal can be rendered outside the button.
      // Even with React portals if you render a modal inside a button, clicks will
      //   bubble up if you don't preventDefault() or stopPropagation().  Instead
      //   we just render the dialog outside of the button using a fragment.
      <>
        <button onClick={this.openModal} className={this.props.buttonClassNames}>
          {this.props.buttonCopy}
        </button>
        {dialog}
      </>
    );
  }
}

export default withShareChannelBehavior({ type: "publicLink" })(PublicLinkShare);
