import React from "react";
import AtomMadlibsDropdown from "components/source/atoms/atom-madlibs-dropdown";
import PropTypes from "prop-types";
import ClosableModal from "components/source/shared/closable-modal";
import { emitDeprecationEvent } from "helpers/deprecation-alert";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { format as formatDate } from "date-fns";

import ActionLogger from "action-logger";
import ReservationCalendar from "components/source/shared/calendars/reservation-calendar";
import objectToQueryString from "modules/object-to-query-string";
import constants from "rtr-constants";

const { madlibsOptions } = constants;

const propTypes = {
  cmsUrl: PropTypes.string,
  customClass: PropTypes.string,
  dateOptions: PropTypes.shape({
    dateStart: PropTypes.string.isRequired,
    dateEnd: PropTypes.string.isRequired,
    blackoutDays: PropTypes.array,
  }).isRequired,
  deliveryCutoffs: PropTypes.array,
};

class MoleculeMadlibsForm extends React.Component {
  state = {
    datepickerOpen: false,
    openDropdown: "",
    timeOfDay: {
      value: "",
      error: false,
    },
    date: "",
    formattedDate: "",
    companion: {
      value: "",
      error: false,
    },
    style: {
      value: "",
      error: false,
    },
    emotion: {
      value: "",
      error: false,
    },
  };

  componentDidMount() {
    emitDeprecationEvent("MoleculeMadlibsForm");
  }

  baseClassWith(element) {
    return ["molecule-madlibs-form", element].join("");
  }

  logAction(action, name, value) {
    const actionObject = {
      object_type: "molecule_page",
      component_type: this.props.cmsUrl,
      action: action,
    };

    if (name) {
      actionObject["name"] = name;
    }

    if (value) {
      actionObject["value"] = value;
    }

    ActionLogger.logAction(actionObject);
  }

  logSuccessfulSubmit() {
    const selectedValues = {
      timeOfDay: this.state.timeOfDay.value,
      companion: this.state.companion.value,
      emotion: this.state.emotion.value,
      style: this.state.style.value,
    };

    if (this.state.date) {
      selectedValues["date"] = this.state.date;
    }

    const actionObject = {
      object_type: "molecule_page",
      action: "find_my_look",
      component_type: this.props.cmsUrl,
      name: selectedValues,
    };

    ActionLogger.inferAction(actionObject);
  }

  onChangeSelection(value, key) {
    this.logAction("submit_value", key, value);
    this.setState({ [key]: { value: value }, openDropdown: "" });
  }

  // This controls which dropdown is open. The logic is contained here so that only one
  // dropdown can be open at any one time.
  onOpenDropdown(nameOfDropdown) {
    // If we're clicking on the input of an open dropdown, we probably want to
    // close that input. This sets up that logic.
    if (this.state.openDropdown === nameOfDropdown) {
      this.setState({ openDropdown: "" });
    } else {
      this.setState({ openDropdown: nameOfDropdown });
    }
  }

  renderGenericMadLibsDropdown(optionsArray, key) {
    // Renders an animated dropdown that renders all the options from the options array that
    // is passed in. Also takes a key which sets the appropriate state.
    return (
      <AtomMadlibsDropdown
        additionalClassName={this.baseClassWith(`__${key}`)}
        error={this.state[key]["error"]}
        onChangeCallback={value => {
          this.onChangeSelection(value, key);
        }}
        options={optionsArray}
        id={key}
        isOpen={this.state.openDropdown === key}
        key={key}
        label={`[${madlibsOptions.optionTitles[key]}]`}
        name={key}
        onOpenDropdown={nameOfDropdown => {
          this.onOpenDropdown(nameOfDropdown);
        }}
      />
    );
  }

  onDestinationSelection(value) {
    // The destination selection only cares about whether it's a daytime or nighttime destination
    const dayTimeObject = madlibsOptions.destinationDaytime;

    const timeOfDay = dayTimeObject.some(el => el.value === value) ? "day" : "night";

    this.logAction("submit_value", "timeOfDay", value);
    this.setState({ timeOfDay: { value: timeOfDay }, openDropdown: "" });
  }

  renderDestinationDropdown() {
    const key = "timeOfDay";
    // There are two types of destination: daytime and nighttime. We merge the two objects for our dropdown.
    const destinationsMerged = [...madlibsOptions.destinationDaytime, ...madlibsOptions.destinationNighttime];
    return (
      <AtomMadlibsDropdown
        additionalClassName={this.baseClassWith("__destination")}
        error={this.state.timeOfDay.error}
        isOpen={this.state.openDropdown === key}
        label={`[${madlibsOptions.optionTitles.timeOfDay}]`}
        name={key}
        options={destinationsMerged}
        onChangeCallback={value => {
          this.onDestinationSelection(value);
        }}
        onOpenDropdown={nameOfDropdown => {
          this.onOpenDropdown(nameOfDropdown);
        }}
      />
    );
  }

  renderDateInput() {
    // The date "input" is a fake input. It shows "[date]" at first, then opens
    // a datepicker if you click on it. It displays the date if you have selected one.
    let selectionText = `[${madlibsOptions.optionTitles.date}]`;
    let selectedClass = this.baseClassWith("__date--empty");

    if (this.state.date) {
      selectionText = this.state.formattedDate;
      selectedClass = this.baseClassWith("__date--selected");
    }

    return (
      <button className={`${this.baseClassWith("__date")} ${selectedClass}`} onClick={this.openDatepicker}>
        {selectionText}
      </button>
    );
  }

  openDatepicker = () => {
    this.logAction("rezo_search");
    this.setState({ datepickerOpen: true });
  };

  closeDatepicker() {
    this.logAction("rezo_select");
    this.setState({ datepickerOpen: false });
  }

  onDateSelection(value) {
    const formattedDate = formatDate(parseISOWithoutTime(value.name), constants.dateFnsFormats.day);
    // The datepicker closes if a date is selected.
    this.closeDatepicker();
    this.setState({ date: value.name, formattedDate: formattedDate });
  }

  getDatepicker() {
    if (!this.state.datepickerOpen) {
      return null;
    }

    return (
      <ClosableModal isOpen={this.state.datepickerOpen} onRequestClose={this.closeDatepicker.bind(this)}>
        <ReservationCalendar
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...this.props.dateOptions}
          date={this.state.date}
          deliveryCutoffs={this.props.deliveryCutoffs}
          duration={4}
          idSuffix="hold-date"
          name="reservation[date]"
          onChange={value => {
            this.onDateSelection(value);
          }}
          loggingObjectType="node"
        />
      </ClosableModal>
    );
  }

  getGridHref() {
    // The grid href that the user is directed to changes based on the selections.
    // The URL mappings can be found in the madlibs-options.js constants file.
    const grids = madlibsOptions.outputGrids;
    let path = grids.default;
    switch (this.state.style.value) {
      case "jeans":
        path = grids.casual;
        break;
      case "veryCasual":
        path = grids.casual;
        break;
      case "semiFormal":
        path = grids.formal;
        break;
      case "veryFormal":
        path = grids.veryFormal;
        break;
      case "warm":
        path = grids.warmWeather;
        break;
      case "cold":
        path = grids.coldWeather;
        break;
      case "festive":
        if (this.state.timeOfDay.value === "day") {
          path = grids.daytimeFestive;
        } else {
          path = grids.nighttimeFestive;
        }
        break;
    }

    // Want to add the date as a query param if it has been selected
    if (this.state.date) {
      const dateParam = { filters: { date: this.state.date } };
      let dateFilter = objectToQueryString(dateParam);

      // If the style value is festive, the path already has query params;
      // we need to add on an extra one
      if (this.state.style.value === "festive") {
        dateFilter = `&${dateFilter}`;
      } else {
        // Otherwise, this will be the only param on the URL.
        dateFilter = `?${dateFilter}`;
      }

      path = `${path}${dateFilter}`;
    }

    return path;
  }

  hasAllValidInputs() {
    // This is validation for our madlibs. We're not allowing you to proceed
    // unless all of the dropdowns below have been selected.
    return Boolean(
      this.state.style.value && this.state.timeOfDay.value && this.state.companion.value && this.state.emotion.value
    );
  }

  showErrors() {
    const fields = ["style", "timeOfDay", "companion", "emotion"];

    this.logAction("find_my_look_error");

    fields.forEach(field => {
      if (this.state[field]["value"] === "") {
        this.setState({ [field]: { error: true } });
      }
    });
  }

  renderSubmitButton() {
    const buttonClass = `${this.baseClassWith("__submit")} btn`;
    const buttonText = "Find My Look";

    if (this.hasAllValidInputs()) {
      return (
        <a
          className={buttonClass}
          href={this.getGridHref()}
          onClick={() => {
            this.logSuccessfulSubmit();
          }}>
          {buttonText}
        </a>
      );
    }
    return (
      <button
        className={buttonClass}
        onClick={() => {
          this.showErrors();
        }}>
        {buttonText}
      </button>
    );
  }

  closeAllDropdowns() {
    this.setState({ openDropdown: "" });
  }

  // The mask allows us to register clicks that aren't on the dropdown and closes the dropdown automatically
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  renderMask() {
    if (this.state.openDropdown) {
      return (
        <div
          className={this.baseClassWith("__mask")}
          onClick={() => {
            this.closeAllDropdowns();
          }}></div>
      );
    }
  }

  render() {
    const customClassName = this.props.customClass ? this.baseClassWith(`--${this.props.customClass}`) : "";

    return (
      <div className={`molecule-madlibs-form ${customClassName}`}>
        {this.renderMask()}
        I’m headed {this.renderDestinationDropdown()} on
        <br className={this.baseClassWith("__break")} />
        {this.renderDateInput()} with {this.renderGenericMadLibsDropdown(madlibsOptions.companion, "companion")}
        <br className={this.baseClassWith("__break")} />
        and it’s going to be {this.renderGenericMadLibsDropdown(madlibsOptions.style, "style")} and
        <br className={this.baseClassWith("__break")} />I want to feel{" "}
        {this.renderGenericMadLibsDropdown(madlibsOptions.emotion, "emotion")}
        <br className={this.baseClassWith("__break")} />
        {this.renderSubmitButton()}
        {this.getDatepicker()}
      </div>
    );
  }
}

MoleculeMadlibsForm.propTypes = propTypes;

export default MoleculeMadlibsForm;
