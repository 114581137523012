import React from "react";
import PropTypes from "prop-types";
import CheckboxPair from "components/source/new_taxonomy/checkbox-pair";
import FilterInputPairs from "components/source/new_taxonomy/filter-input-pairs";
import Constants from "rtr-constants";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { zipAndSizePanelPropType } from "../../../propTypes/rent-my-style-quiz-content-prop-type";

const ZipSizePanel = ({
  errors,
  filters,
  getCtaCopy,
  idSuffix,
  onChangeMaternity,
  onChangeSize,
  onChangeZip,
  onSubmitZipSize,
  panel,
  zipFilterInput,
}) => {
  const renderSizeFilter = () => {
    return (
      <div className={styles["size-filters"]}>
        <FilterInputPairs
          displayName="size"
          filters={filters.canonicalSizes}
          idSuffix={idSuffix}
          onChange={onChangeSize}
          keyName="canonicalSizes"
          values={Constants.CanonicalSizeValues}
        />
      </div>
    );
  };

  const renderMaternityFilter = () => {
    const keyName = "maternity";
    const { epicMaternity = [] } = filters;
    const { maternityDek } = panel;
    const selected = epicMaternity.length;

    if (!maternityDek) {
      return;
    }

    return (
      <div className={styles["maternity-input"]} data-test-id="maternity-radio-button">
        <CheckboxPair
          filterGroupKey={keyName}
          id={""}
          onChange={onChangeMaternity}
          name={"filters[" + keyName + "][]"}
          checked={selected}
          label={maternityDek}
          optionalTitle={keyName}
        />
      </div>
    );
  };

  const renderZipFilter = () => {
    const { zipDek, zipDekError } = panel;

    const zipFilterClass = classNames(styles["zip-input"], {
      [styles["error"]]: errors[zipFilterInput],
    });

    return (
      <div className={zipFilterClass}>
        <h3
          htmlFor={"zip_code" + idSuffix}
          dangerouslySetInnerHTML={{ __html: errors[zipFilterInput] ? zipDekError : zipDek }}
        />
        <input
          id={"zip_code" + idSuffix}
          name={zipFilterInput}
          value={filters.zip_code}
          placeholder="Enter your zip code here"
          type="tel"
          onChange={onChangeZip}
          maxLength="5"
          pattern="(\d{5}?)"
        />
      </div>
    );
  };

  return (
    <div className={styles["quiz-panel"]}>
      <div className={styles["header-copy"]}>
        <h3 dangerouslySetInnerHTML={{ __html: panel.header }} />
        <p dangerouslySetInnerHTML={{ __html: panel.dek }} />
      </div>
      {renderSizeFilter()}
      {renderMaternityFilter()}
      {renderZipFilter()}
      <div className={styles["cta-container"]}>
        <button className="btn" onClick={() => onSubmitZipSize(panel)}>
          {getCtaCopy(panel.leadsTo)}
        </button>
      </div>
    </div>
  );
};

ZipSizePanel.propTypes = {
  errors: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  getCtaCopy: PropTypes.func.isRequired,
  idSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChangeMaternity: PropTypes.func.isRequired,
  onChangeSize: PropTypes.func.isRequired,
  onChangeZip: PropTypes.func.isRequired,
  onSubmitZipSize: PropTypes.func.isRequired,
  panel: zipAndSizePanelPropType.isRequired,
  zipFilterInput: PropTypes.string.isRequired,
};

export default ZipSizePanel;
