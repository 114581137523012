import React from "react";
import _ from "underscore";
import JsonMarkup from "components/source/shared/json-markup";

import ActionLogger from "action-logger";
import ResponsiveScalableImage from "./responsive-scalable-image";
import ShareBlock from "../social/share-block";

class ExtendableContentBuckets extends React.Component {
  // still rendered as of 2021-06-14 at: /halloween
  // componentDidMount() {
  //   emitDeprecationEvent("ExtendableContentBuckets");
  // }

  logAction = action => {
    ActionLogger.inferAction({
      object_type: "extendable_content_buckets",
      action: action,
    });
  };

  generateBucketTextClassNames = bucket => {
    return ["bucket-text", bucket.textPosition, `align-${bucket.textAlign}`, bucket.colorSchemas].join(" ");
  };

  generateBucketLink = bucket => {
    return bucket.linkSrc + bucket.refTag;
  };

  generateBucketPositionClassNames = bucket => {
    return ["bucket", bucket.className || "", bucket.percentOccupy].join(" ");
  };

  generateId = () => {
    if (!this.props.title) {
      return;
    }
    return this.props.title.toLowerCase().replace(/\s+/g, "-");
  };

  renderTitle = () => {
    if (this.props.title) {
      return <div className="title-block h2">{this.props.title}</div>;
    }
  };

  bucketMarkup = (bucket, title, subtitle) => {
    if (bucket.jsonMarkup) {
      return <JsonMarkup markup={bucket.jsonMarkup} />;
    }

    return (
      <div className={this.generateBucketTextClassNames(bucket)}>
        <h2>{title}</h2>
        <a className="cta-btn--link" href={this.generateBucketLink(bucket)}>
          {subtitle}
        </a>
      </div>
    );
  };

  renderBucket = (bucket, index) => {
    const bucketIndex = "bucket-" + index;
    const desktopMarkup = this.bucketMarkup(bucket, bucket.title, bucket.subtitle);
    let desktopClassName = "";
    let mobileMarkup;
    let imageText;

    if (bucket.mobileTitle) {
      const mobileSubtitle = bucket.mobileSubtitle || bucket.subtitle;
      mobileMarkup = this.bucketMarkup(bucket, bucket.mobileTitle, mobileSubtitle);
      desktopClassName += " mq__tablet-and-desktop";
    }
    if (bucket.textPositionBelow) {
      desktopClassName += " position-below";
    }

    if (bucket.designer) {
      imageText = _.pick(bucket.designer, "text", "textColor", "position");
    }

    return (
      <div id={bucketIndex} key={index} className={this.generateBucketPositionClassNames(bucket)}>
        <a href={this.generateBucketLink(bucket)}>
          <ResponsiveScalableImage images={bucket.images} {...imageText} />
        </a>
        <div className={desktopClassName}>{desktopMarkup}</div>
        <div className="mq__mobile">{mobileMarkup}</div>
      </div>
    );
  };

  className = () => {
    let className = "extendable-bucket-container";
    if (this.props.isFullBleed) {
      className += " extendable-bucket-container--full-bleed";
    }
    if (this.props.noPadding) {
      className += " extendable-bucket-container--no-padding";
    }
    return className;
  };

  render() {
    let shareBlock;

    if (this.props.shareChannels) {
      shareBlock = <ShareBlock buttonStyle="icons" channels={this.props.shareChannels} />;
    }

    return (
      <div className={this.className()} id={this.generateId()}>
        {this.renderTitle()}
        {shareBlock}
        <div className="flex-modules-container">
          {_.map(
            this.props.buckets,
            function (bucket, index) {
              return this.renderBucket(bucket, index);
            },
            this
          )}
        </div>
      </div>
    );
  }
}

export default ExtendableContentBuckets;
