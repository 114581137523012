import React from "react";
import PropTypes from "prop-types";
import AtomHed from "components/source/atoms/atom-hed";
import AtomDek from "components/source/atoms/atom-dek";

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  dek: PropTypes.string,
  mobileDek: PropTypes.string,
  value: PropTypes.string.isRequired,
  radius: PropTypes.number,
  stroke: PropTypes.number,
  linecap: PropTypes.oneOf(["round", "butt"]),
  outOf: PropTypes.number,
  withAnimation: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

const defaultProps = {
  radius: 90,
  stroke: 15,
  linecap: "round",
  outOf: 100,
  withAnimation: false,
};

const baseClass = "atom-stats-circle";

class AtomStatsCircle extends React.Component {
  $circle = null;

  render() {
    // Cannot render anything if there is no value provided
    if (typeof this.props.value === "undefined" || this.props.value === null) {
      return null;
    }

    const { children, height, linecap, radius, stroke, width, withAnimation, title, dek, mobileDek } = this.props;
    // Normalized radius should *not* include the width of the stroke
    const normalizedRadius = radius - stroke * 2;

    // Calculate the circumference using 2πr
    const circumference = normalizedRadius * 2 * Math.PI;

    // We're using a dashed line to create the percentage of the circle that is filled
    // We're doing .floor on a /5 number, then multiplying by 5 to give the offset in batches of 5.
    // This allows us to have fewer loops in our Sass stylesheet.
    const strokeDashoffset =
      Math.floor((circumference - (this.props.value / this.props.outOf) * circumference) / 5) * 5;

    const percentSign = this.props.outOf === 100 ? "%" : "";
    const animationClass = withAnimation ? "with-animation" : "without-animation";
    const diameter = radius * 2;

    return (
      <div className={`${baseClass}__wrapper`}>
        <div
          className={`${baseClass} ${animationClass}`}
          ref={element => (this.$circle = element)}
          data-test-id="atom-stats-circle"
          data-value={this.props.value}
          data-offset={strokeDashoffset}>
          <svg height={height || diameter} width={width || diameter}>
            <circle
              className={`${baseClass}__background`}
              fill="transparent"
              strokeWidth={stroke}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap={linecap}
              r={normalizedRadius}
              cx="50%"
              cy="50%"
            />
            <circle
              fill="transparent"
              strokeWidth={stroke}
              strokeDasharray={`${circumference} ${circumference}`}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap={linecap}
              r={normalizedRadius}
              cx="50%"
              cy="50%"
            />
          </svg>
          {children ? (
            children
          ) : (
            <div className={`${baseClass}__value`}>
              {this.props.value}
              {percentSign}
            </div>
          )}
        </div>
        {title && <AtomHed text={title} customClass={`${baseClass}__title`} type="h3" />}
        {dek && <AtomDek text={dek} mobileText={mobileDek} customClass={`${baseClass}__dek`} />}
      </div>
    );
  }
}

AtomStatsCircle.propTypes = propTypes;
AtomStatsCircle.defaultProps = defaultProps;

export default AtomStatsCircle;
