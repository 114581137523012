import React from "react";
import PropTypes from "prop-types";
import Datepicker from "components/source/new_taxonomy/datepicker";
import styles from "./styles.module.scss";
import { dateSelectPanelPropType } from "../../../propTypes/rent-my-style-quiz-content-prop-type";

const propTypes = {
  date: PropTypes.string,
  dateFilterInput: PropTypes.string.isRequired,
  dateOptions: PropTypes.shape({
    blackoutDays: PropTypes.arrayOf(PropTypes.string).isRequired,
    dateEnd: PropTypes.string.isRequired,
    dateStart: PropTypes.string.isRequired,
  }).isRequired,
  datepickerLogging: PropTypes.shape({
    objectType: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
    sizes: PropTypes.array.isRequired,
  }).isRequired,
  finishQuiz: PropTypes.func.isRequired,
  getEventGridUrl: PropTypes.func.isRequired,
  idSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  panel: dateSelectPanelPropType.isRequired,
};

const DateSelectPanel = ({
  date,
  dateFilterInput,
  dateOptions,
  datepickerLogging,
  finishQuiz,
  getEventGridUrl,
  idSuffix,
  onChange,
  panel,
}) => {
  return (
    <div className={styles["quiz-panel"]}>
      <div className={styles["header-copy"]}>
        <h3 dangerouslySetInnerHTML={{ __html: panel.header }} />
        <p dangerouslySetInnerHTML={{ __html: panel.dek }} />
      </div>
      <Datepicker
        blackoutDays={dateOptions.blackoutDays}
        dateEnd={dateOptions.dateEnd}
        dateStart={dateOptions.dateStart}
        onChange={onChange}
        date={date}
        duration="4"
        name={dateFilterInput}
        logging={datepickerLogging}
        idSuffix={idSuffix}
      />
      <div className={styles["cta-container"]}>
        {panel.messageOne && (
          <div className={styles["promo-message"]}>
            <p>{panel.messageOne}</p>
            <p>{panel.messageTwo}</p>
          </div>
        )}
        <a className="btn" href={getEventGridUrl()} onClick={finishQuiz}>
          {panel.ctaText || "Unlock My Styles"}
        </a>
      </div>
    </div>
  );
};

DateSelectPanel.propTypes = propTypes;

export default DateSelectPanel;
