import React from "react";
import PropTypes from "prop-types";
import Gift from "images/gift.svg";
import { browserPropType, membershipStatePropType } from "components/propTypes";
import Modal from "components/source/shared/modal";
import { isActiveMembership } from "helpers/membership-helpers";
import { isCanceled } from "helpers/membership-plan-helpers";
import { Home, LP, User } from "routes";
import StarsIcon from "images/referrals/stars_icon.svg";
import SadFaceIcon from "images/referrals/sad_face_icon.svg";
import ReferralHelper from "helpers/referral-helper";
import { isBefore, parseISO } from "date-fns";

export default class ReferralSubscriptionFriendHeader extends React.Component {
  static propTypes = {
    award: PropTypes.shape({
      expirationDate: PropTypes.string,
      promoCode: PropTypes.string,
    }),
    backgroundHex: PropTypes.string,
    borderColorHex: PropTypes.string,
    browser: browserPropType,
    legalCopy: PropTypes.string,
    membershipState: membershipStatePropType,
    share: PropTypes.shape({
      advocate: PropTypes.shape({
        user: PropTypes.shape({
          firstName: PropTypes.string,
        }),
      }),
      expirationDate: PropTypes.string,
      promotion: PropTypes.shape({
        rules: PropTypes.shape({
          discountAmount: PropTypes.number,
          discountTypeCode: PropTypes.number,
        }),
      }),
    }),
    title: PropTypes.string,
  };

  getBackgroundColor() {
    return {
      backgroundColor: this.props.backgroundHex,
    };
  }

  getBorderStyle() {
    return {
      "border-color": this.props.borderColorHex,
    };
  }

  renderHeader() {
    const { promoCode } = this.props.award;
    const friendName = this.props.share?.advocate?.user?.firstName || "Your friend";
    const incentive = ReferralHelper.getFriendIncentiveCopy(this.props.share, ReferralHelper.referralPageTypes.FRIEND);

    return (
      <>
        <h1>{this.props.title}</h1>
        <div
          className="referral-subscription-friend-header__promotion-card"
          data-test-id="referral-subscription-friend-header-promotion-card"
          style={this.getBorderStyle()}>
          <Gift />
          <div className="referral-subscription-friend-header__promotion-card__copy">
            <h4>{friendName} sent you a gift!</h4>
            <p>
              Get {incentive}. {this.props.browser?.isMobileViewport ? "" : <br />}Use code <b>{promoCode}</b> at
              checkout.
            </p>
          </div>
        </div>
        <p className="referral-subscription-friend-header__legal">
          <i>{this.props.legalCopy}</i>
        </p>
      </>
    );
  }

  renderIneligibleModal({ icon = <StarsIcon />, heading, dek, ctaCopy, ctaHref }) {
    const additionalStyles = {
      content: {
        "padding": "",
        "padding-bottom": "40px",
      },
    };

    return (
      <Modal
        isOpen={true}
        additionalStyles={additionalStyles}
        className="referral-subscription-friend-header__ineligible-modal">
        <div className="referral-subscription-friend-header__ineligible-modal__content">
          {icon}
          <h3>{heading}</h3>
          <p>{dek}</p>
          <a className="btn btn-primary" href={ctaHref}>
            {ctaCopy}
          </a>
          <a href={Home}>Browse Items</a>
        </div>
      </Modal>
    );
  }

  render() {
    const { membershipState, award, share = {} } = this.props;

    let content = null;

    if (isActiveMembership(membershipState)) {
      content = this.renderIneligibleModal({
        heading: "Looks like you're already a member!",
        dek:
          "This offer is only for new subscribers, but you can adjust your spots and shipments from your Membership Settings.",
        ctaCopy: "Go to Membership Settings",
        ctaHref: User.Settings,
      });
    } else if (isCanceled(membershipState)) {
      content = this.renderIneligibleModal({
        heading: "Welcome back",
        dek: "This offer is only for new members, but you’re still welcome to re-join at a discount!",
        ctaCopy: "Pick a New Plan",
        ctaHref: LP.plans,
      });
    } else if (
      !award ||
      !share ||
      (share.expirationDate && isBefore(parseISO(share.expirationDate), new Date())) ||
      (award.expirationDate && isBefore(parseISO(award.expirationDate), new Date()))
    ) {
      content = this.renderIneligibleModal({
        icon: <SadFaceIcon />,
        heading: "Sorry, this code has expired!",
        dek: "Please ask your friend to share again.",
        ctaCopy: "View Plans",
        ctaHref: LP.plans,
      });
    } else {
      content = this.renderHeader();
    }

    return (
      <div
        className="referral-subscription-friend-header"
        data-test-id="referral-subscription-friend-header"
        style={this.getBackgroundColor()}>
        {content}
      </div>
    );
  }
}
