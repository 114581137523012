import React from "react";
import PropTypes from "prop-types";
import { childrenPropType, browserPropType } from "components/propTypes";
import PropTypesHelper from "helpers/PropTypesHelper";
import AtomYoutubeEmbeddedContent from "../atoms/atom-youtube-embedded-content";
import RtrImage from "../shared/rtr-image";

const baseClass = "molecule-checkerboard";
const imageBaseClass = "atom-visual-media";

class MoleculeCheckerboardYoutube extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(["right", "left"]).isRequired,
    altText: PropTypes.string,
    backgroundColor: PropTypesHelper.hexColor,
    children: childrenPropType,
    image: PropTypes.string.isRequired,
    browser: browserPropType,
    videoSettings: PropTypes.shape({
      autoPlay: PropTypes.bool,
      controls: PropTypes.bool,
    }),
    videoSrcYouTube: PropTypes.string,
    videoTitle: PropTypes.string,
  };

  getStyles() {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }
    return styles;
  }

  renderMedia() {
    if (!this.props.videoSrcYouTube) {
      return <RtrImage className={imageBaseClass} src={this.props.image} alt={this.props.altText} />;
    }

    return (
      <AtomYoutubeEmbeddedContent
        image={this.props.image}
        altText={this.props.altText}
        videoSrcYouTube={this.props.videoSrcYouTube}
        videoSettings={this.props.videoSettings}
        videoTitle={this.props.videoTitle}
        browser={this.props.browser}
      />
    );
  }

  render() {
    return (
      <div className={`${baseClass} ${baseClass}--${this.props.align}`} style={this.getStyles()}>
        {this.renderMedia()}
        <div className={`${baseClass}__children`}>{this.props.children}</div>
      </div>
    );
  }
}

export default MoleculeCheckerboardYoutube;
