import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";
import { analytics } from "rtr-constants";
import ActionLogger from "action-logger";

export class ReserveUpsellHeroUnit extends React.Component {
  static propTypes = {
    headingText: PropTypes.string,
    legalCopyLinkText: PropTypes.string,
    legalCopyLinkHref: PropTypes.string,
    primaryCtaHref: PropTypes.string,
    primaryCtaText: PropTypes.string,
    promoCodeText: PropTypes.string,
    promoCodeLinkHref: PropTypes.string,
    promoCodeLinkText: PropTypes.string,
    subHeadingText: PropTypes.string,
    valuePropositionList: PropTypes.arrayOf(PropTypes.string),
    userId: PropTypes.string,
  };

  state = {
    isCopyingPromo: false,
  };

  componentDidMount() {
    this.logPixel(analytics.ACTION_TYPE.VIEW);
  }

  hasPromo() {
    const { promoCodeText } = this.props;
    return promoCodeText;
  }

  onCopyPromoClick = () => {
    const { promoCodeText } = this.props;
    navigator?.clipboard?.writeText(promoCodeText).then(() => {
      this.setState({
        isCopyingPromo: true,
      });
      setTimeout(() => {
        this.setState({
          isCopyingPromo: false,
        });
      }, 3000);
    });

    this.logPixel(analytics.ACTION_TYPE.COPY_CODE);
  };

  renderPromoCode() {
    const { legalCopyLinkHref, legalCopyLinkText, promoCodeText, promoCodeLinkHref, promoCodeLinkText } = this.props;

    if (!this.hasPromo()) {
      return null;
    }

    const showPromoCodeLink = promoCodeLinkHref && promoCodeLinkText;

    return (
      <div className="reserve-upsell-hero-unit__promo" data-test-id="reserve-upsell-unit-promo">
        <div className="reserve-upsell-hero-unit__promo__copy-code">
          <input value={promoCodeText} readOnly data-test-id="promo-input"></input>
          <AtomSecondaryButton
            buttonText="Copy Code"
            onClick={this.onCopyPromoClick}
            disabled={this.state.isCopyingPromo}
          />
        </div>
        {showPromoCodeLink && (
          <a
            className="btn btn-primary btn-full-width"
            href={promoCodeLinkHref}
            onClick={() => this.logPixel()}
            data-test-id={"promo-code-link"}>
            {promoCodeLinkText}
          </a>
        )}
        <p className="reserve-upsell-hero-unit__promo__legal">
          <a href={legalCopyLinkHref} target="_blank" rel="noopener noreferrer">
            {legalCopyLinkText}
          </a>
        </p>
      </div>
    );
  }

  renderPrimaryCta() {
    const { primaryCtaHref, primaryCtaText } = this.props;

    if (!primaryCtaText || !primaryCtaHref) {
      return null;
    }

    return (
      <a
        href={primaryCtaHref}
        className="btn btn-primary"
        onClick={() => this.logPixel()}
        data-test-id="reserve-upsell-hero-unit-primary-cta">
        {primaryCtaText}
      </a>
    );
  }

  logPixel = action => {
    const { userId } = this.props;
    const pixelBody = {
      object_type: analytics.OBJECT_TYPE.TREATMENT_HERO,
      user_id: userId,
    };

    if (!action) {
      ActionLogger.inferAction({
        action: analytics.ACTION_TYPE.CLICK_CTA,
        ...pixelBody,
      });
    } else {
      ActionLogger.logAction({
        action: action,
        ...pixelBody,
      });
    }
  };

  render() {
    const { headingText, subHeadingText, valuePropositionList } = this.props;
    const className = classNames("reserve-upsell-hero-unit", {
      "reserve-upsell-hero-unit--with-promo": this.hasPromo(),
    });

    return (
      <div className={className} data-test-id="reserve-upsell-hero-unit">
        <h1>{headingText}</h1>
        <div className="reserve-upsell-hero-unit__card">
          <h2>{subHeadingText}</h2>
          <ul>
            {valuePropositionList.map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ul>
          {this.renderPromoCode()}
          {this.renderPrimaryCta()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.userData?.userProfile?.id,
});

export default connect(mapStateToProps, null)(ReserveUpsellHeroUnit);
