import React, { useState } from "react";
import PropTypes from "prop-types";

import ReferralHelper from "helpers/referral-helper";
import { getErrorMessage } from "helpers/error-message-maps/referrals";
import ActionLogger from "action-logger";
import { browserPropType } from "components/propTypes";

export const propTypes = {
  browser: browserPropType,
  landingCopy: PropTypes.object,
  onSubmit: PropTypes.func,
  pixelAction: PropTypes.string,
  pixelObjectType: PropTypes.string,
  pixelPageType: PropTypes.string,
  pixelStatus: PropTypes.string,
  programType: PropTypes.string, // I think?
  referralLink: PropTypes.string.isRequired,
  style: PropTypes.oneOf(["icon", "link", "button"]),
  updateShareStatus: PropTypes.func,
};

function getButtonClassNames(type, style) {
  const classNames = ["share-channel"];

  classNames.push([type, style].join("-"));

  if (style === "button") {
    classNames.push("btn");
  }

  return classNames.join(" ");
}

export function withShareChannelBehavior({ type = "publicLink" } = {}) {
  return WrappedComponent => {
    const C = props => {
      const [submissionError, setSubmissionError] = useState(null);

      const {
        onSubmit,
        pixelAction,
        pixelObjectType,
        pixelPageType,
        pixelStatus,
        programType,
        referralLink,
        style = "icon",
        updateShareStatus,
      } = props;

      const handleLogShare = (action, placement) => {
        const pixel = {
          object_type: pixelObjectType || type + "_share",
          action: action,
          placement: placement || "",
          page_type: pixelPageType,
          status: pixelStatus,
        };

        ActionLogger.logAction(pixel);
      };

      const handleShareSuccessCallback = (isEmailShare, placement) => {
        return () => {
          if (isEmailShare) {
            handleLogShare(ReferralHelper.pixels.actions.SUCCESS, placement);

            setSubmissionError(null);
          }
        };
      };

      const handleShareFailureCallback = (isEmailShare, placement) => {
        return e => {
          if (isEmailShare) {
            handleLogShare(ReferralHelper.pixels.actions.FAILURE, placement);

            const errorCode = e?.code;
            setSubmissionError(getErrorMessage(errorCode));
          }
        };
      };

      const handleShareSuccess = (response, placement) => {
        const action = pixelAction || ReferralHelper.pixels.actions.SUCCESS;
        const isEmailShare = type === "email" || pixelObjectType === ReferralHelper.pixels.objectTypes.EMAIL_SHARE;

        if (!isEmailShare) {
          handleLogShare(action, placement);
        }

        if (updateShareStatus) updateShareStatus(true);

        if (onSubmit) {
          onSubmit(
            {
              type,
              success: true,
              data: response,
              link: referralLink,
              programType,
            },
            handleShareSuccessCallback(isEmailShare, placement),
            handleShareFailureCallback(isEmailShare, placement)
          );
        }

        return true;
      };

      return (
        <WrappedComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          buttonClassNames={getButtonClassNames(type, style)}
          onLogShare={handleLogShare}
          onShareSuccess={handleShareSuccess}
          submissionError={submissionError}
        />
      );
    };
    C.propTypes = propTypes;
    C.displayName = `withShareChannelBehavior(${WrappedComponent.displayName || WrappedComponent.name})`;

    return C;
  };
}
