import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RtrImage from "./rtr-image";

class ResponsiveScalableImage extends React.Component {
  // Rendering still occurs as of 2021-06-14
  // componentDidMount() {
  //   emitDeprecationEvent("ResponsiveScalableImage");
  // }

  static propTypes = {
    images: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        })
      ),
    ]),
    textColor: PropTypes.string,
    position: PropTypes.string,
    text: PropTypes.string,
    addHoverOpacity: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["true", "false"])]),
  };

  generateImages = () => {
    const defaultImage = this.props.images.find(i => !i.maxWidth);
    return (
      <picture>
        {this.props.images
          .filter(i => !!i.maxWidth)
          .map(i => (
            <source key={i.src} srcSet={i.src} media={`only screen and (max-width: ${i.maxWidth}px)`} />
          ))}
        <RtrImage src={defaultImage.src} />
      </picture>
    );
  };

  uniqueId = () => {
    if (!this.props.images || !this.props.images.length) {
      return;
    }

    // instead of generating a unique id and blocking react's dom comparison
    // from ever saying "nothing has changed", let's use something that we know
    // to *probably* be unique in the data, and use that as the classname
    const uniqueId = this.props.images[0].src.replace(/[^a-z0-9-_]/gi, "_");

    return "responsive-scalable-image-" + uniqueId;
  };

  getOverlayMarkup = () => {
    const classNames = [],
      baseClassName = "responsive-scalable-image__text";
    if (this.props.textColor) {
      classNames.push(baseClassName + "--color-" + this.props.textColor);
    }
    if (this.props.position) {
      classNames.push(baseClassName + "--position-" + this.props.position);
    }

    if (classNames.length) {
      classNames.push(baseClassName);
      classNames.push("title");
    }
    return <div className={classNames.join(" ")}>{this.props.text}</div>;
  };

  render() {
    let textOverlay;

    if (this.props.text) {
      textOverlay = this.getOverlayMarkup();
    }

    const className = classNames("responsive-scalable-image-container", {
      "hover-opacity": this.props.addHoverOpacity,
    });

    return (
      <div className={className} id={this.uniqueId()}>
        {this.generateImages()}
        {textOverlay}
      </div>
    );
  }
}

export default ResponsiveScalableImage;
