import PropTypes from "prop-types";
import { RENTAL_INTENT } from "rtr-constants";

export const midGridContentPropType = PropTypes.shape({
  title: PropTypes.string,
  footer: PropTypes.string,
  style: PropTypes.object,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      footer: PropTypes.string,
      image: PropTypes.oneOf(["Hanger", "Lightning", "Clipboard", "Calendar", "MeasuringTape", "Leaf"]),
    })
  ),
  cta: PropTypes.shape({
    text: PropTypes.string,
    path: PropTypes.string,
    style: PropTypes.object,
  }),
});

export const midGridEducationalMessagingPropType = PropTypes.shape({
  [RENTAL_INTENT.NO_INTENT]: midGridContentPropType,
  [RENTAL_INTENT.MEMBERSHIP]: midGridContentPropType,
  [RENTAL_INTENT.RESERVE]: midGridContentPropType,
});
