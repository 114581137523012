import PropTypes from "prop-types";

export const visualNavItemPropType = PropTypes.shape({
  targetUrl: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageAspectRatio: PropTypes.string,
  mobileImage: PropTypes.string,
  mobileImageAspectRatio: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  mobileVideoSrcMp4: PropTypes.string,
  mobileVideoSrcMp4Poster: PropTypes.string,
  mobileVideoSrcMp4PosterAspectRatio: PropTypes.string,
  mobileVideoSrcWebm: PropTypes.string,
  mobileVideoSrcWebmPoster: PropTypes.string,
  mobileVideoSrcWebmPosterAspectRatio: PropTypes.string,
  videoSrcMp4: PropTypes.string,
  videoSrcMp4Poster: PropTypes.string,
  videoSrcMp4PosterAspectRatio: PropTypes.string,
  videoSrcWebm: PropTypes.string,
  videoSrcWebmPoster: PropTypes.string,
  videoSrcWebmPosterAspectRatio: PropTypes.string,
});

export const visualNavContentPropType = PropTypes.arrayOf(
  PropTypes.shape({
    hostGrids: PropTypes.arrayOf(PropTypes.string).isRequired,
    products: PropTypes.arrayOf(visualNavItemPropType),
  })
);
