// Error codes from referral service
const REFERRAL_FRIEND_ALREADY_REDEEMED = "Referral-friend-already-redeemed";

// These messages correspond to the unique error code passed from referral service
const errorCodeToMessageMap = {
  default: "Sorry, something went wrong",
  [REFERRAL_FRIEND_ALREADY_REDEEMED]: "Your friend has already redeemed a referral offer",
};

export const errorCodes = Object.freeze({
  REFERRAL_FRIEND_ALREADY_REDEEMED,
});
export const errorMessages = Object.freeze(errorCodeToMessageMap);

/**
 * Gets the client error message for the given error code from referral service
 * @param {String} errorCode - The unique error code from the server
 * @returns {String} - returns the corresponding client error message for the unique error code or the default message
 */
export function getErrorMessage(errorCode) {
  const message = errorMessages[errorCode];

  return message || errorMessages.default;
}
