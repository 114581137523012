import React, { Component } from "react";
import PropTypes from "prop-types";
import { withShareChannelBehavior, propTypes as shareChannelBehaviorProps } from "./with-share-channel-behavior";
import { isIPhone } from "helpers/device-helpers";

const IOS_DELIMITER = "&";
const ANDROID_DELIMITER = "?";

export class SmsShareComponent extends Component {
  static propTypes = {
    buttonClassNames: PropTypes.string,
    buttonCopy: PropTypes.string,
    hasSharedStyles: PropTypes.bool,
    onShareSuccess: PropTypes.func,
    shortenedUrl: PropTypes.string,
    ...shareChannelBehaviorProps,
  };

  static defaultProps = {
    hasSharedStyles: false,
  };

  state = {
    isIPhone: false,
  };

  componentDidMount() {
    this.setState({ isIPhone: isIPhone() });
  }

  handleClick = () => {
    this.props.onShareSuccess();
  };

  composeUrl() {
    let url = "sms:";
    let smsCopy;

    if (this.props.hasSharedStyles) {
      smsCopy = this.props?.landingCopy?.eventShare?.sms;
    } else {
      smsCopy = this.props?.landingCopy?.sms;
    }

    // is there a better way to test for iPhone?
    url += this.state.isIPhone ? IOS_DELIMITER : ANDROID_DELIMITER;
    url += "body=" + encodeURIComponent(smsCopy);
    url += encodeURIComponent("\n") + this.props.shortenedUrl;

    return url;
  }

  render() {
    return (
      <a
        className={`${this.props.buttonClassNames} btn-secondary`}
        onClick={this.handleClick}
        rel="noopener noreferrer"
        target="_blank"
        href={this.composeUrl()}>
        {this.props.buttonCopy}
      </a>
    );
  }
}

export default withShareChannelBehavior({ type: "sms" })(SmsShareComponent);
