import React from "react";
import { connect } from "react-redux";
import { RENTAL_INTENT } from "rtr-constants";
import PropTypes from "prop-types";
import { setMembershipLens } from "actions/products-actions";
import { membershipStatePropType, moleculeRentalIntentGridTopperPropType } from "../../propTypes";
import MoleculeDualCtaHero from "components/source/molecules/molecule-dual-cta-hero";
import classnames from "classnames";
import { getRentalIntent } from "helpers/rental-intent-helper";
import { isActiveMembership } from "../../../helpers/membership-helpers";

export class MoleculeRentalIntentGridTopper extends React.Component {
  static propTypes = {
    ...moleculeRentalIntentGridTopperPropType,
    currentIntent: PropTypes.oneOf(Object.values(RENTAL_INTENT)),
    isMobileViewport: PropTypes.bool,
    isRentableGrid: PropTypes.bool,
    membershipState: membershipStatePropType,
    shouldSeeIntentBasedExperience: PropTypes.bool.isRequired,
    updateLens: PropTypes.func.isRequired,
  };

  static classname = "rental-intent-grid-topper";

  appendPixelDataToCta(cta, currentIntent) {
    if (cta.url) {
      cta.pixelData = {
        object_type: "grid_topper",
        action_type: "click_cta",
        current_intent: currentIntent,
        destination_url: cta.url,
      };
    } else if (cta.updatedLens) {
      const updatedIntent = cta.updatedLens === "classic" ? RENTAL_INTENT.RESERVE : RENTAL_INTENT.MEMBERSHIP;

      cta.pixelData = {
        object_type: "grid_topper",
        action_type: "switch_lens",
        current_intent: currentIntent,
        new_intent: updatedIntent,
      };
    }

    return cta;
  }

  appendClickHandlerToCta(cta = {}) {
    if (cta.updatedLens) {
      cta.onClick = () => this.props.updateLens(cta.updatedLens);
    }

    return cta;
  }

  getCta(cta = {}) {
    const ctaCopy = { ...cta };
    this.appendPixelDataToCta(ctaCopy, this.props.currentIntent);
    this.appendClickHandlerToCta(ctaCopy);

    return ctaCopy;
  }

  getContent() {
    const { currentIntent } = this.props;

    return this.props[currentIntent];
  }

  getAdditionalClassnames() {
    const { condensed, ctaOne, ctaTwo } = this.getContent();
    const bothCtasArePrimary = [ctaOne, ctaTwo].every(cta => cta?.type === "primary");

    return classnames(MoleculeRentalIntentGridTopper.classname, {
      "additional-mobile-height": bothCtasArePrimary,
      "condensed": condensed,
    });
  }

  render() {
    const { currentIntent, isRentableGrid, membershipState, shouldSeeIntentBasedExperience } = this.props;
    if (isActiveMembership(membershipState) || !isRentableGrid || !shouldSeeIntentBasedExperience) {
      return null;
    }

    //Until specific intent is available, block out space for the topper to prevent CLS
    if (!currentIntent) {
      return (
        <MoleculeDualCtaHero
          additionalClassname={MoleculeRentalIntentGridTopper.classname}
          dataTestId={MoleculeRentalIntentGridTopper.classname}
        />
      );
    }

    const contentForCurrentIntent = this.getContent();

    if (!contentForCurrentIntent) {
      return null;
    }

    const content = {
      ...contentForCurrentIntent,
      ctaOne: this.getCta(contentForCurrentIntent?.ctaOne),
      ctaTwo: this.getCta(contentForCurrentIntent?.ctaTwo),
    };

    return (
      <MoleculeDualCtaHero
        attributes={content}
        additionalClassname={this.getAdditionalClassnames()}
        dataTestId={MoleculeRentalIntentGridTopper.classname}
        isMobileViewport={this.props.isMobileViewport}
      />
    );
  }
}

const mapStateToProps = state => ({
  content: state.rentalIntentGridTopper,
  currentIntent: getRentalIntent(state.userData),
  isRentableGrid: state.gridView?.isRentable,
  isMobileViewport: state.browser?.isMobileViewport,
  membershipState: state.membershipState,
  shouldSeeIntentBasedExperience: state.shouldSeeIntentBasedExperience,
});

const mapDispatchToProps = dispatch => {
  return {
    updateLens: lens => dispatch(setMembershipLens(lens, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoleculeRentalIntentGridTopper);
