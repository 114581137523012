import React from "react";
import PropTypes from "prop-types";
import { rentMyStyleQuizContentPropType } from "components/propTypes";
import ClosableModal from "components/source/shared/closable-modal";
import Modal from "components/source/shared/modal";
import RentMyStyleQuiz from "components/source/paid_marketing/rent-my-style/rent-my-style-quiz";
import AvailabilityFiltersInterface from "components/source/new_taxonomy/availability-filters-interface";
import { RENT_MY_STYLE_QUIZ_MODAL_NAME } from "rtr-constants";
import styles from "./styles.module.scss";

const RentMyStyleQuizModal = ({
  displayedModal,
  dateOptions,
  onChange,
  onMaternityFilterChange,
  filters = {},
  isClosable,
  closeModal = () => {},
  quizContent,
}) => {
  const ModalComponent = isClosable ? ClosableModal : Modal;

  return (
    <div>
      {displayedModal === RENT_MY_STYLE_QUIZ_MODAL_NAME && (
        <ModalComponent
          className={styles["rent-my-style-quiz-modal"]}
          isFullScreen={true}
          additionalStyles={{
            content: { height: "100%", padding: 0 },
          }}
          optionalWrapperClass={styles["rent-my-style-quiz-modal"]}
          onRequestClose={closeModal}
          overlayClassName={styles["overlay"]}
          isOpen={displayedModal === RENT_MY_STYLE_QUIZ_MODAL_NAME}>
          <AvailabilityFiltersInterface
            component={RentMyStyleQuiz}
            dateOptions={dateOptions}
            displayedModal={displayedModal}
            errors={{}}
            filters={filters}
            idSuffix="-onboarding-quiz"
            onChange={onChange}
            onMaternityFilterChange={onMaternityFilterChange}
            submitFilters={() => {}}
            quizContent={quizContent}
          />
        </ModalComponent>
      )}
    </div>
  );
};

RentMyStyleQuizModal.propTypes = {
  displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dateOptions: PropTypes.shape({
    blackoutDays: PropTypes.arrayOf(PropTypes.string).isRequired,
    dateEnd: PropTypes.string.isRequired,
    dateStart: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onMaternityFilterChange: PropTypes.func,
  filters: PropTypes.shape({
    date: PropTypes.string,
    zip_code: PropTypes.string,
    canonicalSizes: PropTypes.array,
  }),
  isClosable: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  quizContent: rentMyStyleQuizContentPropType,
};

export default RentMyStyleQuizModal;
