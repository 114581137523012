import React from "react";
import _ from "underscore";
/**********************************************************************
NOTE:
This is not a true dropdown. It was created especially for the madlibs
holiday project in order to have a styled dropdown (which cannot be
done on a regular <select> for security reasons).
**********************************************************************/

import PropTypes from "prop-types";
import { emitDeprecationEvent } from "helpers/deprecation-alert";

const propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
};

const baseClass = "atom-madlibs-dropdown";

class AtomMadlibsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.label,
      typeOutWordsAnimation: false, // This class is added to an element when it needs to be styled to look like it's typing
    };
  }

  componentDidMount() {
    emitDeprecationEvent("AtomMadlibsDropdown");
  }

  baseClassWith(element) {
    return [baseClass, element].join("");
  }

  onInputClick() {
    this.setState({ typeOutWordsAnimation: false });
    this.props.onOpenDropdown(this.props.name);
  }

  renderInput() {
    const selectedClass =
      this.state.selectedOption === this.props.label ? this.baseClassWith("--empty") : this.baseClassWith("--selected");
    const typeOutAnimationClass = this.state.typeOutWordsAnimation ? this.baseClassWith("--type") : "";
    return (
      <div
        onClick={() => {
          this.onInputClick();
        }}
        className={`${baseClass} ${selectedClass}`}>
        <div className={typeOutAnimationClass}> {this.state.selectedOption} </div>
      </div>
    );
  }

  renderDropdown() {
    if (!this.props.isOpen) {
      return;
    }

    return (
      <div className={this.baseClassWith("__options-wrapper")}>
        {_.map(this.props.options, (option, i) => {
          return (
            <div
              className={this.baseClassWith("__option")}
              onClick={() => {
                this.onOptionClick(option);
              }}
              key={i}>
              {option.label}
            </div>
          );
        })}
      </div>
    );
  }

  onOptionClick(option) {
    this.props.onChangeCallback(option.value);
    this.setState({ selectedOption: option.label, typeOutWordsAnimation: true });
  }

  render() {
    const errorClass = this.props.error ? this.baseClassWith("--error") : "";
    return (
      <div className={`${this.baseClassWith("__wrapper")} ${this.props.additionalClassName} ${errorClass}`}>
        {this.renderInput()}
        {this.renderDropdown()}
      </div>
    );
  }
}

AtomMadlibsDropdown.propTypes = propTypes;

export default AtomMadlibsDropdown;
