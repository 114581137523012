import React from "react";
import _ from "underscore";
import RtrImage from "./rtr-image";

class LandingPageHeader extends React.Component {
  // still rendered as of 2021-06-14
  // componentDidMount() {
  //   emitDeprecationEvent("LandingPageHeader");
  // }

  renderBuckets = () => {
    const { buckets } = this.props;

    return _.map(buckets, function (bucket, i) {
      return (
        <div key={i} className="landing-page-header__bucket">
          <a className="landing-page-header__image-wrapper" href={bucket.gridUrl}>
            <RtrImage src={bucket.imgUrl} alt={bucket.title} />
          </a>
          <p className="landing-page-header__title">{bucket.title}</p>
          <a className="landing-page-header__link" href={bucket.gridUrl}>
            {bucket.gridDesc}
          </a>
        </div>
      );
    });
  };

  render() {
    const bucketMarkup = this.renderBuckets();

    return (
      <div>
        <h2 className="landing-page-header__heading">{this.props.heading}</h2>
        <p className="landing-page-header__desc">{this.props.description}</p>
        <div className="landing-page-header__buckets">{bucketMarkup}</div>
      </div>
    );
  }
}

export default LandingPageHeader;
