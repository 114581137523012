import React from "react";
import PropTypes from "prop-types";
import AtomIcon from "components/source/atoms/atom-icon";
import AtomLabel from "components/source/atoms/atom-label";
import AtomDek from "components/source/atoms/atom-dek";
import RtrImage from "../shared/rtr-image";

class MoleculeIconWithText extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string.isRequired,
    textClass: PropTypes.string,
  };

  static className = "molecule-icon-with-text";
  static dek = "dek";

  renderIcon = () => {
    if (this.props.image) {
      return <RtrImage src={this.props.image} alt={this.props.text} />;
    }

    return <AtomIcon iconName={this.props.icon} />;
  };

  renderText = () => {
    if (this.props.textClass === this.constructor.dek) {
      return <AtomDek text={this.props.text} mobileText={this.props.text} />;
    }

    return <AtomLabel text={this.props.text} />;
  };

  render() {
    return (
      <div className={this.constructor.className} data-test-id="molecule-icon-with-text">
        {this.renderIcon()}
        {this.renderText()}
      </div>
    );
  }
}

export default MoleculeIconWithText;
